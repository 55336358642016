import { globalVariable } from '../../../../global/global-variable';
import {
  ADD_RENDER_VIDEO,
  STOP_RENDER_VIDEO,
  ZOOM_RENDER_VIDEO,
  ALL_COLOR_AFTER_VIDEO_STOP,
  WATERMARK_COVER_TYPE,
  VIDEO_LAYOUT_ZONE,
  VIDEO_TYPE_90P,
} from '../../enum';
import renderDiff from '../../service/renderDiff';
import subscribeDiff from '../../service/subscribeDiff';
import calculateQuality from '../../service/calculateQuality';
import { getVideoFillMode } from '../../service/service';
import {
  setIsSubscribeActiveChannel,
  setActiveChannelQuality,
  setSubscribedVideoMaxQuality,
} from '../video-action';
import { isEnableVideoWaterMaskSelector } from '../selectors/video-status-selector';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isViewOnly } from '../../../../global/service/user-types';
import { isUnifiedRender } from '../../../../global/op-feature-option';
import { isSimuliveVideoMode } from '../../../../global';
import {
  activeSpeakerIdSelector,
  canSubscribeActiveChannelSelector,
} from '../selectors/video-list-selector';
import {
  UI_SUBSCRIBE_VIDEO,
  UI_UNSUBSCRIBE_VIDEO,
} from '../../../../constants/AVNotifyAPPTypes';

export function subscribeVideo(videos) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId: currentUserId },
      },
    } = getState();

    const subInfoList = [];
    videos.forEach((item) => {
      if (item.user.userId !== currentUserId) {
        subInfoList.push({
          id: item.user.userId,
          size: item.quality,
          bOn: false,
        });
        if (
          item.zone === VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO &&
          item.quality === 3 &&
          JsMediaSDK_Instance.Zoom_Monitor
        ) {
          /** monitor log about subscribe 720p */
          JsMediaSDK_Instance.Zoom_Monitor.add_monitor('WCLSZ3');
        }
      }
    });
    if (subInfoList.length) {
      globalVariable.avSocket.sendSocket(UI_SUBSCRIBE_VIDEO, subInfoList);
    }
  };
}

export function unsubscribeVideo(videos) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId: currentUserId },
      },
    } = getState();

    const subIDList = [];
    videos.forEach((item) => {
      if (item.user.userId !== currentUserId) {
        subIDList.push({ id: item.user.userId });
      }
    });
    if (subIDList.length) {
      globalVariable.avSocket.sendSocket(UI_UNSUBSCRIBE_VIDEO, subIDList);
    }
  };
}

export function stopRenderVideo({
  user,
  canvasId,
  RGBA,
  doNotClean = false,
  x,
  y,
  width,
  height,
  zone,
}) {
  return () => {
    if (globalVariable.avSocket?.sendSocket && user.displayVideoOn) {
      globalVariable.avSocket.sendSocket(STOP_RENDER_VIDEO, {
        userId: user.userId,
        canvas: canvasId,
        RGBA,
        doNotClean,
        x,
        y,
        width,
        height,
        zone,
      });
    }
  };
}

export function zoomRenderVideo({
  userId,
  width,
  height,
  x,
  y,
  canvasId,
  zone,
}) {
  return () => {
    globalVariable.avSocket.sendSocket(ZOOM_RENDER_VIDEO, {
      userId,
      x,
      y,
      width,
      height,
      canvas: canvasId,
      zone,
      RGBA: ALL_COLOR_AFTER_VIDEO_STOP,
    });
  };
}

export function addRenderVideo({
  userId,
  width,
  height,
  x,
  y,
  canvasId,
  quality,
  zone,
  fillMode,
}) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      meeting: {
        waterMarkText,
        currentUser: { userId: currentUserId },
      },
      video: { watermarkOpacityLevel, watermarkCoverType, watermarkPosition },
    } = state;

    const enableWaterMark = isEnableVideoWaterMaskSelector(state);

    globalVariable.avSocket.sendSocket(ADD_RENDER_VIDEO, {
      userId,
      width,
      height,
      x,
      y,
      canvas: canvasId,
      quality,
      isMyself: userId === currentUserId,
      enableWaterMark,
      waterMarkText,
      zone,
      watermarkOpacity: watermarkOpacityLevel,
      watermarkRepeated: watermarkCoverType === WATERMARK_COVER_TYPE.REPEATED,
      watermarkPosition,
      fillMode,
    });
  };
}

export function calculateCurrentRenderVideo({
  previousCurrentRenderVideo,
  currentRenderVideo,
}) {
  return () => {
    const renderDiffMap = renderDiff(
      previousCurrentRenderVideo || [],
      currentRenderVideo,
    );

    const { cuurentUnsubscribeList, cuurentSubscribeList } = subscribeDiff({
      previousCurrentRenderVideo,
      currentRenderVideo,
      renderDiffMap,
    });

    return {
      renderDiffMap,
      cuurentSubscribeList,
      cuurentUnsubscribeList,
    };
  };
}

export function notifyMediaSDKVideoInfo(renderDiffMap) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isClientPageVisible },
    } = state;

    renderDiffMap.stop.forEach((item) => {
      dispatch(
        stopRenderVideo({
          user: item.user,
          canvasId: item.canvasId,
          RGBA: ALL_COLOR_AFTER_VIDEO_STOP,
          doNotClean: !isClientPageVisible,
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          zone: item.zone,
        }),
      );
    });

    renderDiffMap.zoom
      .filter((item) => !item.onlyChangeQuality)
      .forEach((item) => {
        dispatch(
          zoomRenderVideo({
            userId: item.user.userId,
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height,
            canvasId: item.canvasId,
            zone: item.zone,
          }),
        );
      });

    renderDiffMap.start.forEach((item) => {
      dispatch(
        addRenderVideo({
          userId: item.user.userId,
          width: item.width,
          height: item.height,
          x: item.x,
          y: item.y,
          canvasId: item.canvasId,
          quality: item.quality,
          zone: item.zone,
          fillMode: getVideoFillMode(),
        }),
      );
    });
  };
}

export function notifyRWGVideoInfo({
  cuurentUnsubscribeList,
  cuurentSubscribeList,
}) {
  return (dispatch) => {
    dispatch(unsubscribeVideo(cuurentUnsubscribeList));
    dispatch(subscribeVideo(cuurentSubscribeList));
  };
}

export function renderVideoThunk({
  previousCurrentRenderVideo,
  currentRenderVideo,
  previousReceivedVideoQuality,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { receivedVideoQuality },
      meeting: {
        currentUser: { userRole },
        useWBVideo,
      },
    } = state;

    if (
      (!previousCurrentRenderVideo || !previousCurrentRenderVideo.length) &&
      !currentRenderVideo?.length
    ) {
      return;
    }
    const isSelfSupport1080P = isWebinar() && isViewOnly(userRole);
    // calculateQuality
    const currentRenderVideoTemp = calculateQuality(
      currentRenderVideo || [],
      receivedVideoQuality,
      isSelfSupport1080P,
      useWBVideo,
    );

    const previousCurrentRenderVideoTemp = calculateQuality(
      previousCurrentRenderVideo || [],
      previousReceivedVideoQuality,
      isSelfSupport1080P,
      useWBVideo,
    );

    //get render/subscribe info from previous render list
    const { renderDiffMap, cuurentSubscribeList, cuurentUnsubscribeList } =
      dispatch(
        calculateCurrentRenderVideo({
          previousCurrentRenderVideo: previousCurrentRenderVideoTemp,
          currentRenderVideo: currentRenderVideoTemp,
        }),
      );

    if (isSimuliveVideoMode() || !isUnifiedRender(useWBVideo)) {
      dispatch(notifyMediaSDKVideoInfo(renderDiffMap));
    }

    if (isUnifiedRender(useWBVideo)) {
      dispatch(
        notifyRWGActiveChannel({
          cuurentSubscribeList,
          cuurentUnsubscribeList,
          currentRenderVideoTemp,
          previousCurrentRenderVideoTemp,
        }),
      );
    }

    dispatch(
      notifyRWGVideoInfo({ cuurentUnsubscribeList, cuurentSubscribeList }),
    );

    if (CLIENT_ENV === 'development') {
      // record current max decode size  only for dev
      const subscribedVideoMaxQuality = currentRenderVideoTemp.reduce(
        (maxSize, item) =>
          item?.user?.displayVideoOn
            ? Math.max(maxSize, item.quality)
            : maxSize,
        0,
      );
      dispatch(setSubscribedVideoMaxQuality(subscribedVideoMaxQuality));
    }
  };
}

export function subscribeVideoThunk({
  previousCurrentRenderVideo,
  currentRenderVideo,
  previousReceivedVideoQuality,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { receivedVideoQuality },
      meeting: {
        currentUser: { userRole },
        useWBVideo,
      },
    } = state;

    if (
      (!previousCurrentRenderVideo || !previousCurrentRenderVideo.length) &&
      !currentRenderVideo.length
    ) {
      return;
    }
    const isSelfSupport1080P = isWebinar() && isViewOnly(userRole);
    // calculateQuality
    const currentRenderVideoTemp = calculateQuality(
      currentRenderVideo || [],
      receivedVideoQuality,
      isSelfSupport1080P,
      useWBVideo,
    );

    const previousCurrentRenderVideoTemp = calculateQuality(
      previousCurrentRenderVideo || [],
      previousReceivedVideoQuality,
      isSelfSupport1080P,
      useWBVideo,
    );

    //get render/subscribe info from previous render list
    const { cuurentSubscribeList, cuurentUnsubscribeList } = dispatch(
      calculateCurrentRenderVideo({
        previousCurrentRenderVideo: previousCurrentRenderVideoTemp,
        currentRenderVideo: currentRenderVideoTemp,
      }),
    );

    // active channel subscribe/unsubscribe
    if (isUnifiedRender(useWBVideo)) {
      dispatch(
        notifyRWGActiveChannel({
          cuurentSubscribeList,
          cuurentUnsubscribeList,
          currentRenderVideoTemp,
          previousCurrentRenderVideoTemp,
        }),
      );
    }

    dispatch(
      notifyRWGVideoInfo({ cuurentUnsubscribeList, cuurentSubscribeList }),
    );

    if (CLIENT_ENV === 'development') {
      // record current max decode size  only for dev
      const subscribedVideoMaxQuality = currentRenderVideoTemp.reduce(
        (maxSize, item) =>
          item?.user?.displayVideoOn
            ? Math.max(maxSize, item.quality)
            : maxSize,
        0,
      );
      dispatch(setSubscribedVideoMaxQuality(subscribedVideoMaxQuality));
    }
  };
}

function notifyRWGActiveChannel({
  cuurentSubscribeList,
  cuurentUnsubscribeList,
  currentRenderVideoTemp,
  previousCurrentRenderVideoTemp,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meetingUI: { isMinimizeMode, isPIPMode },
      meeting: {
        useWBVideo,
        currentUser: { userId: currentUserId },
      },
      video: { isSubscribeActiveChannel },
    } = state;
    const isCanSbuscribe = canSubscribeActiveChannelSelector(state);

    const activeSpeakerId = activeSpeakerIdSelector(state);
    if (!useWBVideo && isCanSbuscribe) {
      const activeUser = (cuurentSubscribeList || []).find(
        (item) =>
          item?.user && item.user.userId >> 10 === activeSpeakerId >> 10,
      );

      // It’s only related to ID ,and has no-related with subscription quality. (quality: 0-90P, 1-180P, 2-260P, 3-720P, 4-1080P)
      removeDuplicateSubscribe({
        cuurentSubscribeList,
        previousCurrentRenderVideoTemp,
        isRemovePrivateSubscribe: isMinimizeMode || isPIPMode,
      });

      if (
        previousCurrentRenderVideoTemp.length === 1 &&
        cuurentUnsubscribeList.length === 1 &&
        previousCurrentRenderVideoTemp[0].user.userId ===
          cuurentUnsubscribeList[0].user.userId &&
        (isMinimizeMode || isPIPMode)
      ) {
        // only subscribe active channel when in PIP or minimize window.
        // so remove redundant unsubscribes(private channel)
        cuurentUnsubscribeList.splice(0);
      }

      if (!activeUser) {
        const user = (currentRenderVideoTemp || [])
          .filter(
            (item) =>
              item?.user && item.user.userId >> 10 === activeSpeakerId >> 10,
          )
          .reduce((res, item) => {
            if (!res || res.quality < item.quality) {
              res = item;
            }

            return res;
          }, null);
        if (!isSubscribeActiveChannel && user) {
          dispatch(subscribeActiveChannel(user.quality));
          user.quality = VIDEO_TYPE_90P;
          cuurentSubscribeList.push(user);
        }
        return;
      }

      // subscribe active channel
      dispatch(subscribeActiveChannel(activeUser.quality));
      // small window only subscribe low quality, beacuse active channel already subscribe high quality
      activeUser.quality = VIDEO_TYPE_90P;
    } else {
      if (isSubscribeActiveChannel) {
        restoreActiveSpeakerVideoSubscribe({
          cuurentSubscribeList,
          currentRenderVideoTemp,
          currentUserId,
          activeSpeakerId,
        });
      }
      dispatch(unsubscribeActiveChannel());
    }
  };
}

function unsubscribeActiveChannel() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isSubscribeActiveChannel },
    } = state;

    if (!isSubscribeActiveChannel) {
      return;
    }
    dispatch(setActiveChannelQuality(-1));
    dispatch(setIsSubscribeActiveChannel(false));
    globalVariable.avSocket.sendSocket(UI_UNSUBSCRIBE_VIDEO, [{ id: 1 }]);
  };
}

function subscribeActiveChannel(quality) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isSubscribeActiveChannel, activeChannelQuality },
    } = state;

    if (isSubscribeActiveChannel && activeChannelQuality === quality) {
      return;
    }
    const subInfoList = [
      {
        id: 1,
        size: quality,
        bOn: false,
      },
    ];
    dispatch(setActiveChannelQuality(quality));
    dispatch(setIsSubscribeActiveChannel(true));
    // subscribe active channel
    globalVariable.avSocket.sendSocket(UI_SUBSCRIBE_VIDEO, subInfoList);
  };
}

function removeDuplicateSubscribe({
  cuurentSubscribeList = [],
  previousCurrentRenderVideoTemp = [],
  isRemovePrivateSubscribe = false,
}) {
  if (cuurentSubscribeList.length === 0) {
    return;
  }

  // only subscribe active channel when in PIP or minimize window.
  if (isRemovePrivateSubscribe) {
    cuurentSubscribeList.splice(0);
    return;
  }

  const previousSubscribeId = previousCurrentRenderVideoTemp.reduce(
    (ids, renderVideo) => {
      if (
        renderVideo?.user &&
        renderVideo.user.displayVideoOn &&
        !ids.includes(renderVideo.user.userId)
      ) {
        ids.push(renderVideo.user.userId);
      }
      return ids;
    },
    [],
  );

  if (previousSubscribeId.length === 0) {
    return;
  }

  const newCuurentSubscribeList = cuurentSubscribeList.filter((item) => {
    if (item?.user && previousSubscribeId.includes(item.user.userId)) {
      return false;
    }
    return true;
  });

  if (newCuurentSubscribeList.length === cuurentSubscribeList.length) {
    return;
  }
  cuurentSubscribeList.splice(0);
  cuurentSubscribeList.push(...newCuurentSubscribeList);
}

// when pin or spotlight active speaker, need to restore active speaker video subscription
function restoreActiveSpeakerVideoSubscribe({
  cuurentSubscribeList,
  currentRenderVideoTemp,
  currentUserId,
  activeSpeakerId,
}) {
  const isExistSubscription = (cuurentSubscribeList || []).some(
    (item) => item?.user && item.user.userId >> 10 === activeSpeakerId >> 10,
  );

  if (isExistSubscription) {
    return;
  }

  const subscribeActiveIdx = (currentRenderVideoTemp || []).findIndex(
    (item) => item?.user && item.user.userId >> 10 === activeSpeakerId >> 10,
  );
  if (
    subscribeActiveIdx !== -1 &&
    currentUserId !== currentRenderVideoTemp[subscribeActiveIdx]?.user?.userId
  ) {
    cuurentSubscribeList.push(currentRenderVideoTemp[subscribeActiveIdx]);
  }
}
