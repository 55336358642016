import React from 'react';
import meetingConfig from 'meetingConfig';
import Modal from '../../../global/components/widget/modal';
import { sendSocketMessage } from '../../../actions/SocketActions';
import {
  setArchivingInfo,
  updateparticipantsRemoveMode,
} from '../../../actions/MeetingActions';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import {
  MUTE_VIDEO_MODAL_START,
  MUTE_VIDEO_MODAL_STOP,
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_STANDARD_VIEW,
} from '../../video/enum';
import {
  setClaimHostDialogInfo,
  toggleInstanceOpen,
  toggleMuteAllUnmuteThemselvesChecked,
} from './dialog-action';
import * as Resource from '../resource';
import {
  DLP_BLOCK,
  DLP_NO_SEND_MESSAGE,
  DLP_SEND_MESSAGE,
  DLP_WARNING,
  GETTY_DIALOG_WORDING_WEBINAR_1,
  PROMOTE_CONFIRM_CONTENT_1,
  PROMOTE_CONFIRM_CONTENT_2,
} from '../resource';
import { claimStatusType } from '../enum';

import { EVT_TYPE_WS_CONF_LOCK_SHARE_REQ } from '../../sharing/enum';
import {
  coOrHostSelector,
  raiseHandParticipantSelector,
} from '../../../global/redux/selector';
import { spotlightVideoListSelector } from '../../video/redux/selectors/video-common-selector';
import {
  isDisablePinSelector,
  isDisableSpotlightSelector,
} from '../../video/redux/selectors/video-avatar-menu-selector';
import { hangUpAudio } from '../../audio/redux/audio-thunk-action';
import { isSupportReport } from '../../../global/service';
import {
  ELEMENT_PLACEHOLDER,
  SESSIONSTORAGE_KEYS,
} from '../../../global/constant';
import { LEARN_MORE } from '../../../global/resource';
import { isExternalControlledMode } from '../../../global/util';
import { easyStore, storeType } from '../../../global/easy-store';
import { setHasAlertTimeoutDialog } from '../../recording/redux/recording-action';
import {
  setIsSwapVideoSharedThunk,
  setVideoLayoutThunk,
} from '../../video/redux/thunks/video-layout-change-thunk';
import { startCaptureVideo } from '../../video/redux/thunks/start-stop-capture-video-thunk';
import { clearPinVideoListThunk } from '../../video/redux/thunks/update-pin-thunk';
import { clearSpotlightVideoListThunk } from '../../video/redux/thunks/update-spotlight-thunk';

import { REPORT_TO_ZOOM_TEXT } from '../../security/resource';
import {
  setNeedReportToZoom,
  setReportContentWindowVisible,
  setReportedUsers,
} from '../../security/redux/security-action';
import { removeText } from '../../participants/resource';
import Checkbox from '../../../global/components/widget/checkbox/checkbox';

import {
  setHasShowCannotDragDialog,
  setPinVideoList,
  setShouldRebackPreviousView,
  setSpotlightVideoList,
} from '../../video/redux/video-action';
import { SEND_TXT } from '../../q-a/resource';
import { DLPCheck } from '../../chat/utils';
import { DLPActionType } from '../../chat/constants';
import { Paragraph } from '../../../components/utils/paragraph';
import {
  POP_UP_BOX_DISABLED_SHARE1_DESC,
  POP_UP_BOX_DISABLED_SHARE2_DESC,
  POP_UP_BOX_DISABLED_SHARE3_DESC,
  POP_UP_BOX_DISABLED_SHARE_TITLE,
  POP_UP_BOX_START_SHARE_DESC,
  POP_UP_BOX_START_SHARE_TITLE,
} from '../../sharing/resource';
import {
  doSharer,
  stopSharer,
  stopSharing,
  toggleShare,
} from '../../sharing/redux/sharing-thunk-action';
import { checkArchivingOptions } from '../components/archiving';
import {
  PLS_TRY_AGAIN_TEXT,
  START_RECORDING_ERROR_TEXT,
} from '../../recording/resource';

import { SESSION_APPEARANCE_FAILED } from '../../video/resource';
import reactStringReplace from '../../../global/resct-string-replace';
import { WcLink } from '../../../components/utils/link2';
import { MODAL_CONFIRM_NAME } from '../../../global/components/widget/modal/constant';
import {
  CLOSE_SHARING_AND_START_WHITEBOARD,
  CLOSE_WHITEBOARD_AND_START_SHARING,
  DISABLED_SHARING_IN_WHITEBOARD,
  DISABLED_WHITEBOARD_IN_SHARING,
  DISABLED_WHITEBOARD_IN_SHARING_TO_BO,
  POP_UP_BOX_DISABLED_WB2_DESC,
  POP_UP_BOX_DISABLED_WB_DESC,
  POP_UP_BOX_START_WHITEBOARD_DESC,
  SCREEN_SHARE_IN_PROGRESS,
  WB_CONNECTION_ERROR,
  WB_LOAD_ERROR,
  WB_SIMULTANEOUS_ERROR_CONTENT,
  WB_SIMULTANEOUS_ERROR_TITLE,
  WHITEBOARD_SESSION_IN_PROGRESS,
} from '../../whiteboard/resource';
import {
  closeWhiteboard,
  openWhiteboardWindow,
  stopShareWhiteboard,
  toggleWhiteboardWindow,
} from '../../whiteboard/redux/whiteboard-thunk-action';
import { setHasStartWhiteboardShare } from '../../whiteboard/redux/whiteboard-action';
import {
  commonModalThunk,
  instantDialogRef,
  notAllowUnmuteDialogRef,
  startRecordingErrorRef,
} from '../service/command-dialog';
import { isWebinar } from '../../../global/service/meeting-types';
import { isPanelist } from '../../../global/service/user-types';
import {
  clickRecordingFullStorageDialogTelemetry,
  clickRecordingUnableDialogTelemetry,
  recordingEventName,
} from '../../recording/record-telemetry';
import {
  assignHostThunkAction,
  removeUserThunkAction,
} from '../../../global/redux/thunk-action/assign-host';
import {
  endMeetingThunkAction,
  leaveMeetingThunkAction,
} from '../../../global/redux/thunk-action/end-meeting-request';
import { defaultCancelButtonProps, defaultOkButtonProps } from '../constant';

export const onAfterClose = (dispatch) => {
  dispatch(toggleInstanceOpen(false));
};

export function inviteWhenLockMeeting() {
  return (dispatch, getState) => {
    dispatch(toggleInstanceOpen(true));
    const coOrHost = coOrHostSelector(getState());
    const okText = coOrHost ? Resource.UNLOCK_MEETING : Resource.DIALOG_CANCEL;
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText,
      okButtonProps: defaultOkButtonProps,
      cancelText: coOrHost ? Resource.DIALOG_CANCEL : '',
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'Unlock meeting dialog',
      okCancel: coOrHost,
      title: Resource.DIALOG_CONFIRM_TITLE,
      content: Resource.UNLOCK_MEETING_CONTENT,
      ref: instantDialogRef,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        if (coOrHost) {
          const data = {
            evt: socketEventTypes.WS_CONF_LOCK_REQ,
            body: {
              bLock: false,
            },
          };
          dispatch(sendSocketMessage(data));
        }
      },
    });
  };
}

// hang up
export function hangup() {
  return (dispatch, getState) => {
    const {
      meeting: { isHost },
    } = getState();
    let cancelText = '';
    if (isWebinar()) {
      cancelText = isHost
        ? Resource.DIALOG_END_WEBINAR
        : Resource.DIALOG_LEAVE_WEBINAR;
    } else {
      cancelText = isHost
        ? Resource.DIALOG_END_MEETING
        : Resource.DIALOG_LEAVE_MEETING;
    }
    const desc = (
      <>
        <div className="dialog-instance__tip1">{Resource.HANG_UP_CONFIRM}</div>
        <div className="dialog-instance__tip2">
          {Resource.HANG_UP_CONFIRM_2}
        </div>
      </>
    );
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.HANG_UP_BTN,
      okButtonProps: defaultOkButtonProps,
      cancelText,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'Hang up confirm dialog',
      title: Resource.HANG_UP_BTN,
      content: desc,
      ref: instantDialogRef,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(hangUpAudio());
      },
      onCancel: () => {
        if (isHost) {
          dispatch(endMeetingThunkAction());
        } else {
          dispatch(leaveMeetingThunkAction());
        }
      },
    });
  };
}

// expel
export function expelParticipant({ userId, jid, userName, isAttendee }) {
  return (dispatch, getState) => {
    const {
      security: { reportDomain },
      attendeesList: { attendeesList },
      breakoutRoom: {
        attendee: { status },
      },
    } = getState();
    const isCanReport = isSupportReport(reportDomain, status);
    dispatch(toggleInstanceOpen(true));
    if (isCanReport) {
      dispatch(setNeedReportToZoom(true));
    } else {
      dispatch(setNeedReportToZoom(false));
    }
    const content = isAttendee
      ? Resource.expelAttendee(userName)
      : `${Resource.EXPEL_CONFIRM} ${userName}?`;
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: removeText,
      okButtonProps: {
        ...defaultOkButtonProps,
        type: 'error',
      },
      cancelText: Resource.DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'Expel attendee confirm dialog',
      title: Resource.DIALOG_CONFIRM_TITLE,
      content,
      ref: instantDialogRef,
      checkboxText: isCanReport ? REPORT_TO_ZOOM_TEXT : '',
      checkboxProps: {
        onChange: (value) => {
          dispatch(setNeedReportToZoom(value));
        },
        defaultChecked: true,
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        if (isAttendee) {
          dispatch(
            sendSocketMessage({
              evt: socketEventTypes.WS_CONF_EXPEL_ATTENDEE_REQ,
              body: {
                jid,
                nodeID: userId,
              },
            }),
          );
        } else {
          dispatch(removeUserThunkAction(userId, userName));
          dispatch(updateparticipantsRemoveMode(false));
        }
        const {
          security: { isReportToZoom },
        } = getState();
        if (isReportToZoom && !isWebinar()) {
          const targetUser = attendeesList.find(
            (attendee) => attendee.userId === userId,
          );
          if (targetUser) {
            dispatch(setReportedUsers([targetUser]));
          }
          dispatch(setReportContentWindowVisible(true));
        }
      },
    });
  };
}

// about video
export function aboutVideo() {
  return () => {
    Modal.confirm({
      className: 'zm-modal-legacy',
      footer: <div />,
      contentLabel: 'About video',
      title: Resource.ABOUT_VIDEO_TITLE,
      content: Resource.ABOUT_VIDEO_DESC,
      ref: instantDialogRef,
    });
  };
}

// make host
export function makeHost({ userId, userName }) {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_YES,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'make host confirm dialog',
      title: Resource.DIALOG_CONFIRM_TITLE,
      content: `${Resource.MAKE_HOST_CONFIRM} ${userName}?`,
      ref: instantDialogRef,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(assignHostThunkAction(userId));
      },
    });
  };
}

// make co-host
export function makeCoHost({ userId, userName }) {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_YES,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'make co-host confirm dialog',
      title: Resource.DIALOG_CONFIRM_TITLE,
      content: `${Resource.tMakeCoHost(userName)}`,
      ref: instantDialogRef,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        const data = {
          evt: socketEventTypes.WS_CONF_ASSIGN_HOST_REQ,
          body: {
            id: userId,
            bCoHost: true,
          },
        };
        dispatch(sendSocketMessage(data));
      },
    });
  };
}
// mute video
export function muteVideo({ type }) {
  return (dispatch, getState) => {
    let desc = null;
    let okText = null;
    let cancelText = null;
    let okCancel = true;
    let onOk = null;
    let name = null;
    if (type === MUTE_VIDEO_MODAL_START) {
      desc = Resource.MUTE_VIDEO_DESC_START;
      okText = Resource.MUTE_VIDEO_BTN_START;
      cancelText = Resource.MUTE_VIDEO_BTN_LATER;
      onOk = () => {
        const {
          video: { isControllerDisableVideo },
          meeting: {
            currentUser: { bVideoOn },
          },
        } = getState();
        if (isControllerDisableVideo || bVideoOn) {
          return;
        }
        dispatch(startCaptureVideo());
        name = MODAL_CONFIRM_NAME.MUTE_VIDEO_START;
      };
    } else if (type === MUTE_VIDEO_MODAL_STOP) {
      desc = Resource.MUTE_VIDEO_DESC_STOP;
      okText = Resource.DIALOG_OK;
      okCancel = false;
      onOk = () => {};
      name = MODAL_CONFIRM_NAME.MUTE_VIDEO_STOP;
    }
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText,
      okButtonProps: defaultOkButtonProps,
      cancelText,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'Mute Video Modal',
      okCancel,
      title: Resource.DIALOG_MEETING_ALERT,
      content: desc,
      ref: instantDialogRef,
      name,
      isSingleton: true,
      onOk,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

// unmute audio
export function showNotAllowUnmuteDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      okButtonProps: defaultOkButtonProps,
      contentLabel: 'unmute audio dialog',
      okCancel: false,
      title: Resource.DIALOG_MEETING_ALERT,
      content: Resource.UNMUTE_AUDIO_CONFIRM,
      ref: notAllowUnmuteDialogRef,
      isSingleton: true,
      name: MODAL_CONFIRM_NAME.NOT_ALLOW_UNMUTE,
      onOk: () => {
        // pass
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

// asign new cc type
export function asignNewCCType(participant) {
  return (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();
    const newTargetName = participant && participant.displayName;
    const targetItemList = attendeesList.filter((item) => item.bCCEditor);
    const oldTargetName = targetItemList[0] && targetItemList[0].displayName;
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_YES,
      okButtonProps: defaultOkButtonProps,
      contentLabel: 'asign new cc type dialog',
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      title: Resource.DIALOG_CONFIRM_TITLE,
      content: Resource.asignCCConfirm([oldTargetName, newTargetName]),
      ref: instantDialogRef,
      onOk: () => {
        const data = {
          evt: socketEventTypes.WS_CONF_ASSIGN_CC_REQ,
          body: {
            bCCEditor: !participant.bCCEditor,
            id: participant.userId,
          },
        };
        dispatch(sendSocketMessage(data));
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

// mute all
export function muteAllParticipants() {
  return (dispatch, getState) => {
    const {
      meeting: { bCanUnmute },
    } = getState();
    dispatch(toggleInstanceOpen(true));
    // should initialize redux checked value
    dispatch(toggleMuteAllUnmuteThemselvesChecked(bCanUnmute));
    const checkboxProps = {
      onChange: (value) => {
        dispatch(toggleMuteAllUnmuteThemselvesChecked(value));
      },
      defaultChecked: bCanUnmute,
    };
    const content = (
      <div className="zm-modal-footer-default-checkbox zm-modal-footer-body-checkbox">
        <Checkbox {...checkboxProps}>
          {Resource.MUTE_ALL_CHECKBOX_TEXT}
        </Checkbox>
      </div>
    );
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_CONTINUE,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'mute all confirm dialog',
      title: Resource.MUTE_ALL_TITLE_NEW,
      content,
      ref: instantDialogRef,
      onOk: () => {
        const {
          dialog: {
            muteAll: { unmuteThemselvesChecked },
          },
          meeting: { bCanUnmute },
        } = getState();
        const data = {
          evt: socketEventTypes.WS_AUDIO_MUTEALL_REQ,
          body: {
            bMute: true,
          },
        };
        dispatch(sendSocketMessage(data));
        if (unmuteThemselvesChecked !== bCanUnmute) {
          const body = {
            bOn: !bCanUnmute,
          };
          const data = {
            evt: socketEventTypes.WS_CONF_ALLOW_UNMUTE_AUDIO_REQ,
            body,
          };
          dispatch(sendSocketMessage(data));
        }
      },
      onCancel: () => {
        dispatch(toggleMuteAllUnmuteThemselvesChecked(bCanUnmute));
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

// mute participant upon antry
export function muteOnEntry(selected) {
  return (dispatch, getState) => {
    const data = {
      evt: socketEventTypes.WS_CONF_SET_MUTE_UPON_ENTRY_REQ,
      body: {
        bOn: !selected,
      },
    };
    dispatch(toggleInstanceOpen(true));
    const {
      meeting: { bCanUnmute },
    } = getState();
    // should initialize redux checked value
    dispatch(toggleMuteAllUnmuteThemselvesChecked(bCanUnmute));
    const checkboxProps = {
      onChange: (value) => {
        dispatch(toggleMuteAllUnmuteThemselvesChecked(value));
      },
      defaultChecked: bCanUnmute,
    };
    const content = (
      <div className="zm-modal-footer-default-checkbox zm-modal-footer-body-checkbox">
        <Checkbox {...checkboxProps}>
          {Resource.MUTE_ON_ENTRY_CHECKBOX_TEXT}
        </Checkbox>
      </div>
    );
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_CONTINUE,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'mute all confirm dialog',
      title: Resource.MUTE_ON_ENTRY_TITLE_NEW,
      content,
      ref: instantDialogRef,
      onOk: () => {
        const {
          dialog: {
            muteAll: { unmuteThemselvesChecked },
          },
        } = getState();
        dispatch(sendSocketMessage(data));
        if (unmuteThemselvesChecked !== bCanUnmute) {
          dispatch(
            sendSocketMessage({
              evt: socketEventTypes.WS_CONF_ALLOW_UNMUTE_AUDIO_REQ,
              body: {
                bOn: unmuteThemselvesChecked,
              },
            }),
          );
        }
      },
      onCancel: () => {
        dispatch(toggleMuteAllUnmuteThemselvesChecked(bCanUnmute));
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function promoteUnavailableTalkAttendee(participant) {
  return (dispatch) => {
    const { jid, node, clientCap, name } = participant;
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.PROMOTE_TO_PANELIST_BTN,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'promote to panelists dialog',
      title: Resource.PROMOTE_TO_PANELIST_BTN,
      content: Resource.allowToTalkPromote(name),
      ref: instantDialogRef,
      onOk: () => {
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_ROLE_CHANGE_REQ,
            body: {
              jid,
              userID: node,
              clientCap,
              bPromote: true,
            },
          }),
        );
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function switchToSingleShare(value) {
  return (dispatch) => {
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_YES,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'switch to single participant share dialog',
      title: Resource.SWITCH_TO_SINGLE_SHARE_TITLE,
      content: Resource.SWITCH_TO_SINGLE_SHARE_CONTENT,
      ref: instantDialogRef,
      onOk: () => {
        dispatch(
          sendSocketMessage({
            evt: EVT_TYPE_WS_CONF_LOCK_SHARE_REQ,
            body: {
              lockShare: value,
            },
          }),
        );
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function separateAudio(participant) {
  return (dispatch) => {
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.SEPARATE_AUDIO_BTN,
      okButtonProps: defaultOkButtonProps,
      cancelText: Resource.DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'separate audio',
      title: Resource.SEPARATE_AUDIO_TITLE,
      content: Resource.separateAudioContent(participant.displayName),
      ref: instantDialogRef,
      onOk: () => {
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_BIND_UNBIND_TELE_USR_REQ,
            body: {
              targetUserID: participant.userId,
              teleUserID: participant.userId,
              bBind: false,
            },
          }),
        );
      },
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function spotLightVideo({
  type,
  userId,
  userName,
  videoLayout,
  pinVideoList,
}) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId: currentUserId },
      },
      video: { shouldRebackPreviousView },
    } = getState();

    const coOrHost = coOrHostSelector(getState());

    const spotlightVideoList = spotlightVideoListSelector(getState());
    const isDisableSpotlight = isDisableSpotlightSelector(getState());

    if (type === 'add' && isDisableSpotlight) {
      return;
    }

    let id = userId;
    // spotlight and pin btn all display case
    // coOrHost
    if (spotlightVideoList.length === 1 && type === 'remove' && coOrHost) {
      id = spotlightVideoList[0];
    }

    // current user
    if (type === 'remove' && !coOrHost) {
      id = spotlightVideoList.find((id) => id === currentUserId);
    }

    const spotlightData = {
      evt: socketEventTypes.WS_CONF_SET_GROUP_LAYOUT,
      body: {
        id,
        bReplace: type === 'update',
        bSpotlight: type === 'add' || type === 'update',
        bUnSpotlightAll: false,
      },
    };

    if (type === 'add' && pinVideoList.length > 0) {
      Modal.confirm({
        className: 'zm-modal-legacy',
        okButtonProps: defaultOkButtonProps,
        okText: Resource.DIALOG_CONTINUE,
        cancelText: Resource.DIALOG_CANCEL,
        cancelButtonProps: defaultCancelButtonProps,
        contentLabel: 'remove all pinned videos',
        title: Resource.DIALOG_FOR_CANCEL_PIN,
        content: Resource.toCancelAllPin(userName),
        ref: instantDialogRef,
        onAfterClose: () => {
          onAfterClose(dispatch);
        },
        onOk: () => {
          dispatch(clearPinVideoListThunk(false));
          dispatch(sendSocketMessage(spotlightData));
          dispatch(setSpotlightVideoList({ type, userId }));
          if (
            videoLayout === VIDEO_LAYOUT_GALLERY_VIEW ||
            videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW
          ) {
            dispatch(setShouldRebackPreviousView(videoLayout));
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
          }
        },
      });
    } else {
      // handle spotlight list(add update remove)
      dispatch(sendSocketMessage(spotlightData));
      dispatch(setSpotlightVideoList({ type, userId }));

      if (type === 'remove') {
        // spot list length is 1, means after remove spotlight list is 0
        if (shouldRebackPreviousView && spotlightVideoList.length === 1) {
          if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
            dispatch(setVideoLayoutThunk(shouldRebackPreviousView));
          }

          if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
            if (shouldRebackPreviousView === VIDEO_LAYOUT_GALLERY_VIEW) {
              dispatch(
                setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW),
              );
            } else {
              dispatch(
                setVideoLayoutThunk(
                  VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW,
                ),
              );
            }
          }
          dispatch(setShouldRebackPreviousView(''));
        }
      } else {
        // add/update case change layout
        if (
          videoLayout === VIDEO_LAYOUT_GALLERY_VIEW ||
          videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW
        ) {
          dispatch(setShouldRebackPreviousView(videoLayout));
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
        }

        if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW) {
          dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW));
        }

        if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW) {
          dispatch(
            setShouldRebackPreviousView(VIDEO_LAYOUT_MULTI_SPEAKER_VIEW),
          );
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW));
        }
      }
    }
  };
}

export function pinVideo({ type, userId, userName, videoLayout }) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { bCoHost, userRole, isHost },
      },
      video: { shouldRebackPreviousView, pinVideoList },
    } = getState();

    const spotlightVideoList = spotlightVideoListSelector(getState());
    const isDisablePin = isDisablePinSelector(getState());

    const pinData = { type, userId };
    const spotlightData = {
      evt: socketEventTypes.WS_CONF_SET_GROUP_LAYOUT,
      body: {
        bUnSpotlightAll: true,
      },
    };

    if (type === 'add' && isDisablePin) {
      return;
    }

    // add pining has spotlighted case
    if (
      type === 'add' &&
      spotlightVideoList.length > 0 &&
      !(bCoHost || isPanelist(userRole) || (isHost && isWebinar()))
    ) {
      Modal.confirm({
        className: 'zm-modal-legacy',
        okButtonProps: defaultOkButtonProps,
        okText: Resource.DIALOG_CONTINUE,
        cancelText: Resource.DIALOG_CANCEL,
        cancelButtonProps: defaultCancelButtonProps,
        contentLabel: 'remove all spotlighted videos',
        title: Resource.DIALOG_FOR_CANCEL_SPOTLIGHT,
        content: Resource.toCancelAllSpotlight(userName),
        ref: instantDialogRef,
        onAfterClose: () => {
          onAfterClose(dispatch);
        },
        onOk: () => {
          dispatch(clearSpotlightVideoListThunk(false));
          dispatch(sendSocketMessage(spotlightData));
          dispatch(setPinVideoList(pinData));
          if (videoLayout === VIDEO_LAYOUT_GALLERY_VIEW) {
            dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
          }

          if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW) {
            dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW),
            );
          }

          if (videoLayout === VIDEO_LAYOUT_STANDARD_VIEW) {
            dispatch(setIsSwapVideoSharedThunk(true));
          }
        },
      });
    } else {
      // handle pin list(add update remove)
      dispatch(setPinVideoList(pinData));
      if (type === 'remove') {
        // pin list length is 1, means after remove pin list is 0
        if (shouldRebackPreviousView && pinVideoList.length === 1) {
          dispatch(setShouldRebackPreviousView(''));
          if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_GALLERY_VIEW));
          }

          if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW),
            );
          }
        }
      } else {
        // add/update case change layout
        if (videoLayout === VIDEO_LAYOUT_GALLERY_VIEW) {
          dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
        }

        if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW) {
          dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW));
        }

        if (videoLayout === VIDEO_LAYOUT_STANDARD_VIEW) {
          dispatch(setIsSwapVideoSharedThunk(true));
        }
      }
    }
  };
}

export function cannotDragDiaglog() {
  return (dispatch, getState) => {
    const {
      video: {
        hasShowCannotDragDialog,
        pinVideoList,
        UI: { showSelfView, showNoVideoParticipants },
      },
      meeting: { bFollowHostVideo },
    } = getState();

    const spotlightVideoList = spotlightVideoListSelector(getState());

    const hasRaiseHandParticipant =
      raiseHandParticipantSelector(getState()).length > 0;
    if (hasShowCannotDragDialog) {
      return;
    }
    let wordContent = '';
    if (hasRaiseHandParticipant) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_HAS_RAISE_HAND;
    } else if (pinVideoList.length > 0) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_HAS_PIN_NEW;
    } else if (spotlightVideoList.length > 0) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_HAS_SPOTLIGHT;
    } else if (!showNoVideoParticipants) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_HIDE_NON_VIDEO;
    } else if (!showSelfView) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_HIDE_SELF_VIDEO;
    } else if (bFollowHostVideo) {
      wordContent = Resource.CANNOT_MOVE_VIDEO_FOLLOW_HOST_ORDER;
    }
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_CONTINUE,
      contentLabel: 'remove all pinned videos',
      okCancel: false,
      title: Resource.CANNOT_MOVE_VIDEO,
      content: wordContent,
      ref: instantDialogRef,
      onAfterClose: () => {
        dispatch(setHasShowCannotDragDialog(false));
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(setHasShowCannotDragDialog(false));
      },
    });
  };
}

export function showDLPPopupThunk(actionType, sendChat = () => {}) {
  return (dispatch) => {
    let title;
    let content;
    let cancelText = null;
    let okText;
    let onOk;

    if (actionType === DLPActionType.confirm) {
      title = DLP_SEND_MESSAGE;
      content = DLP_WARNING;
      cancelText = Resource.DIALOG_CANCEL;
      okText = SEND_TXT;
      onOk = sendChat;
    } else if (actionType === DLPActionType.block) {
      title = DLP_NO_SEND_MESSAGE;
      content = DLP_BLOCK;
      okText = Resource.DIALOG_OK;
    }
    return dispatch(
      commonModalThunk({
        dialogType: 'dlpDialog',
        okText,
        cancelText,
        contentLabel: content,
        title,
        content,
        onOk,
      }),
    );
  };
}

export function sendMessageWithDLPCheckThunk(text, sendMessage = () => {}) {
  return (dispatch, getState) => {
    const {
      meeting: { chatModerationPolicy },
    } = getState();

    /* eslint-disable-next-line @babel/new-cap */
    const actionType = DLPCheck(chatModerationPolicy, text);
    if (!actionType || !Object.values(DLPActionType).includes(actionType)) {
      return sendMessage();
    }

    return dispatch(showDLPPopupThunk(actionType, sendMessage));
  };
}

export function setClaimHostDialog(res) {
  return (dispatch) => {
    let claimStatus;
    if (res.bresult === 0) {
      claimStatus = claimStatusType.SUCCESS;
    } else if (res.bresult === 3036) {
      claimStatus = claimStatusType.DEFAULT_ERROR;
    } else {
      claimStatus = claimStatusType.NETWORK_ERROR;
    }
    dispatch(
      setClaimHostDialogInfo({
        claimStatus,
        sendLock: false,
      }),
    );
  };
}

export function showMeetingHasBeenUpgradedDilog() {
  return (dispatch, getState) => {
    const {
      meeting: { isHost },
    } = getState();
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: Resource.GIFT_MEETING_TIP_FOR_OTHERS,
      title: Resource.GIFT_MEETING_TIP_FOR_OTHERS,
      content: isHost
        ? Resource.GIFT_MEETING_TIP_CONTENT_FOR_OTHERS2
        : Resource.GIFT_MEETING_TIP_CONTENT_FOR_OTHERS,
      okCancel: false,
      isModal: false,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showPromoteConfirmThunk(body) {
  return (dispatch) => {
    const { req_id } = body;
    const request = (agreed) => {
      if (agreed) {
        easyStore.easySet(
          SESSIONSTORAGE_KEYS.promoteConfirmed,
          true,
          storeType.sessionStorage,
        );
      }
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_PROMOTE__CONSENT_REQ,
          body: {
            agreed,
            req_id,
          },
        }),
      );
    };
    let { webinarDisclaimers } = meetingConfig;
    let promoteDisclaimerDesc, promoteDisclaimerTitle;
    if (webinarDisclaimers?.promoteDisclaimerDesc) {
      promoteDisclaimerDesc = webinarDisclaimers.promoteDisclaimerDesc
        .split('\n')
        .filter((v) => v);
    } else {
      promoteDisclaimerDesc = [
        PROMOTE_CONFIRM_CONTENT_1,
        PROMOTE_CONFIRM_CONTENT_2,
        GETTY_DIALOG_WORDING_WEBINAR_1,
      ];
    }
    if (webinarDisclaimers?.promoteDisclaimerTitle) {
      promoteDisclaimerTitle = webinarDisclaimers.promoteDisclaimerTitle;
    } else {
      promoteDisclaimerTitle = Resource.PROMOTE_CONFIRM_TITLE;
    }
    return dispatch(
      commonModalThunk({
        dialogType: 'promoteDialog',
        title: promoteDisclaimerTitle,
        okText: Resource.PROMOTE_CONFIRM_OK,
        cancelText: Resource.PROMOTE_CONFIRM_CANCEL,
        contentLabel: promoteDisclaimerTitle,
        /* eslint-disable-next-line @babel/new-cap */
        content: Paragraph({
          textParts: promoteDisclaimerDesc,
        }),
        timeout: 25000,
        afterTimeout: () => request(false),
        onOk: () => request(true),
        onCancel: () => request(false),
      }),
    );
  };
}

export function showCannotShareAtTheSameTimeDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: POP_UP_BOX_DISABLED_SHARE2_DESC,
      title: POP_UP_BOX_DISABLED_SHARE_TITLE,
      content: POP_UP_BOX_DISABLED_SHARE2_DESC,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showGrabSharingConfirmDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_YES,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: POP_UP_BOX_START_SHARE_DESC,
      title: POP_UP_BOX_START_SHARE_TITLE,
      content: POP_UP_BOX_START_SHARE_DESC,
      okCancel: true,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(doSharer());
      },
    });
  };
}

export function showDisableSharingDialog() {
  return (dispatch, getState) => {
    const {
      sharing: { sharerIsShareOptionLocked },
    } = getState();
    dispatch(toggleInstanceOpen(true));
    const dialogContent = sharerIsShareOptionLocked
      ? POP_UP_BOX_DISABLED_SHARE3_DESC
      : POP_UP_BOX_DISABLED_SHARE1_DESC;
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: dialogContent,
      title: POP_UP_BOX_DISABLED_SHARE_TITLE,
      content: dialogContent,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showApplyPanelistAppearanceFailedDialog(result) {
  return (dispatch) => {
    if (result !== 0) {
      dispatch(toggleInstanceOpen(true));
      Modal.confirm({
        className: 'zm-modal-legacy',
        okButtonProps: defaultOkButtonProps,
        okText: Resource.DIALOG_OK,
        title: SESSION_APPEARANCE_FAILED,
        okCancel: false,
        isModal: true,
        onAfterClose: () => {
          onAfterClose(dispatch);
        },
      });
    }
  };
}

export function handleArchivingChange(data) {
  return (dispatch) => {
    if (data.status !== undefined) {
      dispatch(
        setArchivingInfo({
          status: data.status,
          sessionReady: data.sessionReady,
        }),
      );
    }

    if (data.options !== undefined) {
      dispatch(checkArchivingOptions(data));
    }
  };
}

export function showCannotShareWhenSharingToBODialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: Resource.CANNOT_SHARE_WHEN_SHARING_TO_BO,
      title: Resource.CANNOT_SHARE_WHEN_SHARING_TO_BO,
      content: null,
      okCancel: false,
      isModal: false,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showStartRecordingTimeoutDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      ref: startRecordingErrorRef,
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      okButtonProps: defaultOkButtonProps,
      contentLabel: START_RECORDING_ERROR_TEXT,
      okCancel: false,
      title: START_RECORDING_ERROR_TEXT,
      content: PLS_TRY_AGAIN_TEXT,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showAutoRecordingTimeoutDialog() {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { bCoHost },
        isHost,
      },
      recording: { hasAlertAutoRecordingTimeout },
    } = getState();
    if ((isHost || bCoHost) && !hasAlertAutoRecordingTimeout) {
      dispatch(setHasAlertTimeoutDialog(true));
      dispatch(showStartRecordingTimeoutDialog());
    }
  };
}

export function videoCannotStartNoticeThunk() {
  const content = (
    <>
      <p>{Resource.CANNOT_START_VIDEO_TIPS2}</p>
      <p>{Resource.CANNOT_START_VIDEO_TIPS3}</p>
    </>
  );
  return () => {
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      okButtonProps: defaultOkButtonProps,
      contentLabel: 'Your video cannot be started Alert Dialog',
      title: Resource.CANNOT_START_VIDEO_TIPS1,
      content,
      ref: instantDialogRef,
      okCancel: false,
    });
  };
}

export function openVBNoticeThunk(lan = 'en-us') {
  const content = (
    <>
      <p>
        {Resource.ENABLE_VB_TIP2}
        {!isExternalControlledMode() && (
          <a
            tabIndex="0"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://support.zoom.us/hc/${lan}/articles/360060172092`}
          >
            {LEARN_MORE}
          </a>
        )}
      </p>
      <p>{Resource.ENABLE_VB_TIP3}</p>
    </>
  );

  return (dispatch, getState) => {
    const {
      meeting: { baseUrl },
    } = getState();
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      okButtonProps: defaultOkButtonProps,
      cancelText: !isExternalControlledMode() ? Resource.ENABLE_VB_TIP4 : '',
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: 'Enable Virtual Background to start video Confirm Dialog',
      title: Resource.ENABLE_VB_TIP1,
      content,
      ref: instantDialogRef,
      okCancel: !isExternalControlledMode(),
      onCancel: () => {
        window.open(`${baseUrl}/profile/setting`);
      },
    });
  };
}

export function showStartRecordingErrorDialog(body) {
  return (dispatch, getState) => {
    dispatch(toggleInstanceOpen(true));

    const { has_cmr_edit: canEdit, detail_link: detailLink } = body;
    const {
      meeting: { isOriginhost, baseUrl },
    } = getState();
    let content = '';
    let allyLabel = '';
    if (canEdit) {
      const errorTip =
        Resource.unableStartCloudRecordingContent(ELEMENT_PLACEHOLDER);

      if (isExternalControlledMode()) {
        content = Resource.RECORDING_MANAGEMENT_LINK;
      } else {
        content = reactStringReplace(errorTip, ELEMENT_PLACEHOLDER, () => (
          <WcLink
            onClick={() =>
              clickRecordingUnableDialogTelemetry(
                recordingEventName.recording_management,
              )
            }
            link={detailLink ? detailLink : `${baseUrl}/recording/management`}
          >
            {Resource.RECORDING_MANAGEMENT_LINK}
          </WcLink>
        ));
      }

      allyLabel = Resource.unableStartCloudRecordingContent(
        Resource.RECORDING_MANAGEMENT_LINK,
      );
    } else if (!canEdit && isOriginhost) {
      content = Resource.UNABLE_START_CLOUD_RECORDING_CONTENT1;
      allyLabel = content;
    } else {
      content = Resource.UNABLE_START_CLOUD_RECORDING_CONTENT2;
      allyLabel = content;
    }

    Modal.confirm({
      ref: startRecordingErrorRef,
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      onOk: () => {
        clickRecordingUnableDialogTelemetry(recordingEventName.ok);
      },
      okButtonProps: defaultOkButtonProps,
      contentLabel: allyLabel,
      okCancel: false,
      title: Resource.UNABLE_START_CLOUD_RECORDING_TITLE,
      content,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showStartRecordingGracePeriodDialog(body) {
  return (dispatch, getState) => {
    dispatch(toggleInstanceOpen(true));

    const {
      has_cmr_edit: canEdit,
      detail_link: detailLink,
      gracePeriodDate,
    } = body;
    const {
      meeting: { isOriginhost, baseUrl },
    } = getState();
    let content = '';
    let allyLabel = '';

    // International formatted date
    let date = '';
    if (typeof Intl === 'object' && 'DateTimeFormat' in Intl) {
      date = new Intl.DateTimeFormat(meetingConfig.lang, {
        dateStyle: 'long',
      }).format(gracePeriodDate);
    }

    const contentGracePeriod =
      (date && Resource.cloudRecordingGracePeriodContent(date)) || '';

    if (canEdit) {
      const errorTip =
        Resource.cloudRecordingGoRecordingManagement(ELEMENT_PLACEHOLDER);

      if (isExternalControlledMode()) {
        content = Resource.RECORDING_MANAGEMENT_LINK;
      } else {
        content = reactStringReplace(errorTip, ELEMENT_PLACEHOLDER, () => (
          <WcLink
            onClick={() =>
              clickRecordingFullStorageDialogTelemetry(
                recordingEventName.recording_management,
              )
            }
            link={detailLink ? detailLink : `${baseUrl}/recording/management`}
          >
            {Resource.RECORDING_MANAGEMENT_LINK}
          </WcLink>
        ));
      }

      allyLabel = Resource.cloudRecordingGoRecordingManagement(
        Resource.RECORDING_MANAGEMENT_LINK,
      );
    } else if (!canEdit && isOriginhost) {
      content = Resource.CLOUD_RECORDING_STORAGE_FULL_CONTENT1;
      allyLabel = content;
    } else {
      content = Resource.CLOUD_RECORDING_STORAGE_FULL_CONTENT2;
      allyLabel = content;
    }

    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: Resource.DIALOG_OK,
      onOk: () =>
        clickRecordingFullStorageDialogTelemetry(
          recordingEventName.continue_record_on_the_cloud,
        ),
      okButtonProps: defaultOkButtonProps,
      contentLabel: contentGracePeriod + allyLabel,
      okCancel: false,
      title: Resource.CLOUD_RECORDING_STORAGE_FULL_TITLE,
      content: (
        <p>
          {contentGracePeriod}
          <br></br>
          {content}
        </p>
      ),
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showDisableShareWhiteboardDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: POP_UP_BOX_DISABLED_WB_DESC,
      title: POP_UP_BOX_DISABLED_SHARE_TITLE,
      content: POP_UP_BOX_DISABLED_WB_DESC,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showCannotShareWhiteboardAtTheSameTimeDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: POP_UP_BOX_DISABLED_WB2_DESC,
      title: WHITEBOARD_SESSION_IN_PROGRESS,
      content: POP_UP_BOX_DISABLED_WB2_DESC,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showGrabShareWhiteboardConfirmDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_YES,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: POP_UP_BOX_START_WHITEBOARD_DESC,
      title: WHITEBOARD_SESSION_IN_PROGRESS,
      content: POP_UP_BOX_START_WHITEBOARD_DESC,
      okCancel: true,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(openWhiteboardWindow());
      },
    });
  };
}

export function showShareScreenWhenWhiteboardingDialog() {
  return (dispatch, getState) => {
    const {
      whiteboard: { hasStartWhiteboardShare },
    } = getState();

    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_YES,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: CLOSE_WHITEBOARD_AND_START_SHARING,
      title: WHITEBOARD_SESSION_IN_PROGRESS,
      content: CLOSE_WHITEBOARD_AND_START_SHARING,
      okCancel: true,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        if (hasStartWhiteboardShare) {
          dispatch(stopShareWhiteboard());
          dispatch(stopSharer(false, true));
          dispatch(setHasStartWhiteboardShare(false));
        }
        dispatch(closeWhiteboard());
        dispatch(toggleShare(true));
      },
    });
  };
}

export function showWhiteboardWhenSharingScreenDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_YES,
      cancelText: Resource.DIALOG_NO,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: CLOSE_SHARING_AND_START_WHITEBOARD,
      title: SCREEN_SHARE_IN_PROGRESS,
      content: CLOSE_SHARING_AND_START_WHITEBOARD,
      okCancel: true,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
      onOk: () => {
        dispatch(stopSharing());
        dispatch(toggleWhiteboardWindow(true));
      },
    });
  };
}

export function showDisableWhiteboardWhenSharingScreenDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: DISABLED_WHITEBOARD_IN_SHARING,
      title: POP_UP_BOX_DISABLED_SHARE_TITLE,
      content: DISABLED_WHITEBOARD_IN_SHARING,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showDisableWhiteboardWhenSharingScreenToBoDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: '',
      title: DISABLED_WHITEBOARD_IN_SHARING_TO_BO,
      content: '',
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showDisableShareScreenWhenWhiteboardingDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: DISABLED_SHARING_IN_WHITEBOARD,
      title: POP_UP_BOX_DISABLED_SHARE_TITLE,
      content: DISABLED_SHARING_IN_WHITEBOARD,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showWhiteboardErrorDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: WB_LOAD_ERROR,
      title: WB_CONNECTION_ERROR,
      content: WB_LOAD_ERROR,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}

export function showWhiteboardSimultaneousErrorWithSharingDialog() {
  return (dispatch) => {
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: defaultOkButtonProps,
      okText: Resource.DIALOG_OK,
      contentLabel: WB_SIMULTANEOUS_ERROR_CONTENT,
      title: WB_SIMULTANEOUS_ERROR_TITLE,
      content: WB_SIMULTANEOUS_ERROR_CONTENT,
      okCancel: false,
      isModal: true,
      onAfterClose: () => {
        onAfterClose(dispatch);
      },
    });
  };
}
