/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronSVG } from '../../../svg/icons/footer';
import { SelectedSVG } from '../../../svg/icons';
import {
  SELECT_MICROPHONE,
  SELECT_SPEAKER,
  LEAVE_PC_AUDIO,
} from '../../audio/resource';
import { PREVIEW_OPTIONS } from '../../../controller/enum';
import { setPreviewOptions } from '../utils';
import { getPreviewAVSocket, getPreviewOptions } from '../service';
import Menu from './Menu';
import MenuItem from './MenuItem';
import deviceManager from '../../../device-manager';

const MenuContent = React.forwardRef((props, ref) => {
  const avSocket = getPreviewAVSocket();
  const {
    microphones,
    speakers,
    activeMicrophone,
    activeSpeaker,
    onKeyDown,
    onMenuClick,
  } = props;

  const onLeaveComputerAudio = () => {
    avSocket.leaveAudio();
    const previewOptions = getPreviewOptions();
    setPreviewOptions(previewOptions, PREVIEW_OPTIONS.AUTO_JOIN_AUDIO, false);
    if (onMenuClick) {
      onMenuClick();
    }
  };

  const onChangeMicrophone = (deviceId) => {
    deviceManager.manuallySelectMicrophone(deviceId);
    if (onMenuClick) {
      onMenuClick();
    }
  };

  const onChangeSpeaker = (deviceId) => {
    deviceManager.manuallySelectSpeaker(deviceId);
    if (onMenuClick) {
      onMenuClick();
    }
  };

  return (
    <Menu
      className="preview__dropdown-menu"
      ref={ref}
      role="menu"
      onKeyDown={onKeyDown}
    >
      <div className="preview__menu-title">{SELECT_MICROPHONE}</div>
      {microphones.map((microphone) => (
        <MenuItem
          key={microphone.deviceId}
          className="preview__dropdown-menuitem"
          onClick={() => onChangeMicrophone(microphone.deviceId)}
          role="menuitemradio"
          aria-checked={activeMicrophone === microphone.deviceId}
          aria-label={`${SELECT_MICROPHONE} ${microphone.label}`}
        >
          {activeMicrophone === microphone.deviceId && (
            <SelectedSVG width="24" height="24" />
          )}
          <span>{microphone.label}</span>
        </MenuItem>
      ))}
      <div className="preview__menu-divider" />
      <div className="preview__menu-title">{SELECT_SPEAKER}</div>
      {speakers.map((speaker) => (
        <MenuItem
          key={speaker.deviceId}
          className="preview__dropdown-menuitem"
          onClick={() => onChangeSpeaker(speaker.deviceId)}
          role="menuitemradio"
          aria-checked={activeSpeaker === speaker.deviceId}
          aria-label={`${SELECT_SPEAKER} ${speaker.label}`}
        >
          {activeSpeaker === speaker.deviceId && (
            <SelectedSVG width="24" height="24" />
          )}
          <span>{speaker.label}</span>
        </MenuItem>
      ))}
      <div className="preview__menu-divider" />
      <MenuItem
        className="preview__dropdown-menuitem"
        onClick={onLeaveComputerAudio}
      >
        {LEAVE_PC_AUDIO}
      </MenuItem>
    </Menu>
  );
});

MenuContent.displayName = 'MenuContent';
MenuContent.propTypes = {
  microphones: PropTypes.array,
  speakers: PropTypes.array,
  activeMicrophone: PropTypes.string,
  activeSpeaker: PropTypes.string,
  onKeyDown: PropTypes.func,
  onMenuClick: PropTypes.func,
};

const AudioMenu = ({
  showToggle,
  speakers,
  microphones,
  activeSpeaker,
  activeMicrophone,
}) => {
  const [show, setShow] = useState(false);
  const btnRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      const btnContains = btnRef.current && btnRef.current.contains(e.target);
      const menuContains =
        menuRef.current && menuRef.current.contains(e.target);
      if (!btnContains && !menuContains) {
        setShow(false);
      }
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [show]);

  const handleToggle = () => {
    setShow(!show);
  };
  const handleMenuKeydown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShow(false);
      btnRef.current.focus();
    } else if (event.key === 'Escape') {
      setShow(false);
      btnRef.current.focus();
    }
  };

  const onMenuClick = () => {
    setShow(false);
  };

  if (!showToggle) {
    return null;
  }

  return (
    <>
      <button
        className="preview__toggle"
        onClick={handleToggle}
        ref={btnRef}
        aria-label="More audio controls"
      >
        <ChevronSVG />
      </button>
      {show && (
        <MenuContent
          speakers={speakers}
          microphones={microphones}
          activeSpeaker={activeSpeaker}
          activeMicrophone={activeMicrophone}
          ref={menuRef}
          onKeyDown={handleMenuKeydown}
          onMenuClick={onMenuClick}
        />
      )}
    </>
  );
};

export default AudioMenu;
