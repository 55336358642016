import { createSelector } from 'reselect';
import {
  isZRMultiStreamVideoChildUser,
  isZRMultiStreamVideoParentUser,
} from '../../../../global/service';
import { isMeSelector } from '../../../../global/redux/selector';

const attendeesListSelector = ({ attendeesList: { attendeesList } }) =>
  attendeesList;

const virtualAttendeesListSelector = ({
  attendeesList: { virtualAttendeesList },
}) => virtualAttendeesList;

const spotlightVideoListOriginSelector = ({ video: { spotlightVideoList } }) =>
  spotlightVideoList;

const simuLivePlayersUniqueIndexSelector = ({
  video: {
    simuLive: { simuLivePlayersUniqueIndex },
  },
}) => simuLivePlayersUniqueIndex;

const avatarPropsSelector = (
  _,
  { userId, isTempDisableVideo, isReceiveVideo },
) => ({
  userId,
  isTempDisableVideo,
  isReceiveVideo,
});

const participantPropsSelector = (_, { participant }) => participant;

const isClientDisableReceiveVideoSelector = ({
  video: { isClientDisableReceiveVideo },
}) => isClientDisableReceiveVideo;

export const isZRChildUserSelector = createSelector(
  [participantPropsSelector],
  (participant) => {
    return isZRMultiStreamVideoChildUser(participant);
  },
);

export const isZRParentUserSelector = createSelector(
  [participantPropsSelector],
  (participant) => {
    return isZRMultiStreamVideoParentUser(participant);
  },
);

export const isVideoTurnOnSelector = createSelector(
  [isClientDisableReceiveVideoSelector, avatarPropsSelector, isMeSelector],
  (
    isClientDisableReceiveVideo,
    { isTempDisableVideo, isReceiveVideo },
    isMe,
  ) => {
    if (isTempDisableVideo) {
      return false;
    }

    const isVideoVisible =
      isReceiveVideo &&
      (!isClientDisableReceiveVideo || (isClientDisableReceiveVideo && isMe));

    return isVideoVisible;
  },
);

// spotlightVideo list does not include waiting room user
export const spotlightVideoListSelector = createSelector(
  [spotlightVideoListOriginSelector, attendeesListSelector],
  (spotlightVideoListOrigin, attendeesList = []) => {
    const meetingList = attendeesList
      .filter((item) => !item.bHold)
      .map((item) => item.userId);

    const tempList = (spotlightVideoListOrigin || []).filter((item) =>
      meetingList.includes(item),
    );
    return tempList;
  },
);

export const simulivePlayersUserSelector = createSelector(
  [virtualAttendeesListSelector, simuLivePlayersUniqueIndexSelector],
  (virtualAttendeesList = [], simuLivePlayersUniqueIndex = 0) => {
    let simuLivePlayersUser = [];
    virtualAttendeesList.forEach((user) => {
      if (user.uniqueIndex === simuLivePlayersUniqueIndex) {
        simuLivePlayersUser = Object.assign({}, user, {
          displayVideoOn: user.bVideoOn,
        });
      }
    });
    return simuLivePlayersUser;
  },
);

export const videoLayoutSelector = ({
  video: {
    UI: { videoLayout },
  },
}) => videoLayout;
