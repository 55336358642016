import meetingConfig from 'meetingConfig';
import React from 'react';
import PropTypes from 'prop-types';
import { companyLegalText, allRightsReservedText } from '../../footer/resource';
import { WcLink } from '../../../components/utils/link2';
import {
  PRIVACY_LEGAL_POLICIES,
  SEND_REPORT,
  REPORT_ABUSE,
} from '../../security/resource';
import { isLaplaceEnabled } from '../../../global/logger/is-laplace-enabled';

const copyRightCharacter = '\u00a9';
const space = '\u00a0';
const copyRightYear = meetingConfig.copyrightYear || '2023';
const content = [
  copyRightCharacter,
  copyRightYear,
  companyLegalText,
  allRightsReservedText,
];

function PageFooter(props) {
  const { isShowReport, onShowReport, onOpenFeedback } = props;
  const divider = <span className="page-footer-divider">|</span>;

  return (
    <div className="page-footer">
      {content.map((str) => (
        <span key={str}>{str + space}</span>
      ))}
      <WcLink
        link={`${meetingConfig.baseUrl}/privacy`}
        text={PRIVACY_LEGAL_POLICIES}
      />
      {isLaplaceEnabled() && (
        <>
          {divider}
          <button onClick={onOpenFeedback}>{SEND_REPORT}</button>
        </>
      )}
      {isShowReport && (
        <>
          {divider}
          <button onClick={onShowReport}>{REPORT_ABUSE}</button>
        </>
      )}
    </div>
  );
}

export default PageFooter;

PageFooter.propTypes = {
  isShowReport: PropTypes.bool,
  onShowReport: PropTypes.func,
  onOpenFeedback: PropTypes.func,
};

PageFooter.defaultProps = {
  isShowReport: false,
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/PageFooter.scss';
