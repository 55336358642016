import PropTypes from 'prop-types';
import React from 'react';
import { WarningSVG } from '../../../svg/icons';
import clsx from 'classnames';
import { PREVIEW_ERROR } from '../consts';
import {
  LEARN_MORE,
  VIDEO_FORBIDDEN_TEXT,
  VIDEO_TAKEN_TEXT,
  AUDIO_FORBIDDEN_TEXT,
} from '../../../global/resource';
import meetingConfig from 'meetingConfig';
import { LEARN, HOW_TO_ALLOW_MICROPHONE } from '../../audio/resource';
import { CAN_NOT_DETECT_CAMERA } from '../../video/resource';

const getContent = (error) => {
  const { baseUrl } = meetingConfig;
  const deviceAccessDoc = `${baseUrl}/wc/support/mic`;
  switch (error) {
    case PREVIEW_ERROR.CAN_NOT_DETECT_CAMERA: {
      return (
        <>
          <WarningSVG />
          <span className="preview-error-text">{CAN_NOT_DETECT_CAMERA}</span>
        </>
      );
    }
    case PREVIEW_ERROR.VIDEO_FORBIDDEN: {
      return (
        <>
          <WarningSVG />
          <span className="preview-error-text">{VIDEO_FORBIDDEN_TEXT}</span>
          <a
            className="preview-error-doc"
            target="_blank"
            href={deviceAccessDoc}
            rel="noopener noreferrer"
          >
            {LEARN_MORE}
          </a>
        </>
      );
    }
    case PREVIEW_ERROR.CAMERA_TAKEN: {
      return (
        <>
          <WarningSVG />
          <span className="preview-error-text">{VIDEO_TAKEN_TEXT}</span>
        </>
      );
    }
    case PREVIEW_ERROR.AUDIO_FORBIDDEN: {
      return (
        <>
          <WarningSVG />
          <span className="preview-error-text">{AUDIO_FORBIDDEN_TEXT}</span>
          <span>&nbsp;</span>
          <a
            className="preview-error-doc"
            target="_blank"
            rel="noopener noreferrer"
            href={deviceAccessDoc}
          >
            {LEARN}
          </a>
          <span>&nbsp;</span>
          <span className="preview-error-text">{HOW_TO_ALLOW_MICROPHONE}</span>
        </>
      );
    }
  }
};
const PreviewError = (props) => {
  const { error, className, canNotDetectCamera } = props;
  if (error === PREVIEW_ERROR.NONE && !canNotDetectCamera) {
    return null;
  }
  return (
    <div className={clsx('preview-error', className)}>
      {getContent(error, canNotDetectCamera)}
    </div>
  );
};

PreviewError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  canNotDetectCamera: PropTypes.bool,
};

export default PreviewError;
