import { iText } from '../../global/util';

export const INVITE_TITLE = iText('Invite', 'apac.toolbar_invite');
export const MANAGE_PARTICIPANTS_TITLE = iText(
  'Manage Participants',
  'apac.wc_manage_participants',
);
export const PARTICIPANTS_TITLE = iText('Participants', 'apac.wc_participants');
export const SHARER_ENTRY_BUTTON_LABEL1 = iText(
  'Share',
  'apac_wc_sharer_entry_button_label1',
);
export const SHARER_ENTRY_BUTTON_LABEL2 = iText(
  'New Share',
  'apac_wc_sharer_entry_button_label2',
);
export const ARIA_SHARER_SETTING_MENU_ENTRY_BUTTON_TEXT = iText(
  'Share permission setting',
  'apac_wc_aria_sharer_setting_menu_entry_button_text',
);
export const SHARER_ENTRY_BUTTON_OPTION_LABEL = iText(
  'Share Screen Options',
  'apac_wc_sharer_entry_button_options_label',
);
export const CHAT_TITLE = iText('Chat', 'apac.wc_chat.chat');
export const RECORD_TITLE = iText('Record', 'apac.toolbar_record');
export const SETTINGS_TITLE = iText('Settings', 'apac.settings');
export const STOP_RECORD_TITLE = iText(
  'Stop Recording',
  'apac_wc_sharer_controlbar_container_rec_label',
);
export const CLOSED_CAPTION_TITLE = iText(
  'Closed Caption',
  'apac.wc_closed_caption_btn',
);
export const LIVE_TRANSCRIPTION_TITLE = iText(
  'Live Transcription',
  'apac.wc_live_transcription_btn',
);
export const HOST_ASSIGN_CC_TITLE = iText(
  'The host has assigned you to type Closed Caption',
  'apac.wc_cc_host_assigned',
);
export const REACTION_TITLE = iText('React', 'apac.wc_reactions');
export const CLOSED_CAPTION_TURN_ON_TITLE = iText(
  'Closed Caption is turned on',
  'apac.wc_closed_caption_truned_on',
);
export const CLOSED_CAPTION_TURN_OFF_TITLE = iText(
  'Closed Caption is turned off',
  'apac.wc_closed_caption_truned_of',
);
export const CLOSED_CAPTION_AVAILABLE_TITLE = iText(
  'Closed Caption is available',
  'apac.wc_closed_caption_available',
);

export const STOP_INCOMING_VIDEO_TEXT = iText(
  'Stop Incoming Video',
  'apac.wc_extra_function_stop_incoming_video',
);
export const START_INCOMING_VIDEO_TEXT = iText(
  'Start Incoming Video',
  'apac.wc_extra_function_start_incoming_video',
);

export const TURN_ON_WATERMARK_TEXT = iText(
  'Turn on Watermark',
  'apac.turn_on_watermark_text',
);

export const TURN_OFF_WATERMARK_TEXT = iText(
  'Turn off Watermark',
  'apac.turn_off_watermark_text',
);

export const DISABLE_HARDWARE_ACCELERATION_FOR_RECEIVING_VIDEO_TEXT = iText(
  'Disable hardware acceleration for receiving video',
  'apac.wc_extra_function_disable_hardware_acceleration_for_receiving_video',
);
export const ENABLE_HARDWARE_ACCELERATION_FOR_RECEIVING_VIDEO_TEXT = iText(
  'Enable hardware acceleration for receiving video',
  'apac.wc_extra_function_enable_hardware_acceleration_for_receiving_video',
);

export const MORE_BUTTON_TITLE = iText('More', 'apac.wc_more');
export const LEAVE_WEBINAR_BUTTON_TITLE = iText(
  'Leave Webinar',
  'apac.wc_leave_webinar',
);
export const LEAVE_MEETING_BUTTON_TITLE = iText(
  'Leave Meeting',
  'apac.wc_leave_meeting',
);
export const LEAVE_BO_ROOM_BUTTON_TITLE = iText(
  'Leave Breakout Room',
  'apac.wc_leave_bo_room',
);
export const KEEP_PHONE_CONNECTED = iText(
  'Keep phone connected',
  'apac.wc_footer_keep_phone_connected',
);
export const GIVE_FEEDBACK = iText(
  'Give feedback',
  'apac.wc_footer_give_feedback',
);
export const LEAVE_ROOM = iText('Leave Room', 'apac.wc_leave_room');
export const END = iText('End', 'apac.wc_end');
export const ASSIGN_NEW_HOST = iText(
  'Assign a New Host',
  'apac.wc_footer_assign_new_host',
);
export const ASSIGN_AND_LEAVE = iText(
  'Assign and Leave',
  'apac.wc_footer_assign_leave',
);
export const PAUSE_OR_STOP_RECORDING = iText(
  'Pause/Stop Recording',
  'apac.wc_recording_pause_stop',
);
export const RESUME_OR_STOP_RECORDING = iText(
  'Resume/Stop Recording',
  'apac.wc_recording_resume_stop',
);
export const PAUSE_RECORDING = iText(
  'Pause Recording',
  'apac.wc_recording_pause',
);
export const STOP_RECORDING = iText('Stop Recording', 'apac.wc_recording_stop');
export const RESUME_RECORDING = iText(
  'Resume Recording',
  'apac.wc_recording_resume',
);

export const companyText = iText(
  'Zoom Video Communications, Inc.',
  'apac.about.company',
);

export const companyLegalText = iText(
  'Zoom Communications, Inc.',
  'apac.about.company_legal_name',
);

export const allRightsReservedText = iText(
  'All rights reserved.',
  'apac.about.all_rights_reserved',
);

export const YOU_HAVE_SENT_A_REACTION_IN_HIDDEN_MODE_TIP = iText(
  'You have sent a reaction',
  'apac.reaction.you_have_sent_a_reaction_in_hidden_mode_tip',
);

export const SHARED_FILE_TEXT_IN_LAST_MESSAGE_TIP = iText(
  'Shared a file in the meeting',
  'apac.shared_file_text_in_last_message_tip',
);
export const MEETING_CHAT_TITLE = iText(
  'Meeting Chat',
  'apac.wc_chat.chat_meeting',
);
export const WEBINAR_CHAT_TITLE = iText(
  'Webinar Chat',
  'apac.wc_chat.chat_webinar',
);

export const RETURN_TO_DEFAULTS_TEXT = iText(
  'Return to defaults',
  'apac.more.return_to_defaults',
);
