/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import meetingConfig from 'meetingConfig';
import {
  meRaiseHandAsAttendee,
  updateXmppUserList,
  setXMPPPromote,
  handleXmppDisconnected,
  setIsWaitingRoomLoading,
} from '../actions/MeetingActions';
import {
  SET_IS_RECONNECTING_XMPP,
  IS_FAILOVER_TO_SOCKET_CLOSE,
  SET_WEBINAR_TOKEN,
  UPDATE_XMPP_USER_HAND_AUDIO,
} from '../constants/MeetingActionTypes';
import {
  getWebinarUserIdFromJid,
  isSinglePageFlowEnabled,
} from '../global/service';
import {
  saveCookie,
  removeCookie,
  isExternalControlledMode,
  isSafari,
} from '../global/util';
import * as socketActionTypes from '../constants/SocketActionTypes';
import {
  XMPPLog,
  infoLog,
  successLog,
  errorLog,
  infoGroupMsg,
} from '../global/web-client-logger';
import {
  EventQueueProvider,
  SOCKET_NAME_ENUM,
  RESET_WEBSOCKET_EVENTS,
  SESSIONSTORAGE_KEYS,
  getXMPPClientCap,
} from '../global';
import { setExpelInfoDialogVisible } from '../features/dialog/redux/dialog-action';
import { onReceiveXmppChatMessage } from '../features/chat/redux/chat-thunk-action';
import { easyStore, storeType } from '../global/easy-store';
import {
  setPollingStateThunk,
  onPollDataReceived,
  onPollingDocReceived,
} from '../features/poll/redux/poll-thunk-action';
import { setPollingWebLinks } from '../features/poll/redux/poll-action';
import { externalController, CONTROL_MODE_ZOOM_MSG_TYPE } from '../controller';
import { leaveAudioWhenPromoteOrDepromote } from '../features/audio/redux/audio-thunk-action';
import { updateQuestionThunk } from '../features/q-a/redux/q-a-thunk-action';
import { Task } from '@zoom/task-core';
import { taskLoadWebIM } from '../tasks/optional-task/task.load-webim';
import { leaveMeetingThunkAction } from '../global/redux/thunk-action/end-meeting-request';
import { closeSocket } from '../actions/SocketActions';
import {
  getBaseUrl,
  getMediasdkBaseUrl,
} from '../tasks/global/task-utils/common-utils';
import _ from 'lodash';
import {
  calcXmppUpdateDelay,
  Throttler,
} from '../global/flow-control/xmppControl';

export let xmppServer = {};

let closedByNewWaitingRoom = false;
/**
 * Only communicate with XMPP server if current meeting is webinar.
 * Start to communicate with XMPP, refer to 'XMPPSocket' component
 * @return null
 */
// non-webinar meeting can connect to xmpp if polling is enabled now.
export function closeXMPP(closedBy, reasonCode = 1, reason = 'normal close') {
  if (typeof webIM !== 'undefined') {
    if (!_.isEmpty(xmppServer)) {
      xmppServer.disconnect(reasonCode, reason);
      if (closedBy === 'new waiting room flow') {
        closedByNewWaitingRoom = true;
      }
      errorLog('Goodbye XMPP...');
    }
  }
}
window.closeXMPP = closeXMPP;
// let WebimStatus;
class XMPPSocket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isXmppServerInitialized: false,
      isWebIMinitialized: typeof webIM !== 'undefined',
    };
    if (!this.state.isWebIMinitialized && isSinglePageFlowEnabled()) {
      Task.watch(taskLoadWebIM).then(() => {
        this.setState({ isWebIMinitialized: true });
      });
    }
    this.throttler = new Throttler(this.callUpdateXmppUserList, 0);
  }

  showRemoveInfoModal() {
    const { dispatch } = this.props;
    dispatch(setExpelInfoDialogVisible(true));
    setTimeout(() => {
      dispatch(leaveMeetingThunkAction());
    }, 5000);
  }

  joinXmpp = async () => {
    const {
      jid,
      xmppToken,
      meetingNumber,
      node,
      role,
      displayName,
      groupids,
      conID,
      svcUrl,
      // meeting,
    } = this.props;
    const email = meetingConfig.userEmail;
    const clientCap = getXMPPClientCap();

    await webIM.initXmpp(xmppServer, {
      jid,
      xmppToken,
      meetingNumber,
      node,
      role,
      displayName,
      groupids,
      conID,
      svcUrl,
      mediaFeatureOptions: meetingConfig.mediaFeatureOptions,
      jsPath: getBaseUrl() + '/js/lib/webim.min.js',
      webMediaBase: getMediasdkBaseUrl(),
      connectParams: {
        url: `wss://${svcUrl}/wc/media/${meetingNumber}?type=x&cid=${conID}`,
        // url: `wss://rwgdev200.zoomdev.us:8000/`,
        jid,
        xmppToken,
        meetingNumber,
        node,
        role,
        displayName,
        groupids,
        email,
        clientCap,
      },
    });
  };

  callUpdateXmppUserList = () => {
    this.props.dispatch(updateXmppUserList(xmppServer.userMap));
  };

  connectXMPP = () => {
    const {
      dispatch,
      jid,
      meetingNumber,
      conID,
      // meeting,
    } = this.props;
    if (closedByNewWaitingRoom) {
      closedByNewWaitingRoom = false;
    }

    if (_.isEmpty(xmppServer)) {
      const xmppServ = new window.webIM.XMPPServerProxy();
      xmppServer = xmppServ;

      const { /* WebimInstance */ QA_QUESTION_LIST } = webIM;
      // const tmpWebimIns = new WebimInstance();
      // const { server: xmppServ } = tmpWebimIns;
      // WebimStatus = tmpWebimIns.WebimStatus;
      infoGroupMsg('the QA question list is:', QA_QUESTION_LIST);
      xmppServ.on('CONNECTED', () => {
        dispatch({ type: socketActionTypes.OPEN_XMPP_SOCKET, data: null });
        dispatch({ type: SET_IS_RECONNECTING_XMPP, data: false });
        dispatch(setXMPPPromote(false));
        successLog('XMPP CONNECTED');
      });
      xmppServ.on('userAttrUpdate', ([prev, current]) => {
        if (prev && current && current.name) {
          const isRaiseHand = prev.bRaiseHand === false && current.bRaiseHand;
          if (isRaiseHand) {
            dispatch({
              type: UPDATE_XMPP_USER_HAND_AUDIO,
              audioFeedback: `${current.name}'s hand has been raised`,
            });
            if (jid === current.jid) {
              dispatch(meRaiseHandAsAttendee(true));
            }
            return;
          }
          const isLowerHand = prev.bRaiseHand && current.bRaiseHand === false;
          if (isLowerHand) {
            dispatch({
              type: UPDATE_XMPP_USER_HAND_AUDIO,
              audioFeedback: `${current.name}'s hand has been lowered`,
            });
            if (jid === current.jid) {
              dispatch(meRaiseHandAsAttendee(false));
            }
          }
        }
      });
      xmppServ.on('XMPPStatusResponse', (payload) => {
        if (payload && payload.isConflict) {
          dispatch({
            type: socketActionTypes.CONFLICT_XMPP_SOCKET,
            data: null,
          });
        }
      });
      xmppServ.on('DISCONNECTED', () => {
        infoLog('XMPP DISCONNECTED!');
        this.throttler.clear();
        xmppServer.terminateZoomXmppWorker?.();
        xmppServer = {};
        dispatch(handleXmppDisconnected(conID, closedByNewWaitingRoom));
      });

      xmppServ.on('POOL', (data) => {
        if (isExternalControlledMode()) {
          externalController.notifyConnectorZoomMsg(
            CONTROL_MODE_ZOOM_MSG_TYPE.XMPP,
            data.action,
            data,
          );
        }
        if (data.action === 'expel') {
          this.showRemoveInfoModal();
          dispatch({ type: socketActionTypes.CLOSE_XMPP_SOCKET, data: null });
          dispatch({ type: IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
          closeXMPP();
        } else if (data.action === 'promote' || data.action === 'depromote') {
          const { token, meetingtoken } = data.data;
          dispatch(setXMPPPromote(true));
          dispatch({
            type: SET_WEBINAR_TOKEN,
            data: `${token || ''}`,
          });
          dispatch(setIsWaitingRoomLoading(true));
          easyStore.easySet(
            SESSIONSTORAGE_KEYS.webClient_webinarToken,
            `${token || ''}`,
            storeType.sessionStorage,
          );
          easyStore.easySet(
            SESSIONSTORAGE_KEYS.webClient_meetingToken,
            `${meetingtoken || ''}`,
            storeType.sessionStorage,
          );
          dispatch(leaveAudioWhenPromoteOrDepromote(data.action));
          if (data.action === 'promote') {
            easyStore.easySet(
              'JOIN_MEETING_FLOW_PROMOTE',
              true,
              storeType.sessionStorage,
            );
            // save for promote
            const value = `${
              meetingConfig.meetingNumber
            }#${getWebinarUserIdFromJid(jid, meetingNumber)}`;
            const liveTime = 2 * 60 * 60 * 1000;
            saveCookie(liveTime, 'wc_webinar', value);
          } else {
            easyStore.easySet(
              'JOIN_MEETING_FLOW_DEPROMOTE',
              true,
              storeType.sessionStorage,
            );
            removeCookie('wc_webinar');
          }

          setTimeout(() => {
            dispatch(
              closeSocket(true, RESET_WEBSOCKET_EVENTS.PROMOTE_DEPROMOTE),
            );
          }, 600);
        } else if (data.action === 'memberChange') {
          const userCount = xmppServer?.userMap?.size || 0;
          const xmppUpdateDelay = calcXmppUpdateDelay(userCount);
          this.throttler.setTick(xmppUpdateDelay * (isSafari() ? 2 : 1));
          this.throttler.throttle();
        } else if (data.action === 'putdownhands') {
          if (data.data.indexOf(jid) !== -1) {
            xmppServ.lowerHand();
          }
        }
        /* eslint-disable-next-line @babel/new-cap */
        XMPPLog(data, data.action);
      });

      xmppServ.on('POLLING_DOWNLOAD_LINK', (data) => {
        dispatch(setPollingWebLinks(data));
      });

      xmppServ.on('CONNFAIL', (e) => {
        errorLog(`Failed to comunicate with XMPP Server: ${e}`);
      });
      xmppServ.on('OnlineMembers', (data) => {
        infoLog(JSON.stringify(data));
      });
      xmppServ.on('GroupChange', (data) => {
        infoLog(JSON.stringify(data));
      });
      xmppServ.on('RosterChange', (data) => {
        infoLog(JSON.stringify(data));
      });
      xmppServ.on('WebinarQA', (data) => {
        EventQueueProvider.getRegister(SOCKET_NAME_ENUM.XMPP, [
          'WebinarQA',
        ]).emit('WebinarQA', data);
        infoLog(JSON.stringify(data));
      });
      xmppServ.on('DELETE_QUESTION_RESPONSE', (data) => {
        dispatch(
          updateQuestionThunk({
            target: {
              id: data.id,
              command: 'deleteQuestion',
            },
          }),
        );
      });
      xmppServ.on('DELETE_COMMENT_RESPONSE', (data) => {
        dispatch(
          updateQuestionThunk({
            target: {
              cid: data.cid,
              id: data.qid,
              command: 'deleteComment',
            },
          }),
        );
      });
      xmppServ.on('Message', (data) => {
        /* eslint-disable-next-line no-prototype-builtins */
        if (data.data.zmext.hasOwnProperty('webinarchat')) {
          const latestChat = {
            senderJid: data.data.zmext.webinarchat._senderjid,
            receiver: data.data.zmext.webinarchat._to,
            text: data.data.body,
            senderName: data.senderName,
            sn: data.sn,
            type: data.type,
          };
          dispatch(onReceiveXmppChatMessage(latestChat));
        }
      });
      xmppServ.on('POLLING_DOC_UPDATE', (data) => {
        dispatch(onPollDataReceived(data));
      });
      xmppServ.on('POLLING_UI_STATUS_UPDATE', (data) => {
        dispatch(setPollingStateThunk(data));
      });
      xmppServ.on('POLLING_DOCS_INFO', (data) => {
        dispatch(onPollingDocReceived(data));
      });

      xmppServer = xmppServ;
    }

    window.xmppServer = xmppServer;

    // if (WebimStatus.stats !== WebimStatus.CONNECTED) {
    try {
      infoLog('Start to comunicate with XMPP...');
      this.joinXmpp();
      this.setState({ isXmppServerInitialized: true });
    } catch (e) {
      errorLog(e);
    }
    // }
  };

  componentWillUnmount() {
    this.throttler.clear();
  }

  componentDidMount() {
    const { isWebIMinitialized } = this.state;
    const { jid, xmppSocketStatus } = this.props;
    if (
      _.isEmpty(xmppServer) &&
      jid &&
      isWebIMinitialized &&
      xmppSocketStatus !== 'conflict' &&
      window.webIM
    ) {
      infoLog('connect xmpp by [componentDidMount]');
      this.connectXMPP();
    }
    window.connectXMPP = this.connectXMPP;
  }

  componentDidUpdate() {
    const { isXmppServerInitialized, isWebIMinitialized } = this.state;
    const {
      jid,
      xmppSocketStatus,
      meeting: { loading },
    } = this.props;
    if (!jid || isXmppServerInitialized) {
      return;
    }
    if (xmppSocketStatus === 'conflict') {
      closeXMPP();
      xmppServer = {};
    }
    if (
      xmppSocketStatus === 'close' &&
      jid &&
      isWebIMinitialized &&
      !loading &&
      window.webIM
    ) {
      infoLog('Connect xmpp by [componentDidUpdate]');
      closeXMPP();
      xmppServer = {};
      this.connectXMPP();
      return;
    }
  }

  render() {
    return <span />;
  }
}

const mapStatesToProps = (state) => ({
  svcUrl: state.meeting.svcUrl,
  conID: state.meeting.conID,
  jid: state.meeting.jid,
  xmppToken: state.meeting.xtoken,
  meetingNumber: state.meeting.meetingNumber,
  node: state.meeting.currentUser.userId,
  role: state.meeting.currentUser.userRole,
  displayName: state.meeting.userName,
  groupids: state.meeting.groups,
  xmppSocketStatus: state.socketStatus.xmppSocketStatus,
  isReconnectingXMPP: state.meeting.isReconnectingXMPP,
  meeting: state.meeting,
});

export default connect(mapStatesToProps)(XMPPSocket);
