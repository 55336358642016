import { MeetingPreferencesEntity } from '@zoom/zdt_events/dist/entities/meetings/MeetingPreferences';

import { isFullScreenSelector } from '../../selectors/global-video-selector';
import { VideoLayout } from '../constant';
import { videoLayoutSelector } from '../../../features/video/redux/selectors/video-common-selector';

const meetingPreferencesEntity = new MeetingPreferencesEntity();

export const makeMeetingPreferencesEntity = (state) => {
  meetingPreferencesEntity.generalVideoLayout =
    VideoLayout[videoLayoutSelector(state)] ?? 'immersive';
  meetingPreferencesEntity.fullscreen = isFullScreenSelector(state);
  return meetingPreferencesEntity;
};
