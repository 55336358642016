import meetingConfig from 'meetingConfig';
import React from 'react';
import {
  setSharerStatus,
  setRequestingRemoteControl,
  setGrabRemoteControl,
  setRemoteControl,
  setSharingActiveNode,
  setNewSharerToastVisible,
  toggleSharerIndication,
  setWillShareToBo,
  setIsOptimizeVideoClip,
  setIsVideoShare,
  setIsSharingToBo,
  setIsReceiveSharingFromMainSession,
  setForceStopSharingInBoNotificationVisible,
  setCanShareAudioDuringThisSharing,
  updateReceivedSharedAudioMutedStatus,
  setIsSharingAudio,
  setRemoteControlSendId,
  setHasShareBarDragged,
  addReceiveSharingChannel,
  removeReceiveSharingChannel,
  setLeaveSharingAudio,
  setSharingPerformantNotification,
  setVideoIsOnBeforeSharing,
  setSharerPermissionCode,
  setRemoteControllingUserSsrc,
  setJoinRemoteControlTimer,
  setRemoteControlledUserList,
} from './sharing-action';
import { WATERMARK_VISIBLE_ON, WATERMARK_COVER_TYPE } from '../../video/enum';
import {
  stopCaptureVideo,
  startVideo,
} from '../../video/redux/thunks/start-stop-capture-video-thunk';
import { setDisableVideoForSharingPerformant } from '../../video/redux/video-action';
import {
  SHARER_STATUS_ENUM,
  EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
  START_DESKTOP_SHARING,
  STOP_DESKTOP_SHARING,
  UPDATE_REMOTE_CONTROL_PROPERTIES,
  SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
  SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
  EVT_TYPE_WS_SHARING_SUBSCRIBE_REQ,
  EVT_TYPE_WS_SHARING_UNSUBSCRIBE_REQ,
  EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
  EVT_TYPE_WS_SHARING_REMOTE_CONTROLLER_GRAB,
  START_SHARING,
  STOP_SHARING,
  CHANGE_CURRENT_SHARING_ACTIVE_SSRC,
  START_REMOTE_CONTROL,
  RESEND_REMOTE_CONTROL_POSITION_PDU,
  SHARER_PERMISSION_CODE,
  EVT_TYPE_WS_SHARING_FIRST_FRAME_DECODING_OK_REQ,
  WS_SHARING_MUTE_SHARE_AUDIO_REQ,
  WS_SHARING_SHARE_BOROOMS_REQ,
  EVT_TYPE_WS_SHARING_RESUME_REQ,
  RESUME_DESKTOP_SHARING,
  EVT_TYPE_WS_SHARING_PAUSE_REQ,
  PAUSE_DESKTOP_SHARING,
} from '../enum';
import {
  addReceiveSharingChannelForSDK,
  getShareCaptureMode,
  removeReceiveSharingChannelForSDK,
  updateUserRosterInfoToSDK,
  enableOptimizeVideoClipSDK,
  hasShareToBOConfirmDialogAlert,
} from '../service';
import { calculateVideoShareQuality, getShareStreamInfo } from '../util';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { globalVariable } from '../../../global/global-variable';
import {
  setRequestControlDialogVisible,
  setCannotShareDialogVisible,
  setLimitSharingVisible,
  setShareMaximumDialogVisible,
  setRemoteControlApproveDialogVisible,
  setRemoteControlLaunchDialogVisible,
  setShareToBoConfirmDialogVisible,
  setRemoteControlNewScreenDialogVisible,
} from '../../dialog/redux/dialog-action';
import { closeDisallowTip } from '../../dialog/components/disallow-tip';
import { DisallowTipType } from '../../dialog/enum';
import {
  shareUserSelector,
  shareUserListSelector,
  coOrHostSelector,
  isReceiveSharingSelector,
} from '../../../global/redux/selector';
import {
  hasExistingSharers as hasExistingSharersSelector,
  isSelfSharer as isSelfSharerSelector,
  isShowLimitSharingSeletor,
  isShowShareMaximumDialogSelector,
} from './sharing-selector';
import {
  isCoOrHost,
  isJoinAudio,
  isUISelfPaintSharing,
} from '../../../global/service';
import {
  toggleDialog,
  toggleIsUILayoutCleaned,
} from '../../../actions/MeetingUIActions';
import { userForbidShare } from '../../../actions/MeetingActions';
import { toggleQaWidget } from '../../q-a/redux/q-a-action';
import { toggleMeetingQaWidget } from '../../q-a-meeting/redux/q-a-action';
import { toggleIsMouseMove } from '../../footer/redux/footer-action';
import { SHARE_ATTENTION_MODE_ENUM } from '../../attention-mode/enum';
import {
  showATShareToastForHost,
  tryToShowToastForPtsInFocusMode,
} from '../../attention-mode/redux/attention-mode-thunk';
import {
  showDisableSharingDialog,
  showCannotShareAtTheSameTimeDialog,
  showGrabSharingConfirmDialog,
  showCannotShareWhenSharingToBODialog,
  showShareScreenWhenWhiteboardingDialog,
  showDisableShareScreenWhenWhiteboardingDialog,
  showWhiteboardSimultaneousErrorWithSharingDialog,
} from '../../dialog/redux/dialog-thunk-action';
import {
  isDeviceLessPerformant,
  decodeBase64ToBuffer,
  isSameUser,
  isMobileDevice,
  getSharingPlatform,
  isSupportZoomRemoteControl,
  promptA11yInfo,
  isExternalControlledMode,
  isFirefox,
} from '../../../global/util';
import { PLATFORM_TYPE_CODE } from '../../../global/enum';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { UPDATE_MEDIA_PARAMS } from '../../../constants/AVNotifyMediaSDKTypes';
import { sdkIvTypeKeyEnum } from '../../../global/crypto';
import { isInRoom } from '../../breakout-room/utils';
import {
  setJoinAudioDialogVisible,
  stopDesktopAudio,
  localSwitchDesktopOrComputerAudioCapture,
} from '../../audio/redux/audio-thunk-action';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import {
  startToBuildMSChannel,
  startToBuildMAChannel,
} from '../../breakout-room/redux/bo-room-thunk-service';
import { easyStore } from '../../../global/easy-store';
import { PWAMeetingEvent, sendMsgToPWA } from '../../../global/pwa-integration';
import {
  getPanelViewState,
  PanelName,
  changePanelViewState,
  clearAllPanel,
} from '../../../global/redux/set-panel-action';
import { closeWhiteboard } from '../../whiteboard/redux/whiteboard-thunk-action';
import { setIsShowWhiteboardWindow } from '../../whiteboard/redux/whiteboard-action';
import { WHITEBOARD_STATUS } from '../../whiteboard/enum';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import {
  WS_SHARING_REMOTE_CONTROLED_AUTH_REQ,
  WS_SHARING_REMOTE_CONTROLED_CONSENT,
  WS_SHARING_REMOTE_CONTROLED_TAKE_RIGHT_BACK_REQ,
  WS_SHARING_REMOTE_SYNC_SCREEN_INFO_TO_RCAPP_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import {
  SHARE_PAUSED_TIP,
  SHARE_RESUME_TIP,
  remoteControlEndedByRcappTip,
  requestControlClickableTip,
  requestControlWaitingApproveTip,
} from '../resource';
import { LoadingCircleSVG } from '../../../svg/icons/index';
import { LoadJs } from '../../../tasks/global/task-utils/load-js';
import { makeLogger } from '../../../global/logger';
import { getMediasdkBaseUrl } from '../../../tasks/global/task-utils/common-utils';
import { isUseUnifiedRender } from '../../../global/op-feature-option';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../global/logger/log-service/avs-laplace-telemetry';
import {
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
  externalController,
} from '../../../controller';
export const remoteControlLogger = makeLogger(['REMOTE_CONTROL']);
export const RMC_LOG = {
  SUPPORT_RMC: 'support be remote controlled',
  RECV_RMC_REQEST: 'recv remote control request',
  APPROVE_OR_DECLINE_RMC_REQUEST: 'approve or decline remote control request',
  RECV_URL_SCHEME: 'recv remote control url scheme',
  RECV_URL_SCHEME_CONSUMED: 'recv remote control url scheme been consumed',
  LAUNCH_RC_APP: 'lauch rc app with url scheme',
  LOAD_QR_JS_START: 'load qr scanner js script start',
  LOAD_QR_JS_DONE: 'load qr scanner js script done',
  QR_SCAN_START: 'qr scanning start',
  QR_SCAN_STOP: 'qr scanning stop',
  QR_CODE_REQUEST: 'send qr code request to rwg',
  QR_CODE_RESPONSE: 'recv qr code auth response',
  APPROVE_RMC_SUCCESS: 'approve remote control request success',
  APPROVE_RMC_TIMEOUT: 'approve remote control request timeout',
  RC_OPENED: 'rc app connected to rwg',
  RC_CLOSED: 'rc app disconnected to rwg',
  RMC_DEVICE_INFO: 'remote control sharing device info',
  PAUSE_SHARING: 'pause sharing',
  RESUME_SHARING: 'resume sharing',
  TAKE_BACK_RMC: 'take back remote control permission',
  TAKE_BACK_RMC_SUCCESS: 'take back remote control permission success',
  CHECK_INSTALL_LOSE_FOCUS: 'lose focus before checking rc app install',
  CHECK_INSTALL_NEVER_FOCUS: 'never focus when checking rc app install',
  CHECK_INSTALL_FOCUS_SUCCESS: 'successfuly focus when checking rc app install',
};
remoteControlLogger.log(
  `${RMC_LOG.SUPPORT_RMC}(${isSupportZoomRemoteControl()})`,
);

let delayCloseToastTimer;

const enableShareToBo = (enable) => (dispatch) => {
  dispatch(setIsSharingToBo(enable));
  globalVariable.avSocket.sendSocket(AVNotifyMediaSDKTypes.ENABLE_SHARE_TO_BO, {
    enable,
  });
};

export const toggleSharerIndicationThunk = (shouldOpen) => (dispatch) => {
  if (shouldOpen) {
    dispatch(toggleSharerIndication(true));
    delayCloseToastTimer = setTimeout(
      dispatch,
      5000,
      toggleSharerIndication(false),
    );
  } else {
    dispatch(toggleSharerIndication(false));
    if (delayCloseToastTimer) {
      clearTimeout(delayCloseToastTimer);
    }
  }
};

export const toSendFirstShareFrame = (data) => (dispatch) => {
  const message = {
    evt: EVT_TYPE_WS_SHARING_FIRST_FRAME_DECODING_OK_REQ,
    body: data,
  };
  dispatch(sendSocketMessage(message));
};

export const resetIsSharingToBO = () => (dispatch, getState) => {
  const {
    sharing: { isSharingToBO },
  } = getState();
  if (isSharingToBO) {
    dispatch(enableShareToBo(false));
    dispatch(setWillShareToBo(false));
  }
};

export const localMuteOrUnmuteReceivedSharedAudio =
  (toMute, targetUserId = null) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      sharing: { shareeCurrentActiveNodeId },
    } = state;
    if (!targetUserId) {
      dispatch(
        updateReceivedSharedAudioMutedStatus({
          [shareeCurrentActiveNodeId]: toMute,
        }),
      );
    } else {
      dispatch(
        updateReceivedSharedAudioMutedStatus({
          [targetUserId]: toMute,
        }),
      );
    }
  };

export const stopSharing = () => (dispatch, getState) => {
  const shareUser = shareUserSelector(getState());
  const {
    meeting: {
      currentUser: { userId },
    },
  } = getState();
  const sharerUserId = shareUser ? shareUser.shareUserId : userId;
  dispatch(
    stopParticipantSharing({
      userId: sharerUserId,
      sharerOn: true,
    }),
  );
};

export const stopParticipantSharing =
  ({ userId, sharerOn }) =>
  (dispatch) => {
    const data = {
      evt: EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
      body: {
        id: userId,
        bOn: sharerOn,
      },
    };
    dispatch(sendSocketMessage(data));
    if (isExternalControlledMode()) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_PARTICIPANT_SHARING,
      );
    }
  };

const stopSharer = (isStopForNewShare, bShareWb) => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
    sharing: { canShareAudioDuringThisSharing },
    dialog: {
      remoteControlApprove: { requestControlUserSsrc },
      remoteControlLaunch: { lastApprovedUserSsrc },
    },
  } = getState();

  /** take back remote control permission before stop sharer */
  dispatch(takeBackRemoteControlPermission());
  /** if some one is requeting permission, should decline */
  if (requestControlUserSsrc) {
    dispatch(approveOrDeclineRemoteControlRequest(2, requestControlUserSsrc));
  }
  /** if has approved someone, but rcapp hasn't connect success */
  if (lastApprovedUserSsrc) {
    if (requestControlUserSsrc !== lastApprovedUserSsrc) {
      dispatch(approveOrDeclineRemoteControlRequest(2, lastApprovedUserSsrc));
    }
    dispatch(
      setRemoteControlLaunchDialogVisible({
        lastApprovedUserSsrc: null,
      }),
    );
  }
  const remoteMsgObj = {
    evt: EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
    body: {
      id: userId,
      bOn: true,
      bShareWb: !!bShareWb,
    },
  };
  // if isStopForNewShare, we may not stopDesktopAudio.
  // Otherwise, it may cause a timing issue in SDK that make others cannot hear shared audio.
  // In case the new sharing failed, re-stopDesktopAudio in the error event: DESKTOP_SHARING_ERROR, DESKTOP_SHARING_SYSTEM_ERROR.
  // update: remove isStopForNewShare, processAudioCaptureTask can now guard the timing issue.
  if (canShareAudioDuringThisSharing) {
    dispatch(localMuteOrUnmuteReceivedSharedAudio(true, userId));
    dispatch(stopDesktopAudio());
    dispatch(setIsSharingAudio(false));
  }

  dispatch(sendSocketMessage(remoteMsgObj));
  dispatch(toggleSharerIndicationThunk(false));
  globalVariable.avSocket.sendSocket(STOP_DESKTOP_SHARING, {});

  dispatch(setSharerStatus(SHARER_STATUS_ENUM.ED));
  promptA11yInfo('You have stopped sharing your screen');
  sendMsgToPWA(PWAMeetingEvent.STOP_PRESENTING);
  if (!isStopForNewShare) {
    dispatch(resetIsSharingToBO());
  }

  if (isDeviceLessPerformant()) {
    dispatch(restoreVideoStatusBeforeSharing());
  }
  if (isExternalControlledMode()) {
    externalController.notifyConnectorZoomMsg(
      CONTROL_MODE_ZOOM_MSG_TYPE.UI,
      CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_PARTICIPANT_SHARING,
    );
  }
};

const doSharer = () => (dispatch, getState) => {
  //[3.5.0][user_action]user start sharing
  avsLogReport('user start sharing', [
    AVS_TAGS.share_telemetry,
    AVS_TAGS.user_action,
  ]);
  const {
    sharing: {
      sharerSsrc,
      sharerStatus,
      isWillShareToBO,
      isDisableShareAudioIntoBo,
      sharerPermissionCode,
    },
  } = getState();

  if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
    dispatch(stopSharer(true));
  }

  const showShareAudioOption = () => {
    let isShowShareAudio = isDisableShareAudioIntoBo ? !isWillShareToBO : true;
    if (
      isShowShareAudio &&
      isWebinar() &&
      sharerPermissionCode ===
        SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_MULTI_SHARE
    ) {
      isShowShareAudio = false;
    }
    return isShowShareAudio;
  };

  globalVariable.avSocket.sendSocket(START_DESKTOP_SHARING, {
    mode: getShareCaptureMode(),
    ssid: sharerSsrc,
    canvas: SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
    video: SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
    showShareAudioOption: showShareAudioOption(),
    videoParams: {
      displaySurface: 'monitor',
    },
  });
  loadQrScanner();
};

const tryLaunchOrCloseLocalSharer = (message) => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
    sharing: { sharerStatus },
  } = getState();
  const isReceiveSharing = isReceiveSharingSelector(getState());
  const targetToClose = _.find(message.body.update, {
    id: userId,
    bShareOn: false,
  });
  if (targetToClose && sharerStatus !== SHARER_STATUS_ENUM.ED) {
    dispatch(stopSharer());
  }

  const targetToLaunch = _.find(message.body.update, {
    id: userId,
    bShareOn: true,
  });

  if (targetToLaunch && sharerStatus === SHARER_STATUS_ENUM.ED) {
    dispatch(setHasShareBarDragged(false));
    dispatch(setSharerStatus(SHARER_STATUS_ENUM.ING));
    dispatch(tryToShowToastForPtsInFocusMode());
    promptA11yInfo('You are now sharing your screen');
    if (isReceiveSharing) {
      dispatch(setSharingActiveNode({ bStatus: 0 }));
    }
  }
};

function clearUILayout() {
  return (dispatch, getState) => {
    dispatch(toggleIsUILayoutCleaned(true));
    let showQAUI = getPanelViewState(getState(), PanelName.QA);
    let showMeetingQAUI = getPanelViewState(getState(), PanelName.QA2);
    const {
      meeting: { currentUser },
    } = getState();
    dispatch(toggleDialog('InviteDialog', false));
    if (isJoinAudio(currentUser)) {
      dispatch(setJoinAudioDialogVisible(false));
    }
    if (showQAUI) {
      dispatch({ type: toggleQaWidget.toString(), isOpen: true });
      dispatch(changePanelViewState(PanelName.QA));
    }
    if (showMeetingQAUI) {
      dispatch({ type: toggleMeetingQaWidget.toString(), isOpen: true });
      dispatch(changePanelViewState(PanelName.QA2));
    }
    // clean window and right panel
    dispatch(clearAllPanel());
    dispatch(toggleIsMouseMove(false));
  };
}

export function prepareForReceiveSharing({ body }, isSharingFromMainSession) {
  return (dispatch, getState) => {
    const {
      attentionMode: { shareAttentionMode, isEnableAttentionMode },
      attendeesList: { attendeesList },
      sharing: { isReceiveSharingFromMainSession },
      whiteboard: {
        whiteboardSharer: { dcsID, presenterID },
        isShowWhiteboardWindow,
      },
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    if (isShowWhiteboardWindow && body?.bStatus === 1) {
      dispatch(setIsShowWhiteboardWindow(false));
    }
    // eliminate the two cases : receiving/stop sharing from old client's in bo
    // (this message will be received from bo commandsocket)
    if (!isSharingFromMainSession && isReceiveSharingFromMainSession) {
      return;
    }
    const coOrHost = coOrHostSelector(getState());
    const shareActiveUser =
      attendeesList.find(
        (item) => item.userId >> 10 === body.activeNodeID >> 10,
      ) || {};
    if (
      isCoOrHost(shareActiveUser) ||
      shareAttentionMode !== SHARE_ATTENTION_MODE_ENUM.HOST_ONLY ||
      !isEnableAttentionMode ||
      coOrHost
    ) {
      const { bStatus } = body;

      if (bStatus === 1) {
        dispatch(clearUILayout());
      }

      dispatch(setSharingActiveNode(body));
      // dispatch(stopSubRemoteControlSender(body.activeNodeID));
    } else if (shareAttentionMode === SHARE_ATTENTION_MODE_ENUM.HOST_ONLY) {
      const copyBody = body;
      if (!coOrHost) {
        copyBody.bStatus = 0;
        copyBody.ssrc = 0;
      } else {
        dispatch(setSharingActiveNode(copyBody));
      }
    }
    if (
      coOrHost &&
      body?.bStatus === 1 &&
      shareAttentionMode === SHARE_ATTENTION_MODE_ENUM.HOST_ONLY
    ) {
      showATShareToastForHost();
    }
    if (dcsID && presenterID === userId && body?.bStatus === 1) {
      dispatch(closeWhiteboard());
      dispatch(showWhiteboardSimultaneousErrorWithSharingDialog());
    }
  };
}

export function getPrepareForReceiveShare(newNode) {
  return (dispatch) => {
    const { bStatus } = newNode;
    if (bStatus === 1) {
      dispatch(clearUILayout());
    }
    dispatch(setSharingActiveNode(newNode));
    /**
     * when participant stop sharing, client will get 20255
     * if activeNode has given remote control, try to release it
     */

    // dispatch(stopSubRemoteControlSender(newNode.activeNodeID));
  };
}

export const updateIsVideoShare = (message) => (dispatch, getState) => {
  const {
    sharing: { isVideoShare },
  } = getState();
  const targetUser = _.find(message.body.update, (n) =>
    _.has(n, 'bVideoShare'),
  );
  if (targetUser && isVideoShare !== targetUser.bVideoShare) {
    dispatch(setIsVideoShare(targetUser.bVideoShare));
  }
};

export function subscribeSharing() {
  return (dispatch, getState) => {
    const {
      sharing: {
        shareeCurrentActiveNodeId,
        shareeCurrentReceivedShareFps,
        shareeCurrentReceivedShareResolution,
        isReceiveSharingFromMainSession,
        isVideoShare,
        UI: { shareeContianerRect },
        remoteControlledUserList,
      },
      video: { isClientPageVisible },
    } = getState();
    const size = !isVideoShare
      ? shareeCurrentReceivedShareFps
      : calculateVideoShareQuality(
          shareeContianerRect,
          shareeCurrentReceivedShareResolution,
        );
    const body = {
      id: shareeCurrentActiveNodeId,
      size,
      bVideoShare: isVideoShare,
    };
    if (isReceiveSharingFromMainSession) {
      body.bShareToBO = true;
    }
    if (shareeCurrentActiveNodeId) {
      if (!isFirefox() || isClientPageVisible) {
        dispatch(
          sendSocketMessage({
            evt: EVT_TYPE_WS_SHARING_SUBSCRIBE_REQ,
            body,
          }),
        );
      }
      /**
       * rwg support switch sharing but don't give up last remote control user now.
       * some case eg:
       * 1. when self stop share and auto subscribe another user's sharing,
       *  if the user is under control, need to reset remote control status
       * 2. manual switch sharing
       */
      const isCurrentActiveUserBeControlled = !!remoteControlledUserList?.some(
        (userId) => isSameUser(userId, shareeCurrentActiveNodeId),
      );
      dispatch(setRemoteControl(isCurrentActiveUserBeControlled));
    }
  };
}

export function unsubscribeSharing(nodeId) {
  return (dispatch, getState) => {
    const {
      breakoutRoom: {
        attendee: { status },
        mainSessionAttendeeList,
      },
    } = getState();
    const body = {
      id: nodeId,
    };
    // here we can just get the status whether this unsubscribe event is from stoping-sharing-in-mainsession or normal sharing
    const isInBo = isInRoom(status) && mainSessionAttendeeList.length > 0;
    if (
      isInBo &&
      mainSessionAttendeeList.find((attendee) => attendee.userId === nodeId)
    ) {
      body.bShareToBO = true;
    }
    dispatch(
      sendSocketMessage({
        evt: EVT_TYPE_WS_SHARING_UNSUBSCRIBE_REQ,
        seq: 1,
        body,
      }),
    );
  };
}
export function startReceiveSharing({ canvas, cursorCanvas }) {
  return (dispatch, getState) => {
    const {
      sharing: { shareeCurrentSSrc, isReceiveSharingFromMainSession },
      meeting: {
        meetingOptions: { isEnableMeetingWatermark },
        waterMarkText,
      },
      video: {
        watermarkEnableTurnOnOff,
        isWatermarkTurnOn,
        watermarkVisibleOn,
        watermarkOpacityLevel,
        watermarkCoverType,
        watermarkPosition,
      },
    } = getState();
    dispatch(subscribeSharing());
    const enableWaterMark = watermarkEnableTurnOnOff
      ? isWatermarkTurnOn &&
        (watermarkVisibleOn === WATERMARK_VISIBLE_ON.SHARED_CONTENT ||
          watermarkVisibleOn === WATERMARK_VISIBLE_ON.BOTH)
      : isEnableMeetingWatermark;

    if (!isUseUnifiedRender()) {
      globalVariable.avSocket.sendSocket(START_SHARING, {
        width: 0,
        height: 0,
        ssrc: shareeCurrentSSrc,
        canvas,
        cursorCanvas,
        enableWaterMark,
        waterMarkText,
        isFromMainSession: isReceiveSharingFromMainSession,
        watermarkOpacity: watermarkOpacityLevel,
        watermarkRepeated: watermarkCoverType === WATERMARK_COVER_TYPE.REPEATED,
        watermarkPosition,
      });
    }
  };
}

export function stopReceiveSharing(previousNodeId) {
  return (dispatch, getState) => {
    const {
      sharing: { shareeCurrentActiveNodeId, isReceiveSharing },
    } = getState();
    if (isReceiveSharing) {
      if (!isUseUnifiedRender()) {
        globalVariable.avSocket.sendSocket(STOP_SHARING, {});
      }
      dispatch(unsubscribeSharing(shareeCurrentActiveNodeId));
    } else {
      if (!isUseUnifiedRender()) {
        globalVariable.avSocket.sendSocket(STOP_SHARING, {});
      }
      dispatch(unsubscribeSharing(previousNodeId));
    }
  };
}

export function subscribeGrabRemoteControl(bOn) {
  return (dispatch, getState) => {
    const {
      sharing: { shareeCurrentActiveNodeId },
    } = getState();
    dispatch(
      sendSocketMessage({
        evt: EVT_TYPE_WS_SHARING_REMOTE_CONTROLLER_GRAB,
        body: {
          id: shareeCurrentActiveNodeId,
          bOn,
        },
      }),
    );
  };
}

export function unsubscribeRemoteControl(nodeId = null) {
  return (dispatch, getState) => {
    const {
      sharing: {
        isRemoteControl,
        isGrabRemoteControl,
        shareeCurrentActiveNodeId,
      },
    } = getState();
    if (nodeId) {
      dispatch(
        sendSocketMessage({
          evt: EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
          body: {
            id: nodeId,
            bOn: false,
          },
        }),
      );
    } else {
      if (isGrabRemoteControl) {
        dispatch(subscribeGrabRemoteControl(false));
      }
      if (isRemoteControl) {
        dispatch(
          sendSocketMessage({
            evt: EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
            body: {
              id: shareeCurrentActiveNodeId,
              bOn: false,
            },
          }),
        );
      }
    }
  };
}

export function stopSubRemoteControlSender(activeNodeId) {
  return (dispatch, getState) => {
    const {
      sharing: { remoteControlSendId },
    } = getState();
    if (remoteControlSendId && isSameUser(activeNodeId, remoteControlSendId)) {
      dispatch(unsubscribeRemoteControl(activeNodeId));
      dispatch(setRemoteControlSendId(0));
    }
  };
}

export function changeCurrentSharing(previousNodeId) {
  return (dispatch, getState) => {
    const {
      sharing: {
        shareeCurrentActiveNodeId,
        shareeCurrentSSrc,
        localShareeAudioMutedStatus,
        isReceiveSharingFromMainSession,
      },
    } = getState();
    // dispatch(unsubscribeRemoteControl(previousNodeId));

    // hide toast when switch sharing
    dispatch(toggleRequestControlClickableTip(false));
    dispatch(toggleRequestControlWaitingApproveTip(false));
    dispatch(setRequestingRemoteControl(false));

    dispatch(unsubscribeSharing(previousNodeId));
    dispatch(subscribeSharing());
    globalVariable.avSocket.sendSocket(CHANGE_CURRENT_SHARING_ACTIVE_SSRC, {
      isFromMainSession: +isReceiveSharingFromMainSession,
      ssrc: shareeCurrentSSrc,
    });
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.SET_DESKTOP_VOLUME,
      {
        userid: shareeCurrentActiveNodeId >> 10,
        shareVolume: localShareeAudioMutedStatus[shareeCurrentActiveNodeId]
          ? 0
          : 100,
        isFromMainSession: isReceiveSharingFromMainSession,
      },
    );
    /**
     * release target's remote control if target has given remote control permission
     */
    // dispatch(stopSubRemoteControlSender(shareeCurrentActiveNodeId));
    /** RGW do not support remote control multiple user,so not enable this function */
    /*
    const remoteControlUser = shareeRemoteControlUserList.find(
      element => element.id === shareeCurrentActiveNodeId,
    );
    if (remoteControlUser) {
      const {
        isRequestingRemoteControl,
        isRemoteControl,
        isGrabRemoteControl,
      } = remoteControlUser;
      dispatch(setRequestingRemoteControl(isRequestingRemoteControl));
      dispatch(setRemoteControl(isRemoteControl));
      dispatch(setGrabRemoteControl(isGrabRemoteControl));
    } else {
      dispatch(setRequestingRemoteControl(false));
      dispatch(setRemoteControl(false));
      dispatch(setGrabRemoteControl(false));
    }
    */
  };
}

export function subscribeRemoteControl() {
  return (dispatch, getState) => {
    const {
      sharing: { shareeCurrentActiveNodeId },
    } = getState();
    dispatch(
      sendSocketMessage({
        evt: EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
        body: {
          id: shareeCurrentActiveNodeId,
          bOn: true,
        },
      }),
    );
    dispatch(toggleRequestControlWaitingApproveTip(true));
  };
}

export function prepareForRemoteControl({ body: { bOn, sendID, receiveID } }) {
  return (dispatch, getState) => {
    const {
      sharing: {
        isRequestingRemoteControl,
        isGrabRemoteControl,
        shareeCurrentActiveNodeId,
        sharerStatus,
      },
      meeting: {
        currentUser: { userRole: currentUserRole, userId },
        restrictFeatures,
      },
      dialog: {
        requestControl: { visible: requestControlVisible },
      },
    } = getState();
    if (sharerStatus === SHARER_STATUS_ENUM.ED) {
      // share has been stopped
      dispatch(setRemoteControllingUserSsrc(0));
    }
    // rwg will diff new or old flow by this clientCaps flag
    if (isSupportZoomRemoteControl()) {
      if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
        // receive indication when start sharing
        if (isSameUser(userId, sendID)) {
          if (bOn) {
            // approve remote control success
            dispatch(approveRemoteControlSuccess(receiveID));
          } else {
            // take back remote control permission success
            dispatch(takeBackRemoteControlPermissionSuccess());
          }
        } else if (isSameUser(userId, receiveID)) {
          if (bOn) {
            // receive remote control from others
            dispatch(handleRecvRemoteControlRequest(sendID));
          }
        }
        return;
      } else {
        /** As a viewer, if rwg use new flow "bOn" logic has changed, webclient must check receiveId is self */
        if (receiveID && !isSameUser(userId, receiveID)) return;
      }
    }
    if (bOn) {
      const isSupportRemoteControlOthers =
        !isViewOnly(currentUserRole) &&
        !isUISelfPaintSharing() &&
        !isMobileDevice() &&
        !restrictFeatures[JOIN_MEETING_POLICY.REMOTE_CONTROL];
      /**
       *  if not support remote control others
       */
      if (!isSupportRemoteControlOthers) {
        return;
      }
    }

    if (sendID) {
      // store controlled userId, switch sharing need this
      dispatch(
        setRemoteControlledUserList({
          [bOn ? 'add' : 'remove']: [sendID],
        }),
      );
    }

    /**
     * if shareUser is not equal sendUser, should not change remote control status
     */
    const isShareeCurrentActiveIndication =
      !sendID ||
      !shareeCurrentActiveNodeId ||
      isSameUser(sendID, shareeCurrentActiveNodeId);
    if (!isShareeCurrentActiveIndication) {
      // dispatch(setRemoteControlSendId(sendID));
      return;
    }

    if (bOn) {
      /** hide request dialog when given control permission */
      if (requestControlVisible) {
        dispatch(
          setRequestControlDialogVisible({ visible: false, refused: false }),
        );
      }
      // remote control is on, end requesting
      dispatch(setRequestingRemoteControl(false));
      // if grab remote control is off, try to turn it on
      if (!isGrabRemoteControl) {
        dispatch(subscribeGrabRemoteControl(true));
      }
      dispatch(clearUILayout());
    } else if (isRequestingRemoteControl) {
      // if sharer is deny, try to request again,
      dispatch(
        setRequestControlDialogVisible({ visible: true, refused: true }),
      );
      dispatch(toggleRequestControlWaitingApproveTip(false));
    } else {
      // if grab remote control is on, turn it off
      if (isGrabRemoteControl) {
        dispatch(subscribeGrabRemoteControl(false));
      }
      if (globalVariable.avSocket?.sendSocket) {
        globalVariable.avSocket.sendSocket(UPDATE_REMOTE_CONTROL_PROPERTIES, {
          isControllerNow: false,
        });
      }
    }
    dispatch(setRemoteControl(bOn));
  };
}

export function grabRemoteControl({ body: { bOn } }) {
  return (dispatch) => {
    globalVariable.avSocket.sendSocket(UPDATE_REMOTE_CONTROL_PROPERTIES, {
      isControllerNow: bOn,
    });
    dispatch(setGrabRemoteControl(bOn));
  };
}

export function startRemoteControl({ viewport, sharingWindowSize }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { conID, meetingNumber, svcUrl },
      sharing: { isGrabRemoteControl, isSDKGrabControl },
    } = state;
    const shareUser = shareUserSelector(state);
    /**
     * when shareUser give remote control permission
     * shareeCurrentActiveNodeId may be not equal with shareUser
     * if p is sharing, shareeCurrentActiveNodeId is 0 and shareUser is null
     */
    if (!shareUser || shareUser.os === PLATFORM_TYPE_CODE.WEBCLIENT) return;
    const socketURL = `wss://${svcUrl}`;
    /** sharer can  withdraw  control permission,
     *  make sure reveiver has grab remote control permission  */
    if (!isGrabRemoteControl) {
      dispatch(subscribeGrabRemoteControl(true));
    }
    if (isSDKGrabControl) {
      setTimeout(() => {
        /* use getState() to get latest state value, until enable grab remote control,
         try to recover remote control
        */
        if (getState().sharing.isGrabRemoteControl) {
          globalVariable.avSocket.sendSocket(
            RESEND_REMOTE_CONTROL_POSITION_PDU,
          );
          globalVariable.avSocket.sendSocket(UPDATE_REMOTE_CONTROL_PROPERTIES, {
            os: shareUser.shareUserOs,
            isControllerNow: true,
            ...sharingWindowSize,
          });
        } else {
          dispatch(startRemoteControl({ viewport, sharingWindowSize }));
        }
      }, 100);
    } else {
      globalVariable.avSocket.sendSocket(START_REMOTE_CONTROL, {
        socketURL,
        meetingID: meetingNumber,
        condID: conID,
        os: shareUser.shareUserOs,
        dom: viewport,
        ...sharingWindowSize,
      });
    }
  };
}

/**
 * if some sharer stop sharing and no  sharing_status_indication received,
 * should switch to another sharer
 */
export function sharingStopSwitchNewSharer(removedUserId) {
  return (dispatch, getState) => {
    const state = getState();
    const shareUser = shareUserSelector(state);
    const shareUserList = shareUserListSelector(state);
    if (
      shareUser &&
      shareUser.shareUserId === removedUserId &&
      shareUserList &&
      shareUserList.length
    ) {
      const electedUser = shareUserList[0];
      const { userId } = electedUser;
      dispatch(
        setSharingActiveNode({
          activeNodeID: userId,
          bStatus: 1,
          ssrc: userId,
        }),
      );
    }
  };
}

export function toggleToastVisible() {
  return (dispatch, getState) => {
    setTimeout(() => {
      const state = getState();
      const shareUserList = shareUserListSelector(state);
      if (shareUserList.length > 1) {
        dispatch(setNewSharerToastVisible(true));
      }
    }, 300);
  };
}

const triggerSDKShare = () => {
  return (dispatch) => {
    dispatch(doSharer());
  };
};

export function toggleShare(isContinueToShare) {
  return (dispatch, getState) => {
    const {
      sharing: { sharerPermissionCode, isReceiveSharingFromMainSession },
      meeting: { bIbDisableShare },
      whiteboard: {
        whiteboardSharer: { dcsID },
      },
    } = getState();
    const coOrHost = coOrHostSelector(getState());
    const hasExistingSharers = hasExistingSharersSelector(getState());
    const isSelfSharer = isSelfSharerSelector(getState());
    const isShowLimitSharing = isShowLimitSharingSeletor(getState());
    const isShowShareMaximumDialog = isShowShareMaximumDialogSelector(
      getState(),
    );

    if (!isContinueToShare) {
      if (dcsID) {
        if (coOrHost) {
          dispatch(showShareScreenWhenWhiteboardingDialog());
        } else {
          dispatch(showDisableShareScreenWhenWhiteboardingDialog());
        }
        return;
      }
    }

    if (isShowLimitSharing) {
      dispatch(setLimitSharingVisible({ visible: true, isSharing: false }));
      return;
    }
    if (isShowShareMaximumDialog) {
      dispatch(setShareMaximumDialogVisible({ visible: true, reached: true }));
      return;
    }
    // check if there are information barriers
    if (bIbDisableShare) {
      dispatch(setCannotShareDialogVisible(true));
      return;
    }

    if (isReceiveSharingFromMainSession) {
      dispatch(showCannotShareWhenSharingToBODialog());
      return;
    }
    switch (sharerPermissionCode) {
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE:
        if (!coOrHost) {
          dispatch(showDisableSharingDialog());
        } else {
          dispatch(triggerSDKShare());
        }
        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_HOST_GRAB:
        if (coOrHost) {
          dispatch(triggerSDKShare());
        } else if (hasExistingSharers && !isSelfSharer) {
          dispatch(showCannotShareAtTheSameTimeDialog());
        } else {
          dispatch(triggerSDKShare());
        }
        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_ANYONE_GRAB:
        if (hasExistingSharers && !isSelfSharer) {
          dispatch(showGrabSharingConfirmDialog());
        } else {
          dispatch(triggerSDKShare());
        }
        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_MULTI_SHARE:
        dispatch(triggerSDKShare());
        break;
      default:
        break;
    }
  };
}

export const updateSharingDecodeParams =
  (isFromMainSession) => (dispatch, getState) => {
    const {
      meeting: {
        avsEncryptKeys: {
          sharingEncryptKey: currentMeetingSharingEncryptKey,
          sharingEncryptType: currentMeetingSharingEncryptType,
        },
        confId: currentConfId,
        currentUser: { userId: currentUserId },
        zoomId,
      },
      breakoutRoom: {
        mainSessionConfId,
        mainSessionAttendeeList,
        mainSessionShareEncryptInfo,
      },
    } = getState();
    let [sharingEncryptKey, sharingEncryptType, confId, userId] = [
      '',
      '',
      '',
      '',
    ];
    if (isFromMainSession) {
      if (mainSessionShareEncryptInfo.encryptKey) {
        sharingEncryptKey = mainSessionShareEncryptInfo.encryptKey;
        sharingEncryptType = mainSessionShareEncryptInfo.additionalType;
      }
      confId = mainSessionConfId;
      const myInfo = mainSessionAttendeeList.find(
        (attendee) => attendee.zoomID === zoomId,
      );
      userId = myInfo?.userId;
    } else {
      sharingEncryptKey = currentMeetingSharingEncryptKey;
      sharingEncryptType = currentMeetingSharingEncryptType;
      confId = currentConfId;
      userId = currentUserId;
    }
    globalVariable.avSocket.sendSocket(UPDATE_MEDIA_PARAMS, {
      mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
      isFromMainSession: +isFromMainSession,
      updateParams: {
        userId,
        sn: decodeBase64ToBuffer(zoomId),
        confId,
        encryptKey: decodeBase64ToBuffer(sharingEncryptKey),
        encryptType: sharingEncryptType,
        meetingId: meetingConfig.mid,
        meetingNumber: meetingConfig.meetingNumber,
      },
    });
  };

// update only needed when receiving sharing from main session
export const updateSharingAudioDecodeParams = () => (dispatch, getState) => {
  const {
    meeting: { zoomId },
    breakoutRoom: {
      mainSessionConfId,
      mainSessionAttendeeList,
      mainSessionAudioEncryptInfo,
    },
  } = getState();
  const audioEncryptKey = mainSessionAudioEncryptInfo?.encryptKey;
  const audioEncryptType = mainSessionAudioEncryptInfo?.additionalType;
  const confId = mainSessionConfId;
  const myInfo = mainSessionAttendeeList.find(
    (attendee) => attendee.zoomID === zoomId,
  );
  const userId = myInfo?.userId;
  globalVariable.avSocket.sendSocket(UPDATE_MEDIA_PARAMS, {
    mediaActionType: sdkIvTypeKeyEnum.AUDIO_DECODE,
    isFromMainSession: 1,
    updateParams: {
      userId,
      sn: decodeBase64ToBuffer(zoomId),
      confId,
      encryptKey: decodeBase64ToBuffer(audioEncryptKey),
      encryptType: audioEncryptType,
      meetingId: meetingConfig.mid,
      meetingNumber: meetingConfig.meetingNumber,
    },
  });
};

export const updateSharingEncodeParams = () => (dispatch, getState) => {
  const {
    meeting: {
      avsEncryptKeys: { sharingEncryptKey, sharingEncryptType },
      confId,
      currentUser: { userId },
      zoomId,
    },
  } = getState();
  globalVariable.avSocket.sendSocket(UPDATE_MEDIA_PARAMS, {
    mediaActionType: sdkIvTypeKeyEnum.SHARING_ENCODE,
    isFromMainSession: 0,
    updateParams: {
      userId,
      sn: decodeBase64ToBuffer(zoomId),
      confId,
      encryptKey: decodeBase64ToBuffer(sharingEncryptKey),
      encryptType: sharingEncryptType,
      meetingId: meetingConfig.mid,
      meetingNumber: meetingConfig.meetingNumber,
    },
  });
};

export const startToViewMainSesionSharingInBo =
  ({ body: { bStatus } }) =>
  (dispatch, getState) => {
    const {
      sharing: { sharerStatus, isReceiveSharingFromMainSession },
    } = getState();
    const isStartToReceiveSharingFromMainSession = bStatus === 1;
    // need to stop receiving bo's sharing
    if (isStartToReceiveSharingFromMainSession) {
      // if has receiveSharingFromMainSession,do nothing
      if (isReceiveSharingFromMainSession) {
        return;
      }
      if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
        dispatch(stopSharer());
        dispatch(setForceStopSharingInBoNotificationVisible(true));
      }
    }
    dispatch(
      setIsReceiveSharingFromMainSession(
        isStartToReceiveSharingFromMainSession,
      ),
    );
  };

export const handleDesktopSharingCaptureSuccess =
  (shouldShareDesktopAudio) => (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { currentUser },
      breakoutRoom: {
        attendee: { status },
      },
      sharing: { isOptimizeVideoClip, isWillShareToBO, isReceiveSharing },
      whiteboard: { whiteboardStatus },
    } = state;
    if (whiteboardStatus !== WHITEBOARD_STATUS.OFF && !isReceiveSharing) {
      return;
    }
    const { userId } = currentUser;
    const remoteMsgObj = {
      evt: EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
      body: {
        id: userId,
        bOn: false,
        bShareAudio: shouldShareDesktopAudio,
        bShareVideo: isOptimizeVideoClip,
      },
    };
    const coOrHost = coOrHostSelector(state);
    const isInBO = isInRoom(status);
    const hasPermissionToShareToBO = coOrHost && !isInBO;

    if (isWillShareToBO && hasPermissionToShareToBO) {
      remoteMsgObj.body.bShareToBO = true;
      dispatch(enableShareToBo(true));
    } else {
      dispatch(enableShareToBo(false));
    }
    dispatch(sendSocketMessage(remoteMsgObj));
    dispatch(userForbidShare(false));
    closeDisallowTip({ type: DisallowTipType.SHARE });

    if (shouldShareDesktopAudio) {
      // If do not allow sdk to share audio when start sharing,
      // sdk will not be able to share audio during this sharing.
      dispatch(setCanShareAudioDuringThisSharing(true));
      dispatch(setIsSharingAudio(true));

      // Set localShareeAudioMutedStatus. Do not receive others shared audio.
      dispatch(localMuteOrUnmuteReceivedSharedAudio(false, userId));
      dispatch(setLeaveSharingAudio(false));
    } else {
      dispatch(setCanShareAudioDuringThisSharing(false));
      dispatch(setLeaveSharingAudio(true));
    }
  };

export const pauseOrResumeShareAudio = (toPause) => (dispatch, getState) => {
  const {
    sharing: { canShareAudioDuringThisSharing, sharerStatus },
  } = getState();

  const shouldSwitchToComputerAudio = toPause && canShareAudioDuringThisSharing;
  const shouldSwitchToDesktopAudio =
    canShareAudioDuringThisSharing &&
    !toPause &&
    sharerStatus === SHARER_STATUS_ENUM.ING;

  if (shouldSwitchToComputerAudio) {
    dispatch(localSwitchDesktopOrComputerAudioCapture(true));
  } else if (shouldSwitchToDesktopAudio) {
    dispatch(localSwitchDesktopOrComputerAudioCapture(false));
  }

  const remoteMsgObj = {
    evt: WS_SHARING_MUTE_SHARE_AUDIO_REQ,
    body: {
      // rwg will do nothing if bOn is false
      bOn: canShareAudioDuringThisSharing,
      bShareAudio: !toPause,
    },
  };
  dispatch(sendSocketMessage(remoteMsgObj));
  dispatch(setIsSharingAudio(!toPause));
};

export const optimizeVideoClipAction = (enable) => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { sharerOn, userId },
    },
    sharing: { isOptimizeVideoClip, isSharingAudio, isSharingToBO },
  } = getState();
  if (isOptimizeVideoClip === enable) return;
  const msg = {
    evt: EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
    body: {
      id: userId,
      bOn: !sharerOn,
      bShareToBO: isSharingToBO,
      bShareAudio: isSharingAudio,
      bShareVideo: enable,
    },
  };
  dispatch(sendSocketMessage(msg));
  enableOptimizeVideoClipSDK(enable);
  dispatch(setIsOptimizeVideoClip(enable));
};

export const changeLockShare = (lockShare) => (dispatch) => {
  dispatch(setSharerPermissionCode(lockShare));
  if (lockShare === SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_MULTI_SHARE) {
    dispatch(optimizeVideoClipAction(false));
  }
};

export const shareToBoAloneAction = () => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
  } = getState();
  const msg = {
    evt: WS_SHARING_SHARE_BOROOMS_REQ,
    body: {
      id: userId,
      bShareToBO: true,
    },
  };
  // TODO
  // here needs to stop sharing audio
  dispatch(enableShareToBo(true));
  dispatch(sendSocketMessage(msg));
};

export const stopSharingToBoAloneAction = () => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
  } = getState();
  const msg = {
    evt: WS_SHARING_SHARE_BOROOMS_REQ,
    body: {
      id: userId,
      bShareToBO: false,
    },
  };
  dispatch(enableShareToBo(false));
  dispatch(sendSocketMessage(msg));
};

export const receiveSharingChannelReady =
  (body, isFromMainSession) => (dispatch, getState) => {
    const {
      sharing: { shareeDecodeSdkInitialized },
    } = getState();
    const channelData = {
      ...body,
      isFromMainSession: +isFromMainSession, // change boolean type to number
    };
    if (shareeDecodeSdkInitialized) {
      addReceiveSharingChannelForSDK(channelData);
    } else {
      dispatch(addReceiveSharingChannel(channelData));
    }
  };
export const receiveSharingChannelClose =
  (ssrc, isFromMainSession) => (dispatch, getState) => {
    const {
      sharing: { shareeDecodeSdkInitialized },
    } = getState();
    if (shareeDecodeSdkInitialized) {
      removeReceiveSharingChannelForSDK(ssrc, isFromMainSession);
    } else {
      dispatch(removeReceiveSharingChannel(ssrc));
    }
  };

export const initReceivingSharingFlow =
  (isFromMainSession) => (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
      breakoutRoom: { mainSessionAttendeeList },
      sharing: { receiveSharingChannels },
    } = getState();
    // after init sharing decode is success(make sure all below steps should keep the correct sequence):
    // step 1: create new ms websocket channel to receive new sharing from main session in bo
    if (isFromMainSession) {
      dispatch(startToBuildMSChannel());
    }
    // step 2: inform sdk about my roster info and meeting info so sdk can bind that two infos to build a handler
    dispatch(updateSharingDecodeParams(isFromMainSession));
    // step 3: inform sdk about all's roster info to decode other's sharing
    updateUserRosterInfoToSDK({
      addUsers: isFromMainSession ? mainSessionAttendeeList : attendeesList,
      isFromMainSession,
      mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
    });
    // step 4: inform sdk who is staring sharing
    if (receiveSharingChannels.length > 0) {
      receiveSharingChannels.forEach((channelData) => {
        const { ssrc, isFromMainSession: fromMainSession } = channelData;
        // here we should inform sdk separately by isFromMainSession
        if (!!fromMainSession === !!isFromMainSession) {
          addReceiveSharingChannelForSDK(channelData);
          dispatch(removeReceiveSharingChannel(ssrc));
        }
      });
    }
  };

export const initReceivingMainSessionSharingAudioFlow =
  () => (dispatch, getState) => {
    const {
      breakoutRoom: { mainSessionAttendeeList },
    } = getState();
    // after init audio decode is success(make sure all below steps should keep the correct sequence):
    // step 1: create new ma websocket channel to receive audio from main session in bo
    dispatch(startToBuildMAChannel());
    // step 2: inform sdk about my roster info and meeting info so sdk can bind that two infos to build a handler
    dispatch(updateSharingAudioDecodeParams(true));
    // step 3: inform sdk about all's roster info to decode other's sharing
    updateUserRosterInfoToSDK({
      addUsers: mainSessionAttendeeList,
      isFromMainSession: true,
      mediaActionType: sdkIvTypeKeyEnum.AUDIO_DECODE,
    });
  };

export const initEncodeSharingFlow = () => (dispatch) => {
  dispatch(updateSharingEncodeParams());
};

export const stopCaptureVideoForSharingPerformant =
  () => (dispatch, getState) => {
    const {
      video: { isCameraForbidden, isCameraTaken },
      meeting: {
        currentUser: { bVideoOn: isCurrentUserStartedVideo },
      },
    } = getState();
    const isVideoStarted =
      isCurrentUserStartedVideo && !isCameraForbidden && !isCameraTaken;
    dispatch(setVideoIsOnBeforeSharing(isVideoStarted));
    if (isVideoStarted) {
      dispatch(stopCaptureVideo());
      if (!easyStore.easyGet('sharing-performant-notification-shown')) {
        dispatch(setSharingPerformantNotification(true));
      }
    }
    dispatch(setDisableVideoForSharingPerformant(true));
  };

export const restoreVideoStatusBeforeSharing = () => (dispatch, getState) => {
  const {
    sharing: { videoIsOnBeforeSharing },
  } = getState();
  dispatch(setDisableVideoForSharingPerformant(false));
  if (videoIsOnBeforeSharing) {
    dispatch(startVideo());
  }
};

export const startSharer = () => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
  } = getState();
  const message = {
    evt: EVT_TYPE_WS_SHARING_MUTE_SHARE_REQ,
    body: {
      id: userId,
      bOn: false,
      bShareAudio: false,
      bShareVideo: false,
      bShareWb: true,
    },
  };
  dispatch(sendSocketMessage(message));
};

export { doSharer, stopSharer, tryLaunchOrCloseLocalSharer };

// export const requestSharingUrlScheme = () => (dispatch) => {
//   const { width, height, DeviceID } = getShareStreamInfo();
//   const message = {
//     evt: WS_CONF_GET_RC_URL_SCHEME_REQ,
//     body: {
//       DeviceID,
//       width,
//       height,
//       platform: getSharingPlatform(),
//     },
//   };
//   dispatch(sendSocketMessage(message));
// };

export const handleRecvUrlScheme =
  (message, relaunch) => (dispatch, getState) => {
    const { urlScheme, ssrc, downloadUrl } = message.body;
    const {
      dialog: {
        remoteControlLaunch: { lastApprovedUserSsrc },
      },
      sharing: { sharerStatus },
    } = getState();
    remoteControlLogger.log(`${RMC_LOG.RECV_URL_SCHEME}(${!!relaunch})`);
    if (sharerStatus === SHARER_STATUS_ENUM.ED) return;
    /** rwg need to request url scheme from web, this flow is sync, so we need to check if the url-scheme is same as last approved user */
    if (!lastApprovedUserSsrc || lastApprovedUserSsrc === ssrc) {
      misTakeRcAppAsInstalledCheck.stop();
      let curPlatformDownloadUrl = '';
      const isWinPlatform = getSharingPlatform() === 'win';
      try {
        curPlatformDownloadUrl = JSON.parse(downloadUrl || '{}')?.[
          isWinPlatform ? 'winDownloadUrl' : 'macDownloadUrl'
        ];
        // eslint-disable-next-line no-empty
      } catch (e) {}
      dispatch(
        setRemoteControlLaunchDialogVisible({
          visible: true,
          approvedRequestControlUserSsrc: ssrc,
          urlScheme,
          relaunch: !!relaunch,
          downloadUrl: curPlatformDownloadUrl,
        }),
      );
      remoteControlLogger.log(
        `${RMC_LOG.RECV_URL_SCHEME_CONSUMED}(${!!relaunch})`,
      );
    }
  };

/**
 * @description approve or decline remote control request
 * @param approveStatus
 * @enum 0: decline, 1: approve, 2: just clean cache
 */
export const approveOrDeclineRemoteControlRequest =
  (approveStatus, ssrc, isSwitchUser) => (dispatch, getState) => {
    const {
      sharing: { sharerStatus },
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    const isApprove = approveStatus === 1;
    /** when approve other's request, if current share status is paused, need to  resume sharing */
    if (isApprove && sharerStatus === SHARER_STATUS_ENUM.PAUSED) {
      dispatch(resumeSharing());
    }
    if (isSwitchUser && isApprove) {
      dispatch(takeBackRemoteControlPermission());
    }
    const { width, height, DeviceID } = getShareStreamInfo();
    const message = {
      evt: WS_SHARING_REMOTE_CONTROLED_CONSENT,
      body: {
        DeviceID,
        width,
        height,
        op: approveStatus,
        SendUserID: userId,
        AssignUserID: ssrc,
        platform: getSharingPlatform(),
        // PM said: PWA currently don't need autoDelete rc app
        // bAutoDelete: true,
      },
    };
    dispatch(sendSocketMessage(message));
    remoteControlLogger.log(
      `${RMC_LOG.RMC_DEVICE_INFO}(${JSON.stringify({
        DeviceID,
        width,
        height,
      })})`,
    );
    if (isApprove) {
      /** rwg need to request url scheme from web, this flow is sync, store the last approved user ssrc for later check */
      dispatch(
        setRemoteControlLaunchDialogVisible({
          lastApprovedUserSsrc: ssrc,
        }),
      );
    }
    remoteControlLogger.log(
      `${
        RMC_LOG.APPROVE_OR_DECLINE_RMC_REQUEST
      }(${approveStatus}:${!!isSwitchUser})`,
    );
  };

/**
 * @description tell rwg to take back remote control permission
 */
export const takeBackRemoteControlPermission = () => (dispatch, getState) => {
  const {
    sharing: { remoteControllingUserSsrc },
    meeting: {
      currentUser: { userId },
    },
  } = getState();
  misTakeRcAppAsInstalledCheck.stop();
  dispatch(remoteControlApproveOvertimeCheck.stop());
  dispatch(
    setRemoteControlLaunchDialogVisible({
      visible: false,
      approvedRequestControlUserSsrc: 0,
      urlScheme: '',
      relaunch: false,
      downloadUrl: '',
    }),
  );
  if (remoteControllingUserSsrc) {
    const message = {
      evt: WS_SHARING_REMOTE_CONTROLED_TAKE_RIGHT_BACK_REQ,
      body: {
        SendUserID: userId,
        ReceiverUserID: remoteControllingUserSsrc,
      },
    };
    dispatch(sendSocketMessage(message));
    stopCheckQrCode();
    remoteControlLogger.log(RMC_LOG.TAKE_BACK_RMC);
  }
};

export const takeBackRemoteControlPermissionSuccess = () => {
  return (dispatch) => {
    dispatch(setRemoteControllingUserSsrc(0));
    stopCheckQrCode();
    remoteControlLogger.log(RMC_LOG.TAKE_BACK_RMC_SUCCESS);
  };
};

export const handleRcAppClose = () => (dispatch) => {
  dispatch(toggleRemoteControlEndedByRcappTip(true));
  dispatch(takeBackRemoteControlPermission());
  remoteControlLogger.log(RMC_LOG.RC_CLOSED);
};

let loadWebClientTask = null;
function loadQrScanner() {
  if (!isSupportZoomRemoteControl()) {
    return Promise.resolve(true);
  }
  if (window.WebQrscanner) {
    return Promise.resolve(true);
  }
  if (loadWebClientTask) return loadWebClientTask;
  remoteControlLogger.log(RMC_LOG.LOAD_QR_JS_START);
  loadWebClientTask = LoadJs.start(meetingConfig)
    .then((v) => {
      return v.add(getMediasdkBaseUrl(), 'qrscanner.min.js');
    })
    .finally(() => {
      remoteControlLogger.log(RMC_LOG.LOAD_QR_JS_DONE);
      loadWebClientTask = null;
    });
  return loadWebClientTask;
}

/**
 * @description media-sdk have set 15s timeout to stop qr-code scanner,
 * Also when end sharing stream, media-sdk will auto stop qr-code scanner.
 */
function startCheckQrCode() {
  if (!isSupportZoomRemoteControl()) return;
  if (!window.WebQrscanner) {
    (loadWebClientTask || loadQrScanner()).then(() => {
      remoteControlLogger.log(RMC_LOG.QR_SCAN_START);
      globalVariable.avSocket.sendSocket(
        AVNotifyMediaSDKTypes.START_STOP_REMOTE_CONTROL_CHECK,
        {
          enable: true,
        },
      );
    });
  } else {
    remoteControlLogger.log(RMC_LOG.QR_SCAN_START);
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.START_STOP_REMOTE_CONTROL_CHECK,
      {
        enable: true,
      },
    );
  }
}

function stopCheckQrCode() {
  if (!isSupportZoomRemoteControl()) return;
  remoteControlLogger.log(RMC_LOG.QR_SCAN_STOP);
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.START_STOP_REMOTE_CONTROL_CHECK,
    {
      enable: false,
    },
  );
}

/**
 * @description send qrcode resut to rwg to auth
 */
export function sendQrcodeAuthRequest(qrCodeStrList) {
  return (dispatch, getState) => {
    const {
      sharing: { sharerStatus },
    } = getState();
    if (sharerStatus !== SHARER_STATUS_ENUM.ED && qrCodeStrList) {
      qrCodeStrList.forEach((qrCodeStr) => {
        if (qrCodeStr && qrCodeStr.startsWith('zrc_')) {
          const message = {
            evt: WS_SHARING_REMOTE_CONTROLED_AUTH_REQ,
            body: {
              auth: qrCodeStr,
            },
          };
          dispatch(sendSocketMessage(message));
          remoteControlLogger.log(RMC_LOG.QR_CODE_REQUEST);
        }
      });
    }
  };
}

/**
 * @description handle the qr code auth check callback from rwg
 */
export function handleQrCodeResponse(message) {
  return () => {
    const { result } = message.body;
    const success = result === 0;
    if (success) {
      stopCheckQrCode();
    }
    remoteControlLogger.log(`${RMC_LOG.QR_CODE_RESPONSE}(${success})`);
  };
}

export const handleRcAppConnected = () => (dispatch, getState) => {
  const {
    sharing: { sharerStatus },
  } = getState();
  misTakeRcAppAsInstalledCheck.stop();
  dispatch(remoteControlApproveOvertimeCheck.stop());
  dispatch(
    setRemoteControlLaunchDialogVisible({
      visible: false,
      approvedRequestControlUserSsrc: 0,
      urlScheme: '',
      relaunch: false,
      downloadUrl: '',
    }),
  );
  if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
    startCheckQrCode();
  }
  remoteControlLogger.log(RMC_LOG.RC_OPENED);
};

/**
 * @description due to web browser capture sharing stream size not equal to screen size if dpi is not 100%,
 * need send stream size to rcapp to fix it
 */
export const sendShareStreamInfoToRcApp = () => (dispatch) => {
  const { width, height, DeviceID } = getShareStreamInfo();
  const data = {
    DeviceID,
    width,
    height,
  };
  const message = {
    evt: WS_SHARING_REMOTE_SYNC_SCREEN_INFO_TO_RCAPP_REQ,
    body: data,
  };
  dispatch(sendSocketMessage(message));
  remoteControlLogger.log(
    `${RMC_LOG.RMC_DEVICE_INFO}(${JSON.stringify(data)})`,
  );
};

export const resumeSharing = () => (dispatch, getState) => {
  const {
    sharing: { canShareAudioDuringThisSharing, isSharingAudio },
  } = getState();
  const remoteMsgObj = {
    evt: EVT_TYPE_WS_SHARING_RESUME_REQ,
    body: {},
  };
  dispatch(sendSocketMessage(remoteMsgObj));

  globalVariable.avSocket.sendSocket(RESUME_DESKTOP_SHARING, {});

  dispatch(setSharerStatus(SHARER_STATUS_ENUM.ING));
  // isSharingAudio indicates pause sharing button status,
  // it does not indicate sdk capture audio.
  if (isSharingAudio && canShareAudioDuringThisSharing) {
    dispatch(localSwitchDesktopOrComputerAudioCapture(false));
  }
  promptA11yInfo(SHARE_RESUME_TIP);
  remoteControlLogger.log(RMC_LOG.RESUME_SHARING);
};

export const pauseSharing = () => (dispatch, getState) => {
  const {
    sharing: { canShareAudioDuringThisSharing, isSharingAudio },
  } = getState();
  const remoteMsgObj = {
    evt: EVT_TYPE_WS_SHARING_PAUSE_REQ,
    body: {},
  };
  dispatch(sendSocketMessage(remoteMsgObj));

  globalVariable.avSocket.sendSocket(PAUSE_DESKTOP_SHARING, {});

  dispatch(setSharerStatus(SHARER_STATUS_ENUM.PAUSED));
  if (isSharingAudio && canShareAudioDuringThisSharing) {
    dispatch(localSwitchDesktopOrComputerAudioCapture(true));
  }
  promptA11yInfo(SHARE_PAUSED_TIP);
  remoteControlLogger.log(RMC_LOG.PAUSE_SHARING);
};

/**
 * @description share to main sesson only or to bo also
 */
export const shareToBoOrMainSessionOnly =
  (toBo, checkNewShare) => (dispatch, getState) => {
    const {
      sharing: { remoteControllingUserSsrc },
    } = getState();
    /** when being remote controling, need show new share double confirm dialog */
    if (checkNewShare && remoteControllingUserSsrc) {
      dispatch(
        setRemoteControlNewScreenDialogVisible({
          visible: true,
          shareToBo: !!toBo,
        }),
      );
    } else {
      if (toBo) {
        dispatch(setWillShareToBo(true));
        if (hasShareToBOConfirmDialogAlert()) {
          dispatch(toggleShare());
        } else {
          dispatch(setShareToBoConfirmDialogVisible(true));
        }
      } else {
        dispatch(setWillShareToBo(false));
        dispatch(toggleShare());
      }
    }
  };

/**
 * @description approve remote control success
 */
function approveRemoteControlSuccess(approvedUserId) {
  return (dispatch, getState) => {
    const {
      sharing: { sharerStatus },
    } = getState();
    remoteControlLogger.log(RMC_LOG.APPROVE_RMC_SUCCESS);

    misTakeRcAppAsInstalledCheck.stop();
    dispatch(remoteControlApproveOvertimeCheck.stop());
    /** if sharing has been stoped, then recv a approve, need to tack back permission */
    if (sharerStatus === SHARER_STATUS_ENUM.ED) {
      dispatch(takeBackRemoteControlPermission());
      return;
    }
    /** when approve other's request finnaly success, if current share status is paused, need to  resume sharing */
    if (sharerStatus === SHARER_STATUS_ENUM.PAUSED) {
      dispatch(resumeSharing());
    }
    dispatch(setRemoteControllingUserSsrc(approvedUserId));
    dispatch(
      setRemoteControlLaunchDialogVisible({
        visible: false,
        approvedRequestControlUserSsrc: 0,
        urlScheme: '',
        relaunch: false,
        downloadUrl: '',
      }),
    );
  };
}

/**
 * @description handle receive remote control from others
 */
export function handleRecvRemoteControlRequest(
  requestUserId,
  isTimeoutRequest,
) {
  return (dispatch, getState) => {
    const {
      sharing: { sharerStatus },
    } = getState();
    remoteControlLogger.log(
      `${RMC_LOG.RECV_RMC_REQEST}(${!!isTimeoutRequest})`,
    );
    if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
      dispatch(
        setRemoteControlApproveDialogVisible({
          requestControlUserSsrc: requestUserId,
          visible: true,
          rememberLastRequestUserSsrc: null,
          isTimeoutRequest: !!isTimeoutRequest,
        }),
      );
    }
  };
}

/**
 * @description if our code detect rcapp installed, but actually it is not installed.
 * Then we need to give re-show lauch dialog.
 */
export const misTakeRcAppAsInstalledCheck = (() => {
  let misTakeRcAppAsInstalledCheckTimer = null;
  return {
    stop: () => {
      if (misTakeRcAppAsInstalledCheckTimer) {
        clearTimeout(misTakeRcAppAsInstalledCheckTimer);
        misTakeRcAppAsInstalledCheckTimer = null;
      }
    },
    start: () => {
      return (dispatch, getState) => {
        misTakeRcAppAsInstalledCheck.stop();
        const {
          dialog: {
            remoteControlLaunch: {
              approvedRequestControlUserSsrc,
              urlScheme,
              downloadUrl,
            },
          },
        } = getState();
        misTakeRcAppAsInstalledCheckTimer = setTimeout(() => {
          const {
            dialog: {
              remoteControlLaunch: { visible },
            },
          } = getState();
          if (!visible && urlScheme) {
            const isWinPlatform = getSharingPlatform() === 'win';
            const message = {
              body: {
                urlScheme,
                ssrc: approvedRequestControlUserSsrc,
                downloadUrl: JSON.stringify({
                  [isWinPlatform ? 'winDownloadUrl' : 'macDownloadUrl']:
                    downloadUrl,
                }),
              },
            };
            dispatch(handleRecvUrlScheme(message, true));
          }
          misTakeRcAppAsInstalledCheck.stop();
        }, 10 * 1000);
      };
    },
  };
})();

/**
 * @description if remote control approve has no success response overtime, take back remote control permission.
 */
export const remoteControlApproveOvertimeCheck = (() => {
  let remoteControlApproveOvertimeCheckTimer = null;
  const TIME_DURATION = 60 * 1000;
  return {
    stop: () => {
      return (dispatch) => {
        if (remoteControlApproveOvertimeCheckTimer) {
          clearTimeout(remoteControlApproveOvertimeCheckTimer);
          remoteControlApproveOvertimeCheckTimer = null;
          dispatch(setJoinRemoteControlTimer(null));
        }
      };
    },
    start: () => {
      return (dispatch, getState) => {
        dispatch(remoteControlApproveOvertimeCheck.stop());
        remoteControlApproveOvertimeCheckTimer = setTimeout(() => {
          const {
            dialog: {
              remoteControlLaunch: {
                lastApprovedUserSsrc,
                visible,
                approvedRequestControlUserSsrc,
              },
            },
          } = getState();
          dispatch(remoteControlApproveOvertimeCheck.stop());
          if (lastApprovedUserSsrc) {
            dispatch(
              handleRecvRemoteControlRequest(lastApprovedUserSsrc, true),
            );
            dispatch(
              approveOrDeclineRemoteControlRequest(2, lastApprovedUserSsrc),
            );
          }
          if (
            visible &&
            approvedRequestControlUserSsrc === lastApprovedUserSsrc
          ) {
            dispatch(
              setRemoteControlLaunchDialogVisible({
                visible: false,
                approvedRequestControlUserSsrc: 0,
                urlScheme: '',
                relaunch: false,
                downloadUrl: '',
              }),
            );
          }
          remoteControlLogger.log(RMC_LOG.APPROVE_RMC_TIMEOUT);
        }, TIME_DURATION);
        dispatch(
          setJoinRemoteControlTimer(remoteControlApproveOvertimeCheckTimer),
        );
      };
    },
  };
})();

const requestControlWaitingApproveToastName =
  'RemoteControlWaitingApproveToast';
const requestControlClickableToastName = 'RemoteControlClickableTipToast';
const remoteControlEndedByRcappToastName = 'RemoteControlEndedByRcappToast';
export function toggleRequestControlClickableTip({ visible, shareUserName }) {
  return (dispatch) => {
    if (visible) {
      dispatch(toggleRequestControlWaitingApproveTip(false));
      AliveToast.uniqueToast({
        message: requestControlClickableTip(shareUserName),
        name: requestControlClickableToastName,
        usePrevious: true,
        aliveTime: 5 * 1000,
      });
    } else {
      AliveToast.close(requestControlClickableToastName);
    }
  };
}

let waitingTimer = null;
const waitingTimerDuration = 30 * 1000;
export function toggleRequestControlWaitingApproveTip(visible) {
  return (dispatch, getState) => {
    const shareUser = shareUserSelector(getState());
    clearTimeout(waitingTimer);
    waitingTimer = null;
    if (visible && shareUser?.isPwa) {
      dispatch(toggleRemoteControlEndedByRcappTip(false));
      AliveToast.uniqueToast({
        message: requestControlWaitingApproveTip(shareUser.shareUserName),
        name: requestControlWaitingApproveToastName,
        aliveTime: -1,
        usePrevious: true,
        afterComponent: (
          <span
            style={{
              marginLeft: '8px',
              animation: '1.5s linear 0s normal none infinite rotate',
              WebkitAnimation: '1.5s linear 0s normal none infinite rotate',
            }}
          >
            <LoadingCircleSVG />
          </span>
        ),
      });
      waitingTimer = setTimeout(() => {
        dispatch(toggleRequestControlWaitingApproveTip(false));
      }, waitingTimerDuration);
    } else {
      AliveToast.close(requestControlWaitingApproveToastName);
      clearTimeout(waitingTimer);
      waitingTimer = null;
    }
  };
}

export function toggleRemoteControlEndedByRcappTip(visible) {
  return (dispatch) => {
    if (visible) {
      dispatch(toggleRequestControlClickableTip(false));
      dispatch(toggleRequestControlWaitingApproveTip(false));
      AliveToast.uniqueToast({
        message: remoteControlEndedByRcappTip,
        name: remoteControlEndedByRcappToastName,
      });
    } else {
      AliveToast.close(remoteControlEndedByRcappToastName);
    }
  };
}

export function restoreShareAudioStatus(updateList = []) {
  return (dispatch) => {
    const stopShareUser = updateList.filter((user) => {
      return (
        (typeof user.bShareOn === 'boolean' && !user.bShareOn) ||
        (typeof user.bShareToBORooms === 'boolean' &&
          user.bShareOn &&
          !user.bShareToBORooms)
      );
    });
    if (stopShareUser.length > 0) {
      stopShareUser.forEach((user) => {
        dispatch(localMuteOrUnmuteReceivedSharedAudio(false, user.id));
      });
    }
  };
}
