import { createActions } from 'redux-actions';

const SET_VIDEO_DECODE_LOADING = 'SET_VIDEO_DECODE_LOADING';

const SET_ACTIVE_VIDEO = 'SET_ACTIVE_VIDEO';
const SET_TALKING_TIP_VISIBLE = 'SET_TALKING_TIP_VISIBLE';
const SET_VIDEO_QUALITY = 'SET_VIDEO_QUALITY';
const SET_CLIENT_DISABLE_VIDEO = 'SET_CLIENT_DISABLE_VIDEO';
const SET_CAMERA_DEVICES_LIST = 'SET_CAMERA_DEVICES_LIST';
const SET_CAMERA_CAPTURE_LOADING = 'SET_CAMERA_CAPTURE_LOADING';
const SET_ACTIVE_CAMERA_DEVICE = 'SET_ACTIVE_CAMERA_DEVICE';
const SET_CAMERA_IS_TAKEN = 'SET_CAMERA_IS_TAKEN';
const SET_CAMERA_IS_FORBIDDEN = 'SET_CAMERA_IS_FORBIDDEN';
const SET_VIDEO_MENU_VISIBLE = 'SET_VIDEO_MENU_VISIBLE';
const SET_SIMULIVE_AUTOREPLY_ENABLE = 'SET_SIMULIVE_AUTOREPLY_ENABLE';
const SET_SIMULIVE_LOADING = 'SET_SIMULIVE_LOADING';

const SET_SELF_VIDEO_SHOW_TYPE = 'SET_SELF_VIDEO_SHOW_TYPE';
const SET_VIDEO_LAYOUT = 'SET_VIDEO_LAYOUT';
const VIDEO_RESET = 'VIDEO_RESET';
const SET_HAS_INFORMED_RWG_DEVICE_VIDEO_CAP =
  'SET_HAS_INFORMED_RWG_DEVICE_VIDEO_CAP';
const SET_SHOW_SELF_VIEW = 'SET_SHOW_SELF_VIEW';
const SET_SHOW_NO_VIDEO_PARTICIPANTS = 'SET_SHOW_NO_VIDEO_PARTICIPANTS';
const SET_PIN_VIDEO_LIST = 'SET_PIN_VIDEO_LIST';
const CLEAR_PIN_VIDEO_LIST = 'CLEAR_PIN_VIDEO_LIST';
const SET_SPOTLIGHT_VIDEO_LIST = 'SET_SPOTLIGHT_VIDEO_LIST';
const CLEAR_SPOTLIGHT_VIDEO_LIST = 'CLEAR_SPOTLIGHT_VIDEO_LIST';
const SET_HAS_CHANGED_VIDEO_ORDER = 'SET_HAS_CHANGED_VIDEO_ORDER';
const SET_HAS_SHOW_CANNOT_DRAG_DIALOG = 'SET_HAS_SHOW_CANNOT_DRAG_DIALOG';
const SET_CONTROLLER_DISABLE_VIDEO = 'SET_CONTROLLER_DISABLE_VIDEO';

const SET_IS_CHANGE_VIDEO = 'SET_IS_CHANGE_VIDEO';

const SET_CURRENT_VIDEO_STATUS = 'SET_CURRENT_VIDEO_STATUS';

const SET_CURRENT_SPEAKER_BAR_VIDEO = 'SET_CURRENT_SPEAKER_BAR_VIDEO';
const SET_CURRENT_SPEAKER_ACTIVE_VIDEO = 'SET_CURRENT_SPEAKER_ACTIVE_VIDEO';
const SET_CURRENT_GALLERY_MAIN_VIDEO = 'SET_CURRENT_GALLERY_MAIN_VIDEO';
const SET_CURRENT_SUSPENSION_ALL_VIDEO = 'SET_CURRENT_SUSPENSION_ALL_VIDEO';
const SET_CURRENT_TESLA_SUSPENSION_ALL_VIDEO =
  'SET_CURRENT_TESLA_SUSPENSION_ALL_VIDEO';
const SET_INTERPRETER_VIDEO = 'SET_INTERPRETER_VIDEO';

const SET_CURRENT_TESLA_BAR_VIDEO = 'SET_CURRENT_TESLA_BAR_VIDEO';
const SET_CURRENT_TESLA_ACTIVE_VIDEO = 'SET_CURRENT_TESLA_ACTIVE_VIDEO';
const SET_TESLA_BAR_PAGE_POINTER = 'SET_TESLA_BAR_PAGE_POINTER';

const SET_SPEAKER_BAR_PAGE_POINTER = 'SET_SPEAKER_BAR_PAGE_POINTER';
const SET_SPEAKER_ACTIVE_PAGE_POINTER = 'SET_SPEAKER_ACTIVE_PAGE_POINTER';
const SET_GALLERY_MAIN_PAGE_POINTER = 'SET_GALLERY_MAIN_PAGE_POINTER';
const SET_SUSPENSION_ALL_PAGE_POINTER = 'SET_SUSPENSION_ALL_PAGE_POINTER';

const SET_CURRENT_RENDER_VIDEO = 'SET_CURRENT_RENDER_VIDEO';

const SET_ATTENDEE_LAYOUT = 'SET_ATTENDEE_LAYOUT';
const SET_ATTENDEE_VIDEO_CONTROL_MODE = 'SET_ATTENDEE_VIDEO_CONTROL_MODE';
const SET_FIRST_ACTIVE_VIDEO_COORD = 'SET_FIRST_ACTIVE_VIDEO_COORD';

const SET_IS_SWAP_VIDEO_SHARED = 'SET_IS_SWAP_VIDEO_SHARED';

const SET_ATTENDEE_VIDEO_LAYOUT_FLAG = 'SET_ATTENDEE_VIDEO_LAYOUT_FLAG';
const SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_RECEIVING_VIDEO =
  'SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_RECEIVING_VIDEO';
const SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_SENDING_VIDEO =
  'SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_SENDING_VIDEO';
const SET_CLIENT_ENABLE_SEE_SELF_ACTIVE_WHEN_SPEAKING =
  'SET_CLIENT_ENABLE_SEE_SELF_ACTIVE_WHEN_SPEAKING';
const SET_CLIENT_ENABLE_TURN_OFF_VIDEO_WHEN_JOIN_MEETING =
  'SET_CLIENT_ENABLE_TURN_OFF_VIDEO_WHEN_JOIN_MEETING';

const SET_VIDEO_REAL_SIZE = 'SET_VIDEO_REAL_SIZE';

const SET_IS_FIRST_OPEN_VIDEO_IN_MEETING = 'SET_IS_FIRST_OPEN_VIDEO_IN_MEETING';

const SET_IS_LOADING_BACKGROUND_FROM_START_VIDEO =
  'SET_IS_LOADING_BACKGROUND_FROM_START_VIDEO';

const SET_IS_MIRROR_MY_VIDEO = 'SET_IS_MIRROR_MY_VIDEO';
const SET_IS_NEED_NOTIFY_START_VIDEO_FAIL =
  'SET_IS_NEED_NOTIFY_START_VIDEO_FAIL';
const SET_IS_CLIENT_PAGE_VISIBLE = 'SET_IS_CLIENT_PAGE_VISIBLE';
const SET_CUSTOM_GALLERY_VIEW_ORDER = 'SET_CUSTOM_GALLERY_VIEW_ORDER';
const SET_IS_VIEW_ORDER_SAVED = 'SET_IS_VIEW_ORDER_SAVED';
const SET_IS_UPLOADING_VIEW_ORDER = 'SET_IS_UPLOADING_VIEW_ORDER';
const SET_IS_DOWNLOADING_VIEW_ORDER = 'SET_IS_DOWNLOADING_VIEW_ORDER';
const SET_MMR_SAVE_ORDER_FLAG = 'SET_MMR_SAVE_ORDER_FLAG';

const SET_ACTIVE_VIDEO_TALKING_ID = 'SET_ACTIVE_VIDEO_TALKING_ID';
const SET_CURRENT_VIDEO_RENDER_TAG = 'SET_CURRENT_VIDEO_RENDER_TAG';

const SET_DISABLE_VIDEO_FOR_SHARING_PERFORMANT =
  'SET_DISABLE_VIDEO_FOR_SHARING_PERFORMANT';
const SET_IS_TESLA_PARKED = 'SET_IS_TESLA_PARKED';

const SET_IS_WATERMARK_TURN_ON = 'SET_IS_WATERMARK_TURN_ON';
const SET_BRANDING = 'SET_BRANDING';
const SET_BRANDING_BACKGROUND = 'SET_BRANDING_BACKGROUND';
const SET_BRANDING_LAYOUT = 'SET_BRANDING_LAYOUT';
const SET_VIDEO_ACTION_IN_PROGRESS = 'SET_VIDEO_ACTION_IN_PROGRESS';
const SET_NAME_TAG_TEMPLATES = 'SET_NAME_TAG_TEMPLATES';
const SET_BRANDING_WINDOW_VISIBLE = 'SET_BRANDING_WINDOW_VISIBLE';
const SET_CURRENT_APPEARANCE = 'SET_CURRENT_APPEARANCE';

const SET_SHOULD_RECOVER_VIDEO_ON = 'SET_SHOULD_RECOVER_VIDEO_ON';

const SET_IS_LOADING_BACKGROUND_FROM_SETTINGS =
  'SET_IS_LOADING_BACKGROUND_FROM_SETTINGS';

const SET_CURRENT_MINIMIZE_VIDEO = 'SET_CURRENT_MINIMIZE_VIDEO';
const SET_CURRENT_MINIMIZE_RENDER_VIDEO = 'SET_CURRENT_MINIMIZE_RENDER_VIDEO';

const SET_IS_VIDEO_RENDER_TRANSFORM = 'SET_IS_VIDEO_RENDER_TRANSFORM';

const SET_IS_OPEN_VIDEO_25_SIZE = 'SET_IS_OPEN_VIDEO_25_SIZE';

const SET_IS_CHANGE_SIDE_BY_SIDE_SPEAKER_LAYOUT_BY_USER =
  'SET_IS_CHANGE_SIDE_BY_SIDE_SPEAKER_LAYOUT_BY_USER';
const SET_SHOULD_REBACK_PREVIOUS_VIEW = 'SET_SHOULD_REBACK_PREVIOUS_VIEW';

const SET_WAITING_FOR_VIDEO_FRAME_ACTIVE_USER =
  'SET_WAITING_FOR_VIDEO_FRAME_ACTIVE_USER';

const SET_SUBSCRIBED_VIDEO_MAX_QUALITY = 'SET_SUBSCRIBED_VIDEO_MAX_QUALITY';
const SET_NETWORK_QUALITY_MAP = 'SET_NETWORK_QUALITY_MAP';

const SET_GALLERY_ORDER_TYPE = 'SET_GALLERY_ORDER_TYPE';
const SET_IS_GALLERY_ORDER_MODE = 'SET_IS_GALLERY_ORDER_MODE';
const SET_IS_WEBINAR_ONLY_SHOW_CONTENT = 'SET_IS_WEBINAR_ONLY_SHOW_CONTENT';

const SET_IS_SHOW_ZR_MAIN_USER = 'SET_IS_SHOW_ZR_MAIN_USER';
const SET_SIMU_LIVE_PLAYERS_UNIQUE_INDEX = 'SET_SIMU_LIVE_PLAYERS_UNIQUE_INDEX';

const SET_CAMERA_CONTROLLEE_GROUP = 'SET_CAMERA_CONTROLLEE_GROUP';
const SET_CAMERA_CONTROLLER_USER_ID = 'SET_CAMERA_CONTROLLER_USER_ID';
const SET_VIDEO_CAMERA_PTZ_CAPABILITIES = 'SET_VIDEO_CAMERA_PTZ_CAPABILITIES';
const SET_PTZ = 'SET_PTZ';
const SET_START_CAMERA_CONTROL_GROUP = 'SET_START_CAMERA_CONTROL_GROUP';
const SET_IS_SHOW_CAMERA_CONTROL_NOTIFICATION =
  'SET_IS_SHOW_CAMERA_CONTROL_NOTIFICATION';
const SET_IS_ENABLE_AUTO_ACCEPT_CAMERA_CONTROL =
  'SET_IS_ENABLE_AUTO_ACCEPT_CAMERA_CONTROL';
const SET_IS_SUBSCRIBE_ACTIVE_CHANNEL = 'SET_IS_SUBSCRIBE_ACTIVE_CHANNEL';
const SET_ACTIVE_CHANNEL_QUALITY = 'SET_ACTIVE_CHANNEL_QUALITY';
const SET_VIDEO_RENDERER_TYPE = 'SET_VIDEO_RENDERER_TYPE';
const SET_VIDEO_RENDERER_LIST = 'SET_VIDEO_RENDERER_LIST';
const SET_MULTI_SPEAKER_SCALE = 'SET_MULTI_SPEAKER_SCALE';
const SET_CURRENT_MULTI_SPEAKER_ACTIVE_VIDEO =
  'SET_CURRENT_MULTI_SPEAKER_ACTIVE_VIDEO';
const SET_CURRENT_MULTI_SPEAKER_MAIN_VIDEO =
  'SET_CURRENT_MULTI_SPEAKER_MAIN_VIDEO';
const SET_MULTI_SPEAKER_ACTIVE_PAGE_POINTER =
  'SET_MULTI_SPEAKER_ACTIVE_PAGE_POINTER';
const SET_MULTI_SPEAKER_MAIN_PAGE_POINTER =
  'SET_MULTI_SPEAKER_MAIN_PAGE_POINTER';
const SET_MULTI_SPEAKER_ACTIVE_LIST = 'SET_MULTI_SPEAKER_ACTIVE_LIST';
const SET_IS_ENABLE_MULTI_SPEAKER_VIEW = 'SET_IS_ENABLE_MULTI_SPEAKER_VIEW';
const SET_ACTIVE_SPEAKER_LIST = 'SET_ACTIVE_SPEAKER_LIST';
const SET_MULTI_SPEAKER_CONFIG = 'SET_MULTI_SPEAKER_CONFIG';
const SET_USE_WEB_RTC_STRATEGY = 'SET_USE_WEB_RTC_STRATEGY';
const SET_VIDEO_MUTED_BY_SYSTEM = 'SET_VIDEO_MUTED_BY_SYSTEM';

export const {
  setVideoDecodeLoading,
  setActiveVideo,
  setTalkingTipVisible,
  setVideoQuality,
  setClientDisableVideo,
  setClientEnableHardwareAccelerationForReceivingVideo,
  setClientEnableHardwareAccelerationForSendingVideo,
  setClientEnableSeeSelfActiveWhenSpeaking,
  setClientEnableTurnOffVideoWhenJoinMeeting,
  setCameraDevicesList,
  setCameraCaptureLoading,
  setActiveCameraDevice,
  setCameraIsTaken,
  setCameraIsForbidden,
  setVideoMenuVisible,
  setSimuliveAutoreplyEnable,
  setSimuliveLoading,
  setSelfVideoShowType,
  setHasInformedRwgDeviceVideoCap,
  setVideoLayout,
  videoReset,
  setShowSelfView,
  setShowNoVideoParticipants,
  setPinVideoList,
  clearPinVideoList,
  setSpotlightVideoList,
  clearSpotlightVideoList,
  setHasChangedVideoOrder,
  setHasShowCannotDragDialog,
  setControllerDisableVideo,
  setIsChangeVideo,
  setCurrentVideoStatus,

  setCurrentSpeakerBarVideo,
  setCurrentSpeakerActiveVideo,
  setCurrentGalleryMainVideo,
  setCurrentSuspensionAllVideo,
  setCurrentTeslaSuspensionAllVideo,
  setInterpreterVideo,

  setCurrentTeslaBarVideo,
  setCurrentTeslaActiveVideo,
  setTeslaBarPagePointer,

  setSpeakerBarPagePointer,
  setSpeakerActivePagePointer,
  setGalleryMainPagePointer,
  setSuspensionAllPagePointer,

  setCurrentRenderVideo,

  setAttendeeLayout,
  setAttendeeVideoControlMode,
  setFirstActiveVideoCoord,
  setIsSwapVideoShared,
  setAttendeeVideoLayoutFlag,
  setVideoRealSize,
  setIsFirstOpenVideoInMeeting,
  setIsLoadingBackgroundFromStartVideo,
  setIsMirrorMyVideo,
  setIsNeedNotifyStartVideoFail,
  setIsClientPageVisible,
  setCustomGalleryViewOrder,
  setIsViewOrderSaved,
  setIsUploadingViewOrder,
  setIsDownloadingViewOrder,
  setMmrSaveOrderFlag,

  setActiveVideoTalkingId,
  setCurrentVideoRenderTag,
  setDisableVideoForSharingPerformant,
  setIsTeslaParked,
  setIsWatermarkTurnOn,
  setBranding,
  setBrandingBackground,
  setBrandingLayout,
  setVideoActionInProgress,
  setShouldRecoverVideoOn,
  setNameTagTemplates,
  setBrandingWindowVisible,
  setCurrentAppearance,
  setIsLoadingBackgroundFromSettings,

  setCurrentMinimizeVideo,
  setCurrentMinimizeRenderVideo,
  setIsVideoRenderTransform,
  setIsOpenVideo25Size,
  setIsChangeSideBySideSpeakerLayoutByUser,
  setShouldRebackPreviousView,
  setWaitingForVideoFrameActiveUser,
  setSubscribedVideoMaxQuality,
  setNetworkQualityMap,
  setGalleryOrderType,
  setIsGalleryOrderMode,
  setIsWebinarOnlyShowContent,
  setIsShowZrMainUser,
  setSimuLivePlayersUniqueIndex,
  setCameraControlleeGroup,
  setCameraControllerUserId,
  setVideoCameraPtzCapabilities,
  setPtz,
  setStartCameraControlGroup,
  setIsShowCameraControlNotification,
  setIsEnableAutoAcceptCameraControl,
  setIsSubscribeActiveChannel,
  setActiveChannelQuality,
  setVideoRendererType,
  setVideoRendererList,
  setMultiSpeakerScale,
  setCurrentMultiSpeakerActiveVideo,
  setCurrentMultiSpeakerMainVideo,
  setMultiSpeakerActivePagePointer,
  setMultiSpeakerMainPagePointer,
  setMultiSpeakerActiveList,
  setIsEnableMultiSpeakerView,
  setActiveSpeakerList,
  setMultiSpeakerConfig,
  setUseWebRtcStrategy,
  setVideoMutedBySystem,
} = createActions({
  [SET_VIDEO_DECODE_LOADING]: (payload) => payload,
  [SET_ACTIVE_VIDEO]: (payload) => payload,
  [SET_TALKING_TIP_VISIBLE]: (payload) => payload,
  [SET_VIDEO_QUALITY]: (payload) => payload,
  [SET_CLIENT_DISABLE_VIDEO]: (payload) => payload,
  [SET_CAMERA_DEVICES_LIST]: (payload) => payload,
  [SET_CAMERA_CAPTURE_LOADING]: (payload) => payload,
  [SET_ACTIVE_CAMERA_DEVICE]: (payload) => payload,
  [SET_CAMERA_IS_TAKEN]: (payload) => payload,
  [SET_CAMERA_IS_FORBIDDEN]: (payload) => payload,
  [SET_VIDEO_MENU_VISIBLE]: (payload) => payload,
  [SET_SIMULIVE_AUTOREPLY_ENABLE]: (payload) => payload,
  [SET_SIMULIVE_LOADING]: (payload) => payload,
  [SET_SELF_VIDEO_SHOW_TYPE]: (payload) => payload,
  [SET_HAS_INFORMED_RWG_DEVICE_VIDEO_CAP]: (payload) => payload,
  [SET_VIDEO_LAYOUT]: (payload) => payload,
  [VIDEO_RESET]: (payload) => payload,
  [SET_SHOW_SELF_VIEW]: (payload) => payload,
  [SET_SHOW_NO_VIDEO_PARTICIPANTS]: (payload) => payload,
  [SET_PIN_VIDEO_LIST]: (payload) => payload,
  [CLEAR_PIN_VIDEO_LIST]: (payload) => payload,
  [SET_SPOTLIGHT_VIDEO_LIST]: (payload) => payload,
  [CLEAR_SPOTLIGHT_VIDEO_LIST]: (payload) => payload,
  [SET_HAS_CHANGED_VIDEO_ORDER]: (payload) => payload,
  [SET_HAS_SHOW_CANNOT_DRAG_DIALOG]: (payload) => payload,
  [SET_CONTROLLER_DISABLE_VIDEO]: (payload) => payload,

  [SET_IS_CHANGE_VIDEO]: (payload) => payload,
  [SET_CURRENT_VIDEO_STATUS]: (payload) => payload,

  [SET_CURRENT_SPEAKER_BAR_VIDEO]: (payload) => payload,
  [SET_CURRENT_SPEAKER_ACTIVE_VIDEO]: (payload) => payload,
  [SET_CURRENT_GALLERY_MAIN_VIDEO]: (payload) => payload,
  [SET_CURRENT_SUSPENSION_ALL_VIDEO]: (payload) => payload,
  [SET_CURRENT_TESLA_SUSPENSION_ALL_VIDEO]: (payload) => payload,
  [SET_INTERPRETER_VIDEO]: (payload) => payload,

  [SET_CURRENT_TESLA_BAR_VIDEO]: (payload) => payload,
  [SET_CURRENT_TESLA_ACTIVE_VIDEO]: (payload) => payload,
  [SET_TESLA_BAR_PAGE_POINTER]: (payload) => payload,

  [SET_SPEAKER_BAR_PAGE_POINTER]: (payload) => payload,
  [SET_SPEAKER_ACTIVE_PAGE_POINTER]: (payload) => payload,
  [SET_GALLERY_MAIN_PAGE_POINTER]: (payload) => payload,
  [SET_SUSPENSION_ALL_PAGE_POINTER]: (payload) => payload,

  [SET_CURRENT_RENDER_VIDEO]: (payload) => payload,

  [SET_ATTENDEE_LAYOUT]: (payload) => payload,
  [SET_ATTENDEE_VIDEO_CONTROL_MODE]: (payload) => payload,
  [SET_FIRST_ACTIVE_VIDEO_COORD]: (payload) => payload,
  [SET_IS_SWAP_VIDEO_SHARED]: (payload) => payload,
  [SET_ATTENDEE_VIDEO_LAYOUT_FLAG]: (payload) => payload,
  [SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_RECEIVING_VIDEO]: (payload) =>
    payload,
  [SET_CLIENT_ENABLE_HARDWARE_ACCELERATION_FOR_SENDING_VIDEO]: (payload) =>
    payload,
  [SET_CLIENT_ENABLE_SEE_SELF_ACTIVE_WHEN_SPEAKING]: (payload) => payload,
  [SET_CLIENT_ENABLE_TURN_OFF_VIDEO_WHEN_JOIN_MEETING]: (payload) => payload,
  [SET_VIDEO_REAL_SIZE]: (payload) => payload,
  [SET_IS_FIRST_OPEN_VIDEO_IN_MEETING]: (payload) => payload,
  [SET_IS_LOADING_BACKGROUND_FROM_START_VIDEO]: (payload) => payload,
  [SET_IS_MIRROR_MY_VIDEO]: (payload) => payload,
  [SET_IS_NEED_NOTIFY_START_VIDEO_FAIL]: (payload) => payload,
  [SET_IS_CLIENT_PAGE_VISIBLE]: (payload) => payload,

  [SET_CUSTOM_GALLERY_VIEW_ORDER]: (payload) => payload,
  [SET_IS_VIEW_ORDER_SAVED]: (payload) => payload,
  [SET_IS_UPLOADING_VIEW_ORDER]: (payload) => payload,
  [SET_IS_DOWNLOADING_VIEW_ORDER]: (payload) => payload,
  [SET_MMR_SAVE_ORDER_FLAG]: (payload) => payload,
  [SET_ACTIVE_VIDEO_TALKING_ID]: (payload) => payload,
  [SET_CURRENT_VIDEO_RENDER_TAG]: (payload) => payload,
  [SET_DISABLE_VIDEO_FOR_SHARING_PERFORMANT]: (payload) => payload,
  [SET_IS_TESLA_PARKED]: (payload) => payload,
  [SET_IS_WATERMARK_TURN_ON]: (payload) => payload,
  [SET_BRANDING]: (payload) => payload,
  [SET_BRANDING_BACKGROUND]: (payload) => payload,
  [SET_BRANDING_LAYOUT]: (payload) => payload,
  [SET_VIDEO_ACTION_IN_PROGRESS]: (payload) => payload,
  [SET_SHOULD_RECOVER_VIDEO_ON]: (payload) => payload,
  [SET_NAME_TAG_TEMPLATES]: (payload) => payload,
  [SET_BRANDING_WINDOW_VISIBLE]: (payload) => payload,
  [SET_CURRENT_APPEARANCE]: (payload) => payload,
  [SET_IS_LOADING_BACKGROUND_FROM_SETTINGS]: (payload) => payload,
  [SET_CURRENT_MINIMIZE_VIDEO]: (payload) => payload,
  [SET_CURRENT_MINIMIZE_RENDER_VIDEO]: (payload) => payload,
  [SET_IS_VIDEO_RENDER_TRANSFORM]: (payload) => payload,
  [SET_IS_OPEN_VIDEO_25_SIZE]: (payload) => payload,
  [SET_IS_CHANGE_SIDE_BY_SIDE_SPEAKER_LAYOUT_BY_USER]: (payload) => payload,
  [SET_SHOULD_REBACK_PREVIOUS_VIEW]: (payload) => payload,
  [SET_WAITING_FOR_VIDEO_FRAME_ACTIVE_USER]: (payload) => payload,
  [SET_SUBSCRIBED_VIDEO_MAX_QUALITY]: (payload) => payload,
  [SET_NETWORK_QUALITY_MAP]: (payload) => payload,
  [SET_GALLERY_ORDER_TYPE]: (payload) => payload,
  [SET_IS_GALLERY_ORDER_MODE]: (payload) => payload,
  [SET_IS_WEBINAR_ONLY_SHOW_CONTENT]: (payload) => payload,
  [SET_IS_SHOW_ZR_MAIN_USER]: (payload) => payload,
  [SET_SIMU_LIVE_PLAYERS_UNIQUE_INDEX]: (payload) => payload,
  [SET_CAMERA_CONTROLLEE_GROUP]: (payload) => payload,
  [SET_CAMERA_CONTROLLER_USER_ID]: (payload) => payload,
  [SET_VIDEO_CAMERA_PTZ_CAPABILITIES]: (payload) => payload,
  [SET_PTZ]: (payload) => payload,
  [SET_START_CAMERA_CONTROL_GROUP]: (payload) => payload,
  [SET_IS_SHOW_CAMERA_CONTROL_NOTIFICATION]: (payload) => payload,
  [SET_IS_ENABLE_AUTO_ACCEPT_CAMERA_CONTROL]: (payload) => payload,
  [SET_IS_SUBSCRIBE_ACTIVE_CHANNEL]: (payload) => payload,
  [SET_ACTIVE_CHANNEL_QUALITY]: (payload) => payload,
  [SET_VIDEO_RENDERER_TYPE]: (payload) => payload,
  [SET_VIDEO_RENDERER_LIST]: (payload) => payload,
  [SET_MULTI_SPEAKER_SCALE]: (payload) => payload,
  [SET_CURRENT_MULTI_SPEAKER_ACTIVE_VIDEO]: (payload) => payload,
  [SET_CURRENT_MULTI_SPEAKER_MAIN_VIDEO]: (payload) => payload,
  [SET_MULTI_SPEAKER_ACTIVE_PAGE_POINTER]: (payload) => payload,
  [SET_MULTI_SPEAKER_MAIN_PAGE_POINTER]: (payload) => payload,
  [SET_MULTI_SPEAKER_ACTIVE_LIST]: (payload) => payload,
  [SET_IS_ENABLE_MULTI_SPEAKER_VIEW]: (payload) => payload,
  [SET_ACTIVE_SPEAKER_LIST]: (payload) => payload,
  [SET_MULTI_SPEAKER_CONFIG]: (payload) => payload,
  [SET_USE_WEB_RTC_STRATEGY]: (payload) => payload,
  [SET_VIDEO_MUTED_BY_SYSTEM]: (payload) => payload,
});
