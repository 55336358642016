import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import { isJoinVoIP, isCoOrHost } from '../../../global/service';
import { isInRoom } from '../../breakout-room/utils';
import { videoBasicAllAttendeeListSelector } from '../../video/redux/selectors/video-list-selector';
import { SIGN_LANGUAGE_INTERPRETATION_LANG_TYPES } from '../constant';
import {
  isUserSignLanguageInterpreter,
  getUserActiveSignLanguageInterpretationLanguageID,
  isUserSignLanguageInterpreterForLanguage,
} from '../util';
import {
  getInterpretationInfoById,
  getSignLanguageInterpretationInfoById,
} from '../service';
import { MINIMIZED_VIDEO, ACTIVE_VIDEO, MULTI_VIDEO } from '../../video/enum';
import { isAudioBridge } from '../../../global/service/audio-bridge-service';

const isHostSelector = ({ meeting: { isHost } }) => isHost;

const interpretationLanguageSelector = ({ interpretation: { languages } }) =>
  languages;

const signLanguageInterpretationLanguageSelector = ({
  interpretation: { signLanguages },
}) => signLanguages;

const signLanguageTypeSelector = ({ interpretation: { signLanguageType } }) =>
  signLanguageType;

const interpretationStartSelector = ({ interpretation: { isStart } }) =>
  isStart;

const signLanguageInterpretationStartedSelector = ({
  interpretation: { signLanguageStarted },
}) => signLanguageStarted;

const signLanguageInterpreterAllowedToTalkSelector = ({
  interpretation: { allowedToTalk },
}) => allowedToTalk;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

const showNoVideoParticipantsSelector = ({
  video: {
    UI: { showNoVideoParticipants },
  },
}) => showNoVideoParticipants;

const interpreterWindowShowTypeSelector = ({
  interpretation: {
    UI: { interpreterWindowShowType },
  },
}) => interpreterWindowShowType;

const breakoutRoomStatusSelector = ({
  breakoutRoom: {
    attendee: { status },
  },
}) => status;

function isLanguageInList(type, languages) {
  return languages.find((language) => language.type === type);
}

export const audioInterpretationLanguageFlatListSelector = createSelector(
  [interpretationLanguageSelector],
  (languages) => {
    const list = [];
    languages.forEach(
      ({
        firstLanguage,
        firstLanguageID,
        secondLanguage,
        secondLanguageID,
        user_guid,
      }) => {
        if (!isLanguageInList(firstLanguage, list)) {
          const langInfo = getInterpretationInfoById(firstLanguageID) || {};
          list.push({
            type: firstLanguage,
            text: langInfo.name,
            iconText: langInfo.iconText,
            interpreterId: user_guid,
          });
        }
        if (!isLanguageInList(secondLanguage, list)) {
          const langInfo = getInterpretationInfoById(secondLanguageID) || {};
          list.push({
            type: secondLanguage,
            text: langInfo.name,
            iconText: langInfo.iconText,
            interpreterId: user_guid,
          });
        }
      },
    );
    return list;
  },
);

// Returns whether or not a particular attendee is a sign language interpreter.
export const isParticipantSignLanguageInterpreterSelector = createSelector(
  [
    signLanguageInterpretationLanguageSelector,
    (state, participant) => participant,
  ],
  (signLanguages, participant) => {
    return isUserSignLanguageInterpreter(participant, signLanguages);
  },
);

// Returns whether or not the current user is a sign language interpreter.
export const isCurrentUserSignLanguageInterpreterSelector = createSelector(
  [currentUserSelector, (state) => state],
  (currentUser, state) => {
    return isParticipantSignLanguageInterpreterSelector(state, currentUser);
  },
);

// Returns the sign language ID a sign language interpreter is interpreting in.
export const getSignLanguageInterpreterActiveLanguage = createSelector(
  [
    signLanguageInterpretationLanguageSelector,
    (state, participant) => participant,
  ],
  (signLanguages, participant) => {
    return getUserActiveSignLanguageInterpretationLanguageID(
      participant,
      signLanguages,
    );
  },
);

// for each unique sign language there is an intepreter for, join it with info about the sign language, including the text to show for it as well
// as the abbreviated icon text.
export const signLanguageInterpreterLanguageFlatListSelector = createSelector(
  [signLanguageInterpretationLanguageSelector],
  (signLanguages) => {
    const uniqueSignLanguageIds = new Set(
      signLanguages.map((lang) => lang.signLanguageID),
    );

    return [...uniqueSignLanguageIds].map((signLanguageID) => {
      const langInfo = getSignLanguageInterpretationInfoById(signLanguageID);
      return {
        type: signLanguageID,
        text: langInfo.name,
        iconText: langInfo.icon,
      };
    });
  },
);

// Gets all sign language interpreters who are interpreting for the language we are watching.
export const signLanguageInterpretersInCurrentLanguageSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    signLanguageInterpretationLanguageSelector,
    signLanguageTypeSelector,
  ],
  (allAttendees, signLanguages, signLanguageType) => {
    return Array.from(
      new Set(
        signLanguages
          .map((signLanguage) => {
            const targetInterpreter = allAttendees.find((attendee) =>
              isUserSignLanguageInterpreterForLanguage(attendee, signLanguage),
            );
            if (targetInterpreter) {
              return {
                ...targetInterpreter,
                activeInterpretationSignLanguage: signLanguage.signLanguageID,
              };
            }
          })
          .filter(
            (interpreter) =>
              interpreter !== undefined &&
              interpreter.activeInterpretationSignLanguage === signLanguageType,
          ),
      ),
    );
  },
);

// Returns a list of sign language interpreters that we are watching who have their video turned on.
export const signLanguageInterpretersInCurrentLanguageWithVideoOnSelector =
  createSelector(
    [signLanguageInterpretersInCurrentLanguageSelector],
    (interpreterAttendees) => {
      return interpreterAttendees.filter((interpreterAttendee) => {
        return interpreterAttendee.displayVideoOn;
      });
    },
  );

// Returns a list of sign language interpreters to show in the floating interpreter window based
// on the currently selected view mode and whether or not the user has selected to "Hide non-video participants".
export const signLanguageInterpreterAvatarsToShowSelector = createSelector(
  [
    signLanguageInterpretersInCurrentLanguageSelector,
    signLanguageInterpretersInCurrentLanguageWithVideoOnSelector,
    interpreterWindowShowTypeSelector,
    showNoVideoParticipantsSelector,
  ],
  (
    allInterpreters,
    interpretersWithVideoOn,
    interpreterWindowShowType,
    showNoVideoParticipants,
  ) => {
    if (interpreterWindowShowType === MINIMIZED_VIDEO) {
      return [];
    } else if (
      interpreterWindowShowType === ACTIVE_VIDEO ||
      interpreterWindowShowType === MULTI_VIDEO
    ) {
      if (interpretersWithVideoOn.length > 0) {
        return [interpretersWithVideoOn[0]];
      } else if (allInterpreters.length > 0 && showNoVideoParticipants) {
        return [allInterpreters[0]];
      } else {
        return [];
      }
    }
  },
);

export const isCurrentUserSignLanguageInterpreterAllowedToTalkOrMuteSelector =
  createSelector(
    [currentUserSelector, (state) => state],
    (currentUser, state) => {
      return (
        isSignLanguageInterpreterAllowedToTalkSelector(state, currentUser) ||
        !currentUser.muted
      );
    },
  );

export const isCurrentUserSignLanguageInterpreterAllowedToTalkSelector =
  createSelector(
    [currentUserSelector, (state) => state],
    (currentUser, state) => {
      return isSignLanguageInterpreterAllowedToTalkSelector(state, currentUser);
    },
  );

export const isSignLanguageInterpreterAllowedToTalkSelector = createSelector(
  [
    signLanguageInterpreterAllowedToTalkSelector,
    isParticipantSignLanguageInterpreterSelector,
    signLanguageInterpretationStartedSelector,
    breakoutRoomStatusSelector,
    (state, participant) => participant,
  ],
  (
    interpretersAllowedToTalkMap,
    isParticipantSignLanguageInterpreter,
    signLanguageInterpretationStarted,
    boStatus,
    participant,
  ) => {
    return (
      interpretersAllowedToTalkMap[participant.userId] === true ||
      isCoOrHost(participant) ||
      !isParticipantSignLanguageInterpreter ||
      !signLanguageInterpretationStarted ||
      isInRoom(boStatus)
    );
  },
);

// update this check to also show button if sign language has started and we're only connected to phone audio
export const isShowInterpretationBtnSelector = createSelector(
  [
    interpretationStartSelector,
    currentUserSelector,
    isHostSelector,
    signLanguageInterpretationStartedSelector,
  ],
  (isStart, currentUser, isHost, signLanguageStarted) =>
    !isAudioBridge() &&
    ((isStart &&
      meetingConfig.languageInterpretation &&
      isJoinVoIP(currentUser)) ||
      (signLanguageStarted && meetingConfig.signInterpreterInfo?.isEnable) ||
      (isHost &&
        meetingConfig.meetingInterpretationInfo
          ?.isEnableLanguageInterpretation)),
);

export const isShowInterpretationWindowSelector = createSelector(
  [
    signLanguageInterpretationStartedSelector,
    signLanguageTypeSelector,
    breakoutRoomStatusSelector,
  ],
  (signLanguageStarted, signLanguageType, boStatus) => {
    return (
      signLanguageStarted &&
      !isInRoom(boStatus) &&
      signLanguageType !== SIGN_LANGUAGE_INTERPRETATION_LANG_TYPES.ORIGINAL
    );
  },
);

export const isShowInterpretationWindowCloseButtonSelector = createSelector(
  [isCurrentUserSignLanguageInterpreterSelector],
  (isCurrentUserSignLanguageInterpreter) =>
    !isCurrentUserSignLanguageInterpreter,
);

export const showYourVideoIsNowBroadcastingNotificationSelector =
  createSelector(
    [
      signLanguageInterpretationStartedSelector,
      isCurrentUserSignLanguageInterpreterSelector,
      breakoutRoomStatusSelector,
    ],
    (signLanguageStarted, isCurrentUserSignLanguageInterpreter, boStatus) =>
      signLanguageStarted &&
      !isInRoom(boStatus) &&
      meetingConfig.signInterpreterInfo?.isEnable &&
      isCurrentUserSignLanguageInterpreter,
  );
