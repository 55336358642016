import meetingConfig from 'meetingConfig';
import * as types from '../constants/SocketActionTypes';
import { isAudioBridge } from '../global/service/audio-bridge-service';

export const defaultSocketStatus = {
  commandSocketUrl: null,
  bandWidth: 0,
  initAudioEncodeStatus: isAudioBridge() ? 'success' : 'close',
  initAudioDecodeStatus: isAudioBridge() ? 'success' : 'close',
  initVideoEncodeStatus: 'close',
  initVideoDecodeStatus: 'close',
  xmppSocketStatus: 'close',
  isSplitSharing:
    meetingConfig.isSupportAV &&
    typeof WebAssembly === 'object' &&
    typeof Worker === 'function',
};

export default function socketStatus(state = defaultSocketStatus, action) {
  switch (action.type) {
    case types.SOCKETSTATUS_RESET:
      return {
        ...action.data,
      };

    case types.OPEN_SOCKET:
      return Object.assign({}, state, {
        commandSocketUrl: action.commandSocketUrl,
      });

    case types.CLOSE_SOCKET:
      return Object.assign({}, state, {
        commandSocketUrl: null,
      });

    case types.OPEN_XMPP_SOCKET:
      return Object.assign({}, state, {
        xmppSocketStatus: 'open',
      });

    case types.CLOSE_XMPP_SOCKET:
      return Object.assign({}, state, {
        xmppSocketStatus: 'close',
      });
    case types.CONFLICT_XMPP_SOCKET:
      return Object.assign({}, state, {
        xmppSocketStatus: 'conflict',
      });
    case types.INIT_AUDIO_ENCODE_SUCCESS:
      return Object.assign({}, state, {
        initAudioEncodeStatus: 'success',
      });

    case types.INIT_AUDIO_DECODE_SUCCESS:
      return Object.assign({}, state, {
        initAudioDecodeStatus: 'success',
      });

    case types.INIT_VIDEO_ENCODE_SUCCESS:
      return Object.assign({}, state, {
        initVideoEncodeStatus: 'success',
      });

    case types.INIT_VIDEO_DECODE_SUCCESS:
      return Object.assign({}, state, {
        initVideoDecodeStatus: 'success',
      });
    case types.INIT_AUDIO_DECODE_FAILED: {
      return { ...state, initAudioDecodeStatus: 'failed' };
    }
    case types.INIT_AUDIO_ENCODE_FAILED: {
      return { ...state, initAudioEncodeStatus: 'failed' };
    }
    case types.INIT_VIDEO_DECODE_FAILED: {
      return { ...state, initVideoDecodeStatus: 'failed' };
    }
    case types.INIT_VIDEO_ENCODE_FAILED: {
      return { ...state, initVideoEncodeStatus: 'failed' };
    }
    default:
      return state;
  }
}
