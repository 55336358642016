import { useEffect, useState, useRef, useCallback } from 'react';
import {
  PREVIEW_AUDIO_STATUS,
  PREVIEW_VIDEO_STATUS,
  PREVIEW_ERROR,
  PREVIEW_EVENT,
  CACHE_KEY,
} from '../consts';
import eb from '../../../global/event-bus';
import {
  cacheAudioStatus,
  cacheVideoStatus,
  clearPreviewAVSocket,
  getAudioStatusFromCache,
  getCachedAudioOn,
  getPreviewAVSocket,
  getVideoStatusFromCache,
} from '../service';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../global/logger/log-service/avs-laplace-telemetry';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../../job_enum';
import { initVideoPlayer } from '@zoom/web-media-video-player';
import { globalVariable } from '../../../global/global-variable';
import { has } from 'lodash';
import deviceManager from '../../../device-manager';

const usePreviewVideo = ({
  isAudioInitSuccess = false,
  isVideoInitSuccess = false,
  cache = CACHE_KEY.PREVIEW_OPTIONS,
  canAudioUnmute = true,
  canVideoUnmute = true,
}) => {
  const [audioStatus, _setAudioStatus] = useState(PREVIEW_AUDIO_STATUS.LOADING);
  const [videoStatus, setVideoStatus] = useState(PREVIEW_VIDEO_STATUS.LOADING);
  const [nextAudioStatus, setNextAudioStatus] = useState(
    getAudioStatusFromCache(cache),
  );
  const [nextVideoStatus, setNextVideoStatus] = useState(
    getVideoStatusFromCache(cache),
  );
  const [willRenderVideo, setWillRenderVideo] = useState(false);
  const [error, setError] = useState(PREVIEW_ERROR.NONE);
  const [microphones, setMicrophones] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState(null);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [activeCamera, setActiveCamera] = useState(null);
  const avSocket = getPreviewAVSocket();
  const isVideoOpen = videoStatus === PREVIEW_VIDEO_STATUS.OPEN;

  const dynamicPropRef = useRef(null);
  dynamicPropRef.current = {
    audioStatus: dynamicPropRef.current
      ? dynamicPropRef.current.audioStatus
      : audioStatus,
    videoStatus,
    canAudioUnmute,
    canVideoUnmute,
  };

  const setAudioStatus = useCallback((status) => {
    dynamicPropRef.current.audioStatus = status;
    _setAudioStatus(status);
  }, []);

  const handleCamerasUpdate = useCallback((cameras) => {
    setCameras(cameras);
    if (cameras.length <= 1) {
      setError(PREVIEW_ERROR.CAN_NOT_DETECT_CAMERA);
      setVideoStatus(PREVIEW_VIDEO_STATUS.CAN_NOT_DETECT_CAMERA);
    } else {
      setError(PREVIEW_ERROR.NONE);
    }
  }, []);

  useEffect(() => {
    const deviceChangeHandler = (deviceState) => {
      if (has(deviceState, 'microphones')) {
        setMicrophones(deviceState.microphones);
      }
      if (has(deviceState, 'speakers')) {
        setSpeakers(deviceState.speakers);
      }
      if (has(deviceState, 'cameras')) {
        handleCamerasUpdate(deviceState.cameras);
      }
      if (has(deviceState, 'activeMicrophone')) {
        setActiveMicrophone(deviceState.activeMicrophone);
        if (deviceState.activeMicrophone !== activeMicrophone) {
          avSocket.changeMicrophone(deviceState.activeMicrophone);
        }
      }
      if (has(deviceState, 'activeSpeaker')) {
        setActiveSpeaker(deviceState.activeSpeaker);
      }
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceState.activeCamera);
        if (deviceState.activeCamera !== activeCamera && isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };
    const deviceSelectedHandler = (deviceState) => {
      if (has(deviceState, 'activeMicrophone')) {
        setActiveMicrophone(deviceState.activeMicrophone);
        avSocket.changeMicrophone(deviceState.activeMicrophone);
      }
      if (has(deviceState, 'activeSpeaker')) {
        setActiveSpeaker(deviceState.activeSpeaker);
      }
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceState.activeCamera);
        if (isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };

    deviceManager.on('DEVICE_CHANGED', deviceChangeHandler);
    deviceManager.on('DEVICE_SELECTED', deviceSelectedHandler);

    return () => {
      deviceManager.off('DEVICE_CHANGED', deviceChangeHandler);
      deviceManager.off('DEVICE_SELECTED', deviceSelectedHandler);
    };
  }, [
    avSocket,
    activeSpeaker,
    activeCamera,
    activeMicrophone,
    handleCamerasUpdate,
    isVideoOpen,
  ]);

  useEffect(() => {
    deviceManager.watchInitComplete().then((deviceState) => {
      setMicrophones(deviceState.microphones);
      setSpeakers(deviceState.speakers);
      setActiveMicrophone(deviceState.activeMicrophone);
      setActiveSpeaker(deviceState.activeSpeaker);
      handleCamerasUpdate(deviceState.cameras);
      setActiveCamera(deviceState.activeCamera);
    });
  }, [handleCamerasUpdate]);

  useEffect(() => {
    if (willRenderVideo) {
      avSocket.startVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willRenderVideo]);
  const startVideo = () => setWillRenderVideo(true);
  const stopVideo = useCallback(() => {
    avSocket.stopVideo();
    setWillRenderVideo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { audioStatus } = dynamicPropRef.current;
    if (!canAudioUnmute) {
      if (audioStatus === PREVIEW_AUDIO_STATUS.MUTED) {
        setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
      }
      if (audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED) {
        avSocket.muteAudio();
        setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
      }
    } else if (canAudioUnmute) {
      if (audioStatus === PREVIEW_AUDIO_STATUS.DISABLED) {
        setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAudioUnmute]);

  useEffect(() => {
    const { videoStatus } = dynamicPropRef.current;
    if (!canVideoUnmute) {
      if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
        stopVideo();
      }
      setVideoStatus(PREVIEW_VIDEO_STATUS.DISABLED);
    } else if (canVideoUnmute) {
      if (videoStatus === PREVIEW_VIDEO_STATUS.DISABLED) {
        setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canVideoUnmute]);

  useEffect(() => {
    return () => {
      const { audioStatus, videoStatus } = dynamicPropRef.current;
      if (
        videoStatus === PREVIEW_VIDEO_STATUS.OPEN ||
        videoStatus === PREVIEW_VIDEO_STATUS.CAPTURING
      ) {
        avSocket.enableReuseStream(true);
        stopVideo();
      }
      if (
        audioStatus === PREVIEW_AUDIO_STATUS.MUTED ||
        audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED ||
        audioStatus === PREVIEW_AUDIO_STATUS.CAPTURING
      ) {
        avSocket.enableReuseStream(true);
        avSocket.leaveAudio();
      }
      clearPreviewAVSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onAudioInitSuccess = () => {
      const cachedAudioStatus = getAudioStatusFromCache(cache);
      const isAutoJoin =
        cachedAudioStatus !== PREVIEW_AUDIO_STATUS.NOT_CONNECTED;
      if (isAutoJoin) {
        Job.start(JOB_ENUM.PREVIEW_CONNECT_AUDIO, () => {
          avSocket.connectAudio();
          setAudioStatus(PREVIEW_AUDIO_STATUS.CAPTURING);
        })
          .then(() => {
            const { canAudioUnmute } = dynamicPropRef.current;
            const isAudioOn =
              cachedAudioStatus === PREVIEW_AUDIO_STATUS.UNMUTED &&
              canAudioUnmute;
            if (isAudioOn) {
              avSocket.unmuteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            } else if (canAudioUnmute) {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
            } else {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
          })
          .catch();
      } else {
        setAudioStatus(PREVIEW_AUDIO_STATUS.NOT_CONNECTED);
      }
    };
    const onVideoInitSuccess = () => {
      initVideoPlayer({
        mediaSDK: () => globalVariable.avSocket?.socketInstance,
        renderMode: globalVariable.avSocket?.useWBVideo ? 'video' : 'canvas',
      });
      const cachedVideoStatus = getVideoStatusFromCache(cache);
      const { videoStatus, canVideoUnmute } = dynamicPropRef.current;
      const isVideoOn =
        cachedVideoStatus === PREVIEW_VIDEO_STATUS.OPEN && canVideoUnmute;
      if (videoStatus === PREVIEW_VIDEO_STATUS.DISABLED) {
        return;
      }
      if (isVideoOn) {
        startVideo();
        if (videoStatus === PREVIEW_VIDEO_STATUS.LOADING) {
          setVideoStatus(PREVIEW_VIDEO_STATUS.CAPTURING);
        }
      } else {
        if (canVideoUnmute) {
          setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
        } else {
          setVideoStatus(PREVIEW_VIDEO_STATUS.DISABLED);
        }
      }
    };
    eb.on(PREVIEW_EVENT.AUDIO_INIT_SUCCESS, onAudioInitSuccess);
    eb.on(PREVIEW_EVENT.AUDIO_CONNECT_SUCCESS, () => {
      Job.complete(JOB_ENUM.PREVIEW_CONNECT_AUDIO);
    });

    eb.on(PREVIEW_EVENT.AUDIO_LEAVE_SUCCESS, () => {
      setAudioStatus(PREVIEW_AUDIO_STATUS.NOT_CONNECTED);
    });

    eb.on(PREVIEW_EVENT.AUDIO_FORBIDDEN, () => {
      setAudioStatus(PREVIEW_AUDIO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.AUDIO_FORBIDDEN);
    });

    eb.on(PREVIEW_EVENT.VIDEO_INIT_SUCCESS, onVideoInitSuccess);

    eb.on(PREVIEW_EVENT.CAMERA_IS_TAKEN, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.CAMERA_TAKEN);
      setWillRenderVideo(false);
    });

    eb.on(PREVIEW_EVENT.VIDEO_START_SUCCESS, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.OPEN);
      setError(PREVIEW_ERROR.NONE);
    });

    eb.on(PREVIEW_EVENT.VIDEO_FORBIDDEN, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.VIDEO_FORBIDDEN);
      setWillRenderVideo(false);
    });

    if (isAudioInitSuccess) {
      onAudioInitSuccess();
    }

    if (isVideoInitSuccess) {
      onVideoInitSuccess();
    }

    return () => {
      eb.offAll(Object.values(PREVIEW_EVENT));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAudioClick = () => {
    const { canAudioUnmute } = dynamicPropRef.current;
    switch (audioStatus) {
      case PREVIEW_AUDIO_STATUS.MUTED: {
        avsLogReport('preview user unmute audio', [
          AVS_TAGS.user_action,
          AVS_TAGS.audio_telemetry,
        ]);
        avSocket.unmuteAudio();
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.UNMUTED);
        setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
        break;
      }
      case PREVIEW_AUDIO_STATUS.UNMUTED: {
        avsLogReport('preview user mute audio', [
          AVS_TAGS.user_action,
          AVS_TAGS.audio_telemetry,
        ]);
        avSocket.muteAudio();
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
        setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
        break;
      }
      case PREVIEW_AUDIO_STATUS.NOT_CONNECTED: {
        const isAudioOn = getCachedAudioOn(cache) && canAudioUnmute;
        Job.start(JOB_ENUM.PREVIEW_CONNECT_AUDIO, () => {
          avSocket.connectAudio(isAudioOn);
          setAudioStatus(PREVIEW_AUDIO_STATUS.CAPTURING);
          if (isAudioOn) {
            setNextAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.UNMUTED);
          } else {
            if (canAudioUnmute) {
              setNextAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            } else {
              setNextAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
            cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
          }
        })
          .then(() => {
            if (isAudioOn) {
              avSocket.unmuteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            } else if (canAudioUnmute) {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
            } else {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
          })
          .catch();
        break;
      }
      case PREVIEW_AUDIO_STATUS.DISABLED:
      case PREVIEW_AUDIO_STATUS.FORBIDDEN:
      case PREVIEW_AUDIO_STATUS.LOADING:
      case PREVIEW_AUDIO_STATUS.CAPTURING:
        break;
      default:
        break;
    }
  };
  const handleVideoClick = () => {
    switch (videoStatus) {
      case PREVIEW_VIDEO_STATUS.OPEN: {
        avsLogReport('preview user stop capture video', [
          AVS_TAGS.user_action,
          AVS_TAGS.video_telemetry,
        ]);
        stopVideo();
        setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
        cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.CLOSED);
        break;
      }
      case PREVIEW_VIDEO_STATUS.CLOSED:
      case PREVIEW_VIDEO_STATUS.FORBIDDEN: {
        avsLogReport('preview user start capture video', [
          AVS_TAGS.user_action,
          AVS_TAGS.video_telemetry,
        ]);
        startVideo();
        setVideoStatus(PREVIEW_VIDEO_STATUS.CAPTURING);
        setNextVideoStatus(PREVIEW_VIDEO_STATUS.OPEN);
        cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.OPEN);
        break;
      }
      case PREVIEW_VIDEO_STATUS.LOADING:
      case PREVIEW_VIDEO_STATUS.CAPTURING:
        break;
      default:
        break;
    }
  };

  return {
    audioStatus,
    videoStatus,
    nextAudioStatus,
    nextVideoStatus,
    willRenderVideo,
    error,
    setError,
    handleAudioClick,
    handleVideoClick,
    speakers,
    microphones,
    cameras,
    activeSpeaker,
    activeMicrophone,
    activeCamera,
  };
};

export default usePreviewVideo;
